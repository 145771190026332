import { toast } from "react-toastify";

import { axiosB24NoTokenInstance } from "../../axios.config";
import { LeadActionTypes } from "./lead.types";

export const addLead = (data) => (dispatch) => {
  dispatch({ type: LeadActionTypes.ADD.LEAD_PENDING });

  axiosB24NoTokenInstance
    .post(`/crm/lead/331`, data)
    .then((result) => {
      dispatch({
        type: LeadActionTypes.ADD.LEAD_SUCCESS,
        payload: result.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: LeadActionTypes.ADD.LEAD_ERROR,
        payload: error.response.data,
      });

      toast.error(error.response.data.message);
    });
};

export const resetLead = (data) => (dispatch) => {
  dispatch({ type: LeadActionTypes.RESET.LEAD_ADD });
};
