import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./ProductDetails.css";
import { getProductById } from "../../redux/actions-exporter";
import parse from "html-react-parser";

const s = {
  dialogContainer: {
    textAlign: "right",
    width: "400px",
    m: "auto",
  },
  dialogTitle: {
    padding: "16px 50px",
  },
  dialogIconButton: {
    position: "absolute",
    left: 8,
    top: 8,
    zIndex: "10000",
  },
};

export default function ProductDetails() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { product } = useSelector((state) => state.product);
  const { id } = useParams();
  const desc = [];
  const navigate = useNavigate();

  product?.description
    ?.split(product?.description.includes("\\n") ? "\\n" : "\n")
    .map((item) => {
      item.length > 0 && desc.push(item.includes("<a") ? parse(item) : item);
    });

  useEffect(() => {
    if (!product?.length) dispatch(getProductById(id));
  }, [id]);

  return (
    <div>
      <div className="productDetails">
        <div className="container productTop">
          <div className="productDetails-heading">
            <h5 className="productDetails-title">{product.title}</h5>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="productDetails-info">
                <div className="productDetails-content">
                  <h6 className="productDetails-contentTitle">
                    {t("description")}
                  </h6>
                  <p className="productDetails-contentText content">
                    {product?.content || ""}
                  </p>

                  {desc.map((term) => (
                    <p className="productDetails-contentText description">
                      {term[1].type === "a" ? (
                        <span
                          onClick={() => {
                            navigate(`${term[1].props.href}`, {
                              state: {
                                id: term[1].props.href.replace("/product/", ""),
                              },
                            });
                          }}
                        >
                          {" "}
                          {term.slice(0, term.indexOf("<a"))}
                        </span>
                      ) : (
                        term
                      )}
                    </p>
                  ))}
                </div>
              </div>
            </div>
            <div className="imgWrapper">
              <div className="productDetails-img">
                <img src={product?.image} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
