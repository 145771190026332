import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Box } from "@material-ui/core";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { getOrder } from "../../../redux/actions-exporter";

import "./styles.css";
import GiftForm from "./GiftForm";

// css
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "4px",
  boxShadow: 24,
  p: "32px 15px",
};

const Index = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const currentOrder = useSelector((state) => state.order.order);
  const [timer, setTimer] = useState(false);
  const [order, setOrder] = useState(false);
  const [share, setShare] = useState(false);
  const [giftFormModal, setGiftFormModal] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getOrder(orderId));

    if (!timer) {
      setTimer(setInterval(() => dispatch(getOrder(orderId)), 2000));
    }
  }, [orderId]); // eslint-disable-line

  useEffect(() => {
    if (!order?.id) {
      setOrder(currentOrder);
      clearInterval(timer);
    }
  }, [currentOrder, order]); // eslint-disable-line

  useEffect(() => {
    console.log("Current order from Redux: ", currentOrder);
    if (!order?.id) {
      setOrder(currentOrder);
      console.log("Setting order state to: ", currentOrder);
      clearInterval(timer);
    }
  }, [currentOrder]);

  const handleClose = () => {
    setGiftFormModal(false);
  };
  return (
    <div className="pageTemplate">
      {/* <Header isFull={false} /> */}
      <div className="container">
        <hr />
        <div className="templateHeading">
          <h1 className="templateTitle">{t("payment approve")}</h1>
        </div>
        <hr />
        <div className="paymentApproveContent">
          <div className="paymentApproveText">
            <h6 className="pelecard-page__info">
              סכום החשבון: ₪ {order?.total || 0}
            </h6>
            <h5 className="pelecard-page__title">
              תשלום בסך ₪{order?.discountTotal || order?.total || 0} בוצע בהצלחה
            </h5>
            {/*<h5 className="paymentApproveLine">*/}
            {/*  {order?.metadata?.cardNumber ? (*/}
            {/*    <>קוד מולטיפס: {order?.metadata?.cardNumber}</>*/}
            {/*  ) : (*/}
            {/*    <>{t("Loading card")}...</>*/}
            {/*  )}*/}
            {/*</h5>*/}
            <h5 className="pelecard-page__info">
              את השוברים שלי ניתן לראות{" "}
              <Link className="forgot" to="/wallet">
                כאן
              </Link>
            </h5>
            {/* {order?.metadata?.cardPin ? <>{order?.metadata?.cardPin}</> : ""} */}
          </div>
          <div
            className="loadMoneyCard-btn"
            // style={{ justifyContent: "center" }}
          >
            <Button
              className="blueBtn"
              style={{ margin: 0 }}
              onClick={() => {
                navigate("/");
              }}
            >
              {t("end")}
            </Button>
            {/* {order?.metadata?.cardNumber ? ( */}
            {false ? (
              <>
                <Button
                  className="blueBtn outlineBlueBtn"
                  onClick={() => {
                    setGiftFormModal(true);
                  }}
                >
                  שתף כמתנה
                </Button>
                <Modal
                  open={giftFormModal}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <GiftForm order={order} />
                  </Box>
                </Modal>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
