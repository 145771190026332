import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import HomeIcon from "@mui/icons-material/Home";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import { IoIosChatbubbles } from "react-icons/io";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import cx from "classnames";
import { useSelector, useDispatch } from "react-redux";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

import GrayIcon from "./GrayIcon";
import SignUp from "../sign/SignUp";
import SignIn from "../sign/SignIn";
import ForgotPassword from "../sign/ForgotPassword";
import { setSidebarState } from "../../redux/actions-exporter";

import chatIcon from "../../assets/images/footer/chat.png";
import ClearIcon from "@mui/icons-material/Clear";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "./Footer.css";

const containerArr = [
  {
    img: <HomeIcon />,
    link: "/",
    title: "שוברים",
  },
  {
    img: <StoreMallDirectoryIcon />,
    link: "/allvendors",
    title: "ספקים",
  },
  // {
  //   img: <ShoppingCartIcon />,
  //   link: "/cart",
  // },
  // {
  //   img: <LocalMallIcon />,
  //   link: "/clubs",
  // },
  // {
  //   img: <LocalOfferIcon />,
  //   link: "/sales",
  // },
];

const Footer = () => {
  const [signUp, setSignUp] = useState("signin");
  const dispatch = useDispatch();
  const isOpenSidebar = useSelector((state) => state.sidebar.isOpen);
  const user = useSelector((state) => state.user.user);

  let url = window.location.pathname;
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 12,
      fontFamily: "inherit ",
      transform: "translate(40px) !important",
    },
  }));
  return (
    <>
      <BrowserView>
        <div className="webFooter">
          <div
            className={cx(
              "customizer border-left-blue-grey border-left-lighten-4",
              isOpenSidebar ? "open" : "d-none d-md-block"
            )}
          >
            <div className="customizer-toggle box-shadow-3">
              {containerArr.map((el, i) => {
                return (
                  <LightTooltip title={el.title} placement="left">
                    <Link to={el.link} key={i}>
                      {el.img}
                    </Link>
                  </LightTooltip>
                );
              })}

              <LightTooltip title="עזרה" placement="left">
                <Link to="#">
                  {isOpenSidebar ? (
                    <ChevronRightIcon
                      onClick={() => dispatch(setSidebarState(!isOpenSidebar))}
                    />
                  ) : (
                    <IoIosChatbubbles
                      onClick={() => dispatch(setSidebarState(!isOpenSidebar))}
                    />
                  )}
                </Link>
              </LightTooltip>
            </div>
            <div
              id="widget-6e4f39f3058daac5a51f947ebe82a55ed19ff476"
              style={{ display: !user ? "none" : "block" }}
              data-view="slide"
            />
            <div
              className="chatCloseIcon"
              onClick={() => dispatch(setSidebarState(!isOpenSidebar))}
            >
              <ClearIcon />
            </div>
            {/* {!user && ( */}
            <>
              {signUp === "signup" ? (
                <SignUp setSignUp={setSignUp} />
              ) : signUp === "signin" ? (
                <SignIn setSignUp={setSignUp} />
              ) : signUp === "forgot" ? (
                <ForgotPassword setSignUp={setSignUp} />
              ) : (
                ""
              )}
            </>
            {/* )} */}
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div>
          <footer className="footer">
            <div
              className={cx(
                "customizer border-left-blue-grey border-left-lighten-4",
                isOpenSidebar ? "open" : "d-none d-md-block"
              )}
            >
              <div
                id="widget-6e4f39f3058daac5a51f947ebe82a55ed19ff476"
                style={{ display: !user ? "none" : "block" }}
                data-view="slide"
              />
              <div
                className="chatCloseIcon"
                onClick={() => dispatch(setSidebarState(!isOpenSidebar))}
              >
                <ClearIcon />
              </div>
              {/* {!user && ( */}
              <>
                {signUp === "signup" ? (
                  <SignUp setSignUp={setSignUp} />
                ) : signUp === "signin" ? (
                  <SignIn setSignUp={setSignUp} />
                ) : signUp === "forgot" ? (
                  <ForgotPassword setSignUp={setSignUp} />
                ) : (
                  ""
                )}
              </>
              {/* )} */}
            </div>
            <div className="sosAndGray">
              <div className="grayIcons">
                {containerArr.map((el, i) => {
                  return (
                    <GrayIcon
                      img={el.img}
                      i={i}
                      url={url}
                      key={i}
                      link={el.link}
                    />
                  );
                })}
              </div>
            </div>
            <div
              className="mainChat"
              onClick={() => dispatch(setSidebarState(!isOpenSidebar))}
            >
              <img alt="" className="chat" src={chatIcon} />
            </div>
          </footer>
        </div>
      </MobileView>
    </>
  );
};

export default Footer;
