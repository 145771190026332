import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";

import {
  MenuItem,
  Select,
  Button,
  DialogTitle,
  Dialog,
  FormControl,
  TextField,
  IconButton,
  Drawer,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Categories from "./Categories";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {
  addToCart,
  getProducts,
  getProductCategories,
  openSidebar,
} from "../../redux/actions-exporter";
import ClubRegistr from "./clubRegistr/ClubRegistr";
import BasicPagination from "./BasicPagination";
import Search from "./Search";
import VendorList from "./VendorList";
import shach from "../../assets/images/shach.jpg";
import "./dashboard.css";
import widgetHelper from "../../helpers/widget";
import art from "../../assets/images/1.jpg";
import art1 from "../../assets/images/1L.jpg";
import art2 from "../../assets/images/2L.jpg";
import art3 from "../../assets/images/3L.jpg";
import art4 from "../../assets/images/4a.jpg";
import art5 from "../../assets/images/5a.jpg";
import art6 from "../../assets/images/6L.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, EffectFade, Autoplay } from "swiper";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/autoplay/autoplay.min.css";
import "swiper/modules/effect-fade/effect-fade.min.css";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import Send from "../product/Send";
const arts = [art, art1, art2, art3, art4, art5, art6];

const screenWidth = window.innerWidth;
let makeProductsPerPage = 0;
if (screenWidth > 991) {
  makeProductsPerPage = 4;
} else if (screenWidth > 767) {
  makeProductsPerPage = 3;
} else if (screenWidth > 500) {
  makeProductsPerPage = 2;
} else {
  makeProductsPerPage = 1;
}
const s = {
  dialogContainer: {
    textAlign: "right",
    width: "400px",
    m: "auto",
  },
  dialogTitle: {
    padding: "16px 50px",
  },
  dialogIconButton: {
    position: "absolute",
    left: 8,
    top: 8,
    zIndex: "10000",
  },
};
export default function Dashboard({ userType, setIsClub, isMap }) {
  const [search, setSearch] = useState(false); // eslint-disable-line
  const [currentCategory, setCurrentCategory] = useState("all");
  const [clearFilter, setClearFilter] = useState(false);
  const [categories, setCategories] = useState([]);
  const [filterProducts, setFilterProducts] = useState([]);
  const [zero, setZero] = useState(false);
  const [tooLow, setTooLow] = useState(null);
  const [tooHigh, setTooHigh] = useState(null);
  const [chosen, setChosen] = useState({
    product: {},
    price: [],
    quantity: [],
  });
  const [inCart, setInCart] = useState(false);
  const [cartPop, setCartPop] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);
  const products = useSelector((state) => state.product.products);
  const user = useSelector((state) => state?.user?.user);
  const popular = products
    ?.filter((product) => product.id == 4)[0]
    ?.merchants?.filter((merchant) => {
      return merchant.id == 809 || merchant.id == 817 || merchant.id == 789;
    });

  !isMap && setIsClub(false);
  let amount = [];
  for (let i = 1; i < 100; i++) amount.push(i);
  let prices = [];
  let quantitys = [];
  for (let i = 0; i < 10; i++) {
    prices.push("");
    quantitys.push(1);
  }
  useEffect(() => {
    !products.length && dispatch(getProducts());
  }, [dispatch]);

  useEffect(() => {
    if (products.length) {
      let newProducts = [...products];

      newProducts.forEach((p) => {
        p.networks = [];
        // p.networks = [...new Set(p.merchants.map(item => item.network))]
        p.newMerchants = [];
        p.merchants.forEach((m) => {
          if (m.network && !p.networks.includes(m.network?.id)) {
            p.networks.push(m.network.id);
            p.newMerchants.push(m);
          }
        });
      });

      setFilterProducts(newProducts);

      setCategories([]);

      const productCategories = new Map();
      products?.forEach((product) =>
        product?.categories.forEach((category) =>
          productCategories?.set(category.id, category)
        )
      );
      setCategories([...productCategories.values()]);
    }
  }, [products]); // eslint-disable-line


  const handleChange = (catId) => {
    if (currentCategory !== catId && catId !== "all") setClearFilter(true);

    setCurrentCategory(catId);

    if (catId === "all") {
      setFilterProducts(products);
    } else {
      setFilterProducts(
        products.filter(
          (product) =>
            product.categories.findIndex((category) => category.id === catId) >
            -1
        )
      );
    }
  };

  const ref = useRef(null);


  const handleSubmit = (product, i, vendor) => {
    const { minPrice, maxPrice } = product;
    if (cart?.items?.some((a) => a.product.id === product.id)) {
      setInCart(true);
    } else {
      if (
        (!minPrice && !maxPrice) ||
        (!maxPrice && minPrice && chosen.price[i] >= minPrice) ||
        (!minPrice && maxPrice && chosen.price[i] <= maxPrice) ||
        (chosen.price[i] >= minPrice && chosen.price[i] <= maxPrice)
      ) {
        setCartPop(true);
        setChosen({
          ...chosen,
          product: product,
          price: product.price || chosen.price[i],
          quantity: chosen.quantity[i] || 1,
          vendor: vendor || null,
        });
      } else {
        if (!chosen?.price[i]) {
          setZero(true);
        } else {

          chosen.price[i] >= minPrice
            ? setTooHigh(maxPrice)
            : setTooLow(minPrice);
        }
      }
    }
  };

  return (
    <>
      <div className="dashboard-tamplate">
        <div className="container" ref={ref}>
          <Categories
            categories={categories}
            handleChange={handleChange}
            currentCategory={currentCategory}
          />
          <div className="bannerWrapper">
            <div
              style={{
                position: "absolute",
                bottom: "30px",
                zIndex: 2,
              }}
            >
              {screenWidth > 700 ? (
                <Search
                  filterProducts={filterProducts}
                  setFilterProducts={setFilterProducts}
                  handleChange={handleChange}
                  clearFilter={clearFilter}
                  setClearFilter={setClearFilter}
                />
              ) : (
                ""
              )}
            </div>
            <div className="banner">
              <Swiper
                freeMode={true}
                slidesPerView={1}
                modules={[Pagination, EffectFade, Autoplay]}
                pagination={{ clickable: true }}
                autoplay={{
                  delay: 1000,
                  disableOnInteraction: false,
                }}
                speed={2000}
                effect={"fade"}
                fadeEffect={{
                  crossFade: true,
                }}
              >
                {arts.map((art) => (
                  <SwiperSlide>
                    <div className="bannerImg">
                      <img alt="" src={art} />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>

          {filterProducts.length > 0
            ? filterProducts
                .filter(
                  (product) => product.priceB2B === 0 || product.price > 0
                )
                .sort((obj1, obj2) => {
                  if (obj1?.attributeValues?.order) {
                    return -1 * obj1?.attributeValues?.order;
                  }
                })

                .map((product, ix) => (
                  <>
                    <div className="productAllListBlock ">
                      <h6 className="module-heading__title">{product.title}</h6>
                      <div
                        className="showAll"
                        onClick={() => {
                          navigate(`/product/${product.id}`, {
                            state: { id: product.id },
                          });
                        }}
                      >
                        הצג הכל
                        <ArrowLeftIcon />
                      </div>
                    </div>

                    <ScrollingCarousel>
                      <ul className="categorySwiper dash">
                        <li className="product-box first isLink">
                          {/* <div className="product-box first isLink"> */}
                          <div
                            onClick={() => {
                              navigate(`/product/${product.id}`, {
                                state: { id: product.id },
                              });
                            }}
                            className={`product-box__img`}
                            style={{
                              backgroundImage: `url(${product.image})`,
                            }}
                          >
                            <div className="product-box__tag all">
                              הכל כלול!
                            </div>
                            {product.attributeValues.slogan ? (
                              <div className="product-box__tag best">
                                {product.attributeValues.slogan}
                              </div>
                            ) : null}
                          </div>
                          <div className="product-box__info">
                            <h6 className="product-box__brand">
                              {product.title}
                            </h6>
                            <div className="product-box__desc">
                              {product.content.length > 50
                                ? product.content.slice(0, 50).concat("...")
                                : product.content}
                            </div>
                            <div className="product-box__infoTop">
                              {product.price > 0 ? (
                                <div className="fixed">{product.price} ₪</div>
                              ) : (
                                <>
                                  <FormControl sx={{ maxWidth: 60 }}>
                                    <TextField
                                      required
                                      type="tel"
                                      size="small"
                                      id="search-bar"
                                      value={
                                        chosen?.product?.id === product.id
                                          ? chosen.price[0]
                                          : ""
                                      }
                                      onChange={(e) => {
                                        const np = [...prices];
                                        np.splice(0, 1, e.target.value);
                                        setChosen({
                                          ...chosen,
                                          product: product,
                                          price: np,
                                          // quantity: chosen?.quantity[0] || 1
                                        });
                                      }}
                                      placeholder="סכום"
                                      inputProps={{
                                        style: {
                                          fontSize: 12,
                                          minHeight: "23px",
                                          width: "55px",
                                        },
                                      }}
                                    />
                                  </FormControl>
                                  ₪
                                </>
                              )}
                              <FormControl size="small" sx={{ minWidth: 60 }}>
                                <Select
                                  autoWidth
                                  value={
                                    chosen?.product?.id === product?.id
                                      ? chosen?.quantity[0] || 1
                                      : 1
                                  }
                                  displayEmpty
                                  inputProps={{
                                    "aria-label": "Without label",
                                  }}
                                  InputLabelProps={{ shrink: false }}
                                  onChange={(e) => {
                                    const nq = [...quantitys];
                                    nq.splice(0, 1, e.target.value);
                                    setChosen({
                                      ...chosen,
                                      product: product,
                                      quantity: nq,
                                    });
                                  }}
                                >
                                  {amount?.map((el, idx) => (
                                    <MenuItem value={el} key={idx}>
                                      {el}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <Button
                                variant="outlined"
                                sx={{
                                  color: "var(--primary)",
                                  borderColor: "var(--primary)",
                                  "&:hover": {
                                    color: "var(--redish)",
                                    borderColor: "var(--redish)",
                                    background: "#FED8B1",
                                  },
                                }}
                                onClick={() => {
                                  if (!user) {
                                    widgetHelper.login(() => {
                                      dispatch(openSidebar());
                                    });
                                  } else {
                                    chosen?.product?.id !== product?.id &&
                                      setChosen({
                                        ...chosen,
                                        product: product,
                                        quantity: 1,
                                      });
                                    handleSubmit(product, 0);
                                  }
                                }}
                              >
                                לרכישה
                              </Button>
                            </div>
                          </div>

                        </li>
                        {product?.newMerchants
                          .slice(0, 10)
                          .map((merchant, i) => (

                            <li className="product-box">
                              <div
                                onClick={() => {
                                  navigate(`/vendor/${merchant.id}`, {
                                    state: {
                                      id: merchant.id,
                                      product: product,
                                    },
                                  });
                                }}
                                className={`product-box__img`}
                                style={{
                                  backgroundImage: `url(${merchant.bannerImage})`,
                                }}
                              >
                                <div className="product-box__tag">
                                  <img alt="" src={merchant.image} />
                                </div>
                              </div>
                              <div className="product-box__info">
                                <h6 className="product-box__brand">

                                  {merchant.network.name}
                                </h6>
                                <div
                                  className="product-box__desc"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      merchant.content.length > 50
                                        ? merchant.content
                                            .slice(0, 50)
                                            .concat("...")
                                        : merchant.content,
                                  }}
                                >

                                </div>
                                <div className="product-box__infoTop">
                                  {product.price > 0 ? (
                                    <div className="fixed">
                                      {product.price} ₪
                                    </div>
                                  ) : (
                                    <>
                                      <FormControl sx={{ maxWidth: 60 }}>
                                        <TextField
                                          required
                                          type="tel"
                                          size="small"
                                          id="search-bar"
                                          value={
                                            chosen?.product?.id === product.id
                                              ? chosen.price[i + 1]
                                              : ""
                                          }
                                          onChange={(e) => {
                                            const np = [...prices];
                                            np.splice(i + 1, 1, e.target.value);
                                            setChosen({
                                              ...chosen,
                                              product: product,
                                              price: np,
                                            });
                                          }}
                                          placeholder="סכום"
                                          inputProps={{
                                            style: {
                                              fontSize: 12,
                                              minHeight: "23px",
                                              width: "55px",
                                            },
                                          }}
                                        />
                                      </FormControl>
                                      ₪
                                    </>
                                  )}
                                  <FormControl
                                    size="small"
                                    sx={{ minWidth: 60 }}
                                  >
                                    <Select
                                      autoWidth
                                      value={
                                        chosen?.product?.id === product.id
                                          ? chosen.quantity[i + 1] || 1
                                          : 1
                                      }
                                      displayEmpty
                                      inputProps={{
                                        "aria-label": "Without label",
                                      }}
                                      InputLabelProps={{ shrink: false }}
                                      onChange={(e) => {
                                        const nq = [...quantitys];
                                        nq.splice(i + 1, 1, e.target.value);
                                        setChosen({
                                          ...chosen,
                                          product: product,
                                          quantity: nq,
                                        });
                                      }}
                                    >
                                      {amount?.map((el, idx) => (
                                        <MenuItem value={el} key={idx}>
                                          {el}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  <Button
                                    variant="outlined"
                                    sx={{
                                      color: "var(--primary)",
                                      borderColor: "var(--primary)",
                                      "&:hover": {
                                        color: "var(--redish)",
                                        borderColor: "var(--redish)",
                                        background: "#FED8B1",
                                      },
                                    }}
                                    onClick={() => {
                                      if (!user) {
                                        widgetHelper.login(() => {
                                          dispatch(openSidebar());
                                        });
                                      } else {
                                        chosen?.product?.id !== product?.id &&
                                          setChosen({
                                            ...chosen,
                                            merchant: merchant,
                                            product: product,
                                            quantity: 1,
                                          });
                                        handleSubmit(product, i + 1, merchant);
                                      }
                                    }}
                                  >
                                    לרכישה
                                  </Button>
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </ScrollingCarousel>

                  </>
                ))
            : ""}

          <VendorList popular={popular} />
        </div>
        <ClubRegistr />
      </div>
      <Dialog sx={s.dialogContainer} onClose={() => setZero(false)} open={zero}>
        <DialogTitle sx={s.dialogTitle}>נא להכניס סכום</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setZero(false)}
          sx={s.dialogIconButton}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>

      <Dialog
        sx={s.dialogContainer}
        onClose={() => setTooLow(null)}
        open={Boolean(tooLow)}
      >
        <DialogTitle sx={s.dialogTitle}>סכום מינימלי: {tooLow} ₪ </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setTooLow(null)}
          sx={s.dialogIconButton}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>

      <Dialog
        sx={s.dialogContainer}
        onClose={() => setTooHigh(null)}
        open={Boolean(tooHigh)}
      >
        <DialogTitle sx={s.dialogTitle}>סכום מקסימלי: {tooHigh} ₪ </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setTooHigh(null)}
          sx={s.dialogIconButton}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>

      <Dialog
        sx={s.dialogContainer}
        onClose={() => setInCart(false)}
        open={inCart}
      >
        <DialogTitle>
          הפריט כבר נמצא בעגלה {""}
          <Button
            onClick={() => navigate("/cart")}
            variant="contained"
            sx={{ mr: 3 }}
          >
            לעגלה
          </Button>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setInCart(false)}
          sx={s.dialogIconButton}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>
      <Drawer
        open={search}
        onClose={() => setSearch(false)}
        PaperProps={{
          sx: {
            padding: "0 24px 16px 24px",
            width: "90%",
            height: "300px",
            top: "120px",
            right: "5%",
            borderRadius: "15px",
            display: "flex",
            justifyContent: "center",
            margin: "auto",
          },
        }}
        anchor="right"
      >
        <IconButton
          onClick={() => setSearch(false)}
          sx={{
            position: "absolute",
            left: 8,
            top: 8,
            zIndex: "10000",
          }}
        >
          <CloseIcon />
        </IconButton>
        <Search
          filterProducts={filterProducts}
          setFilterProducts={setFilterProducts}
          handleChange={handleChange}
          clearFilter={clearFilter}
          setClearFilter={setClearFilter}
        />
      </Drawer>

      <Dialog
        onClose={() => setCartPop(false)}
        open={cartPop}
        maxWidth="xl"
        fullWidth
        sx={{
          textAlign: "center",
        }}
      >
        <DialogTitle></DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setCartPop(false)}
          sx={{
            position: "absolute",
            left: 8,
            top: 8,
            zIndex: "10000",
          }}
        >
          <CloseIcon />
        </IconButton>
        <Send chosen={chosen} setChosen={setChosen} />
      </Dialog>
    </>
  );
}
