import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import SVG from "react-inlinesvg";
import { Button } from "@material-ui/core";

import styles from "./assets/styles.module.css";
import iosButton from "./assets/iosButton.png";
import closeIcon from "./assets/close.svg";

const LS_KEY = "params_last_close";
const DELAY_DAYS = 7;

const canHidePanel = () => {
  const today = new Date();
  const lastShowParams = JSON.parse(localStorage.getItem(LS_KEY));

  if (!lastShowParams) return false;

  const lastCloseDate = new Date(lastShowParams.lastClose);
  lastCloseDate.setDate(lastCloseDate.getDate() + DELAY_DAYS);

  return today < lastCloseDate;
};

const InstallPWA = (props) => {
  const { onBeforeInstall, onAppInstalled, onChoice, title, setIsVisiblePWA } =
    props;
  const [support, setSupport] = useState(false);
  const [prompt, setPrompt] = useState(null);
  const [isIOS, setIOS] = useState(false);
  const [hide, setHide] = useState(canHidePanel());
  const { t } = useTranslation();

  const checkIOS = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  const isInStandaloneMode = () =>
    "standalone" in window.navigator && window.navigator.standalone;

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      setPrompt(e);
      setSupport(true);

      if (onBeforeInstall) {
        onBeforeInstall(e);
      }
    });

    window.addEventListener("appinstalled", onAppInstalled);
    // TODO: online and offline
    // window.addEventListener('online', ...)
    // window.addEventListener('offline', ...)

    if (checkIOS()) {
      setIOS(true);

      if (isInStandaloneMode()) {
        setHide(true);
      }
    }

    // TODO: online and offline
    // return () => {
    //   window.removeEventListener('online', ...)
    //   window.removeEventListener('offline', ...)
    // }
  }, [onAppInstalled, onBeforeInstall]);

  useEffect(() => {
    setIsVisiblePWA(!canHidePanel());
  }, []);

  const handleClose = () => {
    setHide(true);
    setIsVisiblePWA(false);
    localStorage.setItem(LS_KEY, JSON.stringify({ lastClose: new Date() }));
  };

  const onClick = useCallback(() => {
    if (prompt) {
      prompt.prompt();
      prompt.userChoice.then((e) => {
        if (e.outcome === "accepted") {
          setPrompt(false);
        }

        if (onChoice) {
          onChoice(e);
        }
      });
    }
  }, [prompt, setPrompt, onChoice]);

  if (!support) {
    return null;
  }

  return (
    <>
      {!hide && prompt && (
        <div className={cx(styles.container, "notification")}>
          <div className={styles.description}>
            {isIOS ? (
              <>
                {"התקן את האפליקציה הזה על האייפון שלך: לחץ"}
                <img className={styles.iosButton} alt="" src={iosButton} />
                {"ואז הוסף למסך הבית."}
              </>
            ) : (
              <>{"למען נוחות, הורידו את האפליקציה שלנו בקליק אחד"}</>
            )}
          </div>
          {!isIOS && (
            <Button primary className={styles.notIosButton} onClick={onClick}>
              {t(title)}
            </Button>
          )}
          <SVG className={styles.close} src={closeIcon} onClick={handleClose} />
        </div>
      )}
    </>
  );
};

InstallPWA.propTypes = {
  onBeforeInstall: PropTypes.func,
  onAppInstalled: PropTypes.func,
  onChoice: PropTypes.func,
  title: PropTypes.string,
};

InstallPWA.defaultProps = {
  onBeforeInstall: (e) => {},
  onAppInstalled: (e) => {},
  onChoice: (e) => {},
  title: "התקן",
};

export default InstallPWA;
