import { RegionActionTypes } from "./region.types";

const INITIAL_STATE = {
  isRegionsPending: false,
  regions: [],
  regionsError: null,
};

const RegionReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case RegionActionTypes.GET_REGION_PENDING:
      return {
        ...state,
        regions: [],
        isRegionsPending: true,
      };

    case RegionActionTypes.GET_REGION_SUCCESS:
      return {
        ...state,
        isRegionsPending: false,
        regions: action.payload,
        regionsError: null,
      };

    case RegionActionTypes.GET_REGION_ERROR:
      return {
        ...state,
        regions: [],
        isRegionsPending: false,
        regionsError: action.payload,
      };

    default:
      return state;
  }
};

export default RegionReducer;
