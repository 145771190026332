import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Button } from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import { TextField } from "@mui/material";
// import Rating from "@mui/material/Rating";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
// import LinearProgress from "@mui/material/LinearProgress";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {
  getMerchantById,
  getMerchantProducts,
  getMerchants,
} from "../../redux/API/merchant/merchant.action";
import Send from "../product/Send";
import SendOne from "../product/SendOne";
import { addToCart } from "../../redux/actions-exporter";
import Art from "../../assets/images/art.jpg";
import "../vendor/vendorsDetails.css";
import { openSidebar } from "../../redux/actions-exporter";
import widgetHelper from "../../helpers/widget";
import QuantitySelector from "../quantitySelector";
import { ReactComponent as Location } from "../../assets/icons/locate.svg";

const s = {
  dialogContainer: {
    textAlign: "right",
    width: "400px",
    m: "auto",
  },
  dialogTitle: {
    padding: "16px 50px",
  },
  dialogIconButton: {
    position: "absolute",
    left: 8,
    top: 8,
    zIndex: "10000",
  },
};

export default function VendorsDetails({ isClub }) {
  const merchants = useSelector((state) => state.merchant.merchants);

  const cart = useSelector((state) => state.cart.cart);
  const [inCart, setInCart] = useState(false);
  // const [ratings, setRatings] = React.useState([0, 2, 3, 0, 0]);
  const [cartPop, setCartPop] = useState(false);
  const [fors, setFors] = useState([]);
  const [zero, setZero] = useState(false);
  const [tooLow, setTooLow] = useState(null);
  const [tooHigh, setTooHigh] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [filterProducts, setFilterProducts] = useState([]);
  const [pop, setPop] = useState(false);
  const [error, setError] = useState(false);
  const [chosen, setChosen] = useState({
    product: {},
    price: "",
    quantity: 1,
    vendor: null,
  });
  const { t } = useTranslation();
  const { id } = location?.state;
  const merchant = useSelector((state) => state.merchant.merchant);
  const products = useSelector((state) => state.merchant.merchantProducts);
  const user = useSelector((state) => state?.user?.user);
  const b2b = useSelector((state) => state.tabbing.b2b);
  const [disableBuy, setDisableBuy] = useState();
  const [branchesPop, setBranchesPop] = useState(false);
  const [branches, setBranches] = useState([]);
  const { product } = location?.state;
  let amount = [];
  for (let i = 1; i < 100; i++) amount.push(i);

  useEffect(() => {
    let newM = [
      ...merchants?.filter((m) => m.network?.id == merchant.network?.id),
    ];
    setBranches(newM);
  }, [merchants, merchant]);
  useEffect(() => {
    dispatch(getMerchantById(id));
    dispatch(getMerchantProducts(id));
    if (!merchants.length) dispatch(getMerchants());
  }, [id]); //eslint-disable-line

  useEffect(() => {
    setFilterProducts(
      !b2b
        ? products.filter(
            (product) => product.priceB2B === 0 || product.price > 0
          )
        : products
    );
  }, [b2b, products]);

  const handleFavChange = () => {};
  // const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  //   height: 10,
  //   borderRadius: 5,
  // }));

  // const renderRatings = (item, index) => {
  //   //eslint-disable-line
  //   return (
  //     <div className="reviewList">
  //       <Rating
  //         value={item}
  //         onChange={(event, newValue) => {
  //           let a = ratings;
  //           a[index] = newValue;
  //           setRatings(JSON.parse(JSON.stringify(a)));
  //         }}
  //       ></Rating>
  //       <BorderLinearProgress
  //         id={index}
  //         variant="determinate"
  //         value={item * 20}
  //       />
  //     </div>
  //   );
  // };

  // const handleChange = (event, newValue) => {
  //   if (newValue === "all") {
  //     setFilterProducts(products);
  //   } else {
  //     setFilterProducts(
  //       products.filter(
  //         (product) =>
  //           !!product?.categories?.find((category) => category.id === newValue)
  //       )
  //     );
  //   }
  // };
  // const handleSubmit = (product) => {
  //   if (product.price > 0 || (product.id === chosen.id && chosen.price > 0)) {
  //     let newProduct = { ...product, quantity: chosen.amount };
  //     if (newProduct.price === 0) newProduct.userPrice = Number(chosen.price);
  //     dispatch(addToCart(newProduct.id, newProduct.quantity));
  //   } else {
  //     setError(true);
  //   }
  //   setPop(true);
  // };
  const handleSubmit = (product) => {
    const { minPrice, maxPrice } = product;
    if (cart?.items?.some((a) => a.product.id === product.id)) {
      setInCart(true);
    } else {
      if (
        product.priceB2B > 0 ||
        (!minPrice && !maxPrice) ||
        (!maxPrice && minPrice && chosen.price >= minPrice) ||
        (!minPrice && maxPrice && chosen.price <= maxPrice) ||
        (chosen.price >= minPrice && chosen.price <= maxPrice)
      ) {
        setCartPop(true);
        setChosen({
          ...chosen,
          vendor: merchant || null,

          product: product,
        });
      } else {
        if (!chosen?.price) {
          setZero(true);
        } else {
          chosen.price >= minPrice ? setTooHigh(maxPrice) : setTooLow(minPrice);
        }
      }
    }
  };

  // const handleCart = (e) => {
  //   e.preventDefault();

  //   let newProduct = { ...cartPop, quantity: chosen.amount };
  //   if (newProduct.price === 0) newProduct.price = Number(chosen.price);
  //   dispatch(
  //     addToCart(
  //       newProduct.id,
  //       newProduct.quantity,
  //       newProduct.benefitType === "load" ? newProduct.price : null
  //     )
  //   );
  //   setCartPop(false);
  //   navigate("/cart");
  // };
  return (
    <>
      {/* <Header /> */}
      <div className="container">
        <div className="vendorTemplate">
          <div
            className="vendorbanner"
            style={{
              backgroundImage: `url("${merchant?.bannerImage || Art}")`,
            }}
          >
            {merchant.network && (
              <Button
                variant="contained"
                sx={{
                  m: 1,
                  background: "white",
                  color: "var(--primary)",
                  fontWeight: "bold",
                }}
                size="large"
                onClick={() => setBranchesPop(true)}
              >
                סניפים
              </Button>
            )}
            <div className="row align-items-center">
              <div className="col-lg-8">
                <div className="vendorbanner-brand">
                  <span className="vendorbanner-brandImg">
                    <img src={merchant?.image} className="img-fluid" alt="" />
                  </span>
                  <div className="vendorbanner-brandInfo">
                    {/*<h6 className="vendorbanner-brandTitle">*/}
                    {/*  {t("footLocker")}*/}
                    {/*</h6>*/}
                    <p className="vendorbanner-brandSubtitle">
                      {merchant.network?.name || merchant.title}
                    </p>
                  </div>
                </div>
              </div>
              {/*<div className="col-lg-4">*/}
              {/*  <Button className="blueBtn">{t("follow")}</Button>*/}
              {/*</div>*/}
            </div>
          </div>
          <div style={{ display: "flex", margin: "20px 0" }}>
            <div className="vendorContent">
              {merchant.content || merchant.description ? (
                <>
                  <h6 className="vendorContent-title">{t("description")}</h6>
                  <div
                    className="vendorContent-text"
                    dangerouslySetInnerHTML={{
                      __html: merchant?.content || "",
                    }}
                  >
                    {/* {merchant.content} */}
                  </div>
                  {/* {product &&
                    merchant?.description?.split(/(?= -)/).map((line) => (
                      <div className="vendorContent-text excerpt">{line}</div>
                    ))} */}
                </>
              ) : (
                ""
              )}
            </div>
            {product ? (
              <div style={{ margin: "auto", cursor: "pointer" }}>
                <div className="product-box vd">
                  <div
                    onClick={() => {
                      navigate(`/product/${product.id}`, {
                        state: { id: product.id },
                      });
                    }}
                    className="product-box__img"
                    style={{ backgroundImage: `url(${product.image})` }}
                  ></div>
                  <div className="product-box__info">
                    <div className="product-box__infoTop">
                      <h6 className="product-box__brand">{product.title}</h6>
                      {!isClub ? (
                        <>
                          {product.price > 0 ? (
                            <div className="fixed">
                              ₪{b2b ? product.priceB2B : product.price}{" "}
                            </div>
                          ) : (
                            <>
                              <FormControl sx={{ maxWidth: 60 }}>
                                <TextField
                                  required
                                  type="tel"
                                  size="small"
                                  id="search-bar"
                                  value={
                                    chosen.id === product.id ? chosen.price : ""
                                  }
                                  onChange={(e) =>
                                    setChosen({
                                      ...chosen,
                                      id: product.id,
                                      price: e.target.value,
                                    })
                                  }
                                  placeholder="סכום"
                                  inputProps={{
                                    style: {
                                      fontSize: 12,
                                      minHeight: "23px",
                                      width: "70px",
                                    },
                                  }}
                                />
                              </FormControl>
                              ₪
                            </>
                          )}
                          <FormControl size="small" sx={{ minWidth: 60 }}>
                            <Select
                              autoWidth
                              value={
                                chosen.id === product.id ? chosen.quantity : 1
                              }
                              displayEmpty
                              inputProps={{
                                "aria-label": "Without label",
                              }}
                              InputLabelProps={{ shrink: false }}
                              onChange={(e) =>
                                setChosen({
                                  ...chosen,
                                  id: product.id,
                                  quantity: e.target.value,
                                })
                              }
                            >
                              {amount.map((i) => (
                                <MenuItem value={i}>{i}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <Button
                            disabled={disableBuy}
                            variant="outlined"
                            onClick={() => {
                              if (!user) {
                                widgetHelper.login(() => {
                                  dispatch(openSidebar());
                                });
                              } else {
                                handleSubmit(product);
                              }
                            }}
                          >
                            לרכישה{" "}
                          </Button>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div>
            <div className="productDetails-content module-heading__link">
              <h6 className="productDetails-contentTitle">תוים מכובדים</h6>
            </div>
            {/* <Tabs
              // defaultSelectedIndex={1}
              className="categoriesSliderTabs"
              onChange={handleChange}
            >
              <Tab value="all" label={t("all")}>
                All
              </Tab>
              {merchant?.productCategories?.length > 0 &&
                merchant.productCategories.map((category) => (
                  <Tab value={category.id} label={category.title}>
                    {category.title}
                  </Tab>
                ))}
            </Tabs> */}
          </div>
          <ul className="product-Module">
            {filterProducts.length > 0
              ? filterProducts
                  .filter((item) => item.title !== product?.title)
                  .map((product) => (
                    <>
                      <li className="product-Module__list isLink">
                        <div className="product-box">
                          <div
                            onClick={() => {
                              navigate(`/product/${product.id}`, {
                                state: { id: product.id },
                              });
                            }}
                            className="product-box__img"
                            style={{ backgroundImage: `url(${product.image})` }}
                          >
                            {/* <div className="product-box__likeBtn">
                            <FavoriteBorderOutlinedIcon
                              onClick={() => handleFavChange()}
                            />
                          </div> */}
                          </div>
                          <div className="product-box__info">
                            <div className="product-box__infoTop">
                              <h6 className="product-box__brand">
                                {product.title}
                              </h6>
                              {!isClub ? (
                                <>
                                  {(b2b ? product.priceB2B : product.price) >
                                  0 ? (
                                    <div className="fixed">
                                      ₪{b2b ? product.priceB2B : product.price}{" "}
                                    </div>
                                  ) : (
                                    <>
                                      <FormControl sx={{ maxWidth: 60 }}>
                                        <TextField
                                          required
                                          type="tel"
                                          size="small"
                                          id="search-bar"
                                          value={
                                            chosen.id === product.id
                                              ? chosen.price
                                              : ""
                                          }
                                          onChange={(e) =>
                                            setChosen({
                                              ...chosen,
                                              id: product.id,
                                              price: e.target.value,
                                            })
                                          }
                                          placeholder="סכום"
                                          inputProps={{
                                            style: {
                                              fontSize: 12,
                                              minHeight: "23px",
                                              width: "55px",
                                            },
                                          }}
                                        />
                                      </FormControl>
                                      ₪
                                    </>
                                  )}
                                  <FormControl
                                    size="small"
                                    sx={{ minWidth: 60 }}
                                  >
                                    <Select
                                      autoWidth
                                      value={
                                        chosen.id === product.id
                                          ? chosen.quantity
                                          : 1
                                      }
                                      displayEmpty
                                      inputProps={{
                                        "aria-label": "Without label",
                                      }}
                                      InputLabelProps={{ shrink: false }}
                                      onChange={(e) =>
                                        setChosen({
                                          ...chosen,
                                          id: product.id,
                                          quantity: e.target.value,
                                        })
                                      }
                                    >
                                      {amount.map((i) => (
                                        <MenuItem value={i}>{i}</MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  <Button
                                    variant="outlined"
                                    onClick={() => {
                                      if (!user) {
                                        widgetHelper.login(() => {
                                          dispatch(openSidebar());
                                        });
                                      } else {
                                        handleSubmit(product);
                                      }
                                    }}
                                  >
                                    לרכישה{" "}
                                  </Button>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </li>
                    </>
                  ))
              : t("No products")}
          </ul>
        </div>

        <Dialog
          sx={s.dialogContainer}
          onClose={() => setInCart(false)}
          open={inCart}
        >
          <DialogTitle>
            הפריט כבר נמצא בעגלה {""}
            <Button
              onClick={() => navigate("/cart")}
              variant="contained"
              sx={{ mr: 3 }}
            >
              לעגלה
            </Button>
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setInCart(false)}
            sx={s.dialogIconButton}
          >
            <CloseIcon />
          </IconButton>
        </Dialog>

        <Dialog
          sx={s.dialogContainer}
          onClose={() => setTooLow(null)}
          open={Boolean(tooLow)}
        >
          <DialogTitle sx={s.dialogTitle}>
            סכום מינימלי: {tooLow} ₪{" "}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setTooLow(null)}
            sx={s.dialogIconButton}
          >
            <CloseIcon />
          </IconButton>
        </Dialog>

        <Dialog
          sx={s.dialogContainer}
          onClose={() => setTooHigh(null)}
          open={Boolean(tooHigh)}
        >
          <DialogTitle sx={s.dialogTitle}>
            סכום מקסימלי: {tooHigh} ₪{" "}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setTooHigh(null)}
            sx={s.dialogIconButton}
          >
            <CloseIcon />
          </IconButton>
        </Dialog>

        <Dialog
          sx={s.dialogContainer}
          onClose={() => setZero(false)}
          open={zero}
        >
          <DialogTitle sx={s.dialogTitle}>נא להכניס סכום</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setZero(false)}
            sx={s.dialogIconButton}
          >
            <CloseIcon />
          </IconButton>
        </Dialog>

        <Dialog
          onClose={() => setCartPop(false)}
          open={cartPop}
          maxWidth="xl"
          fullWidth
          sx={{ textAlign: "center" }}
        >
          <DialogTitle></DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setCartPop(false)}
            sx={s.dialogIconButton}
          >
            <CloseIcon />
          </IconButton>
          <Send
            setChosen={setChosen}
            chosen={chosen}
            fors={fors}
            setFors={setFors}
          />
        </Dialog>

        <Dialog
          onClose={() => setBranchesPop(false)}
          open={branchesPop}
          className="branchesModal"
          sx={{
            textAlign: "right",
            overflowY: "hidden !important",
          }}
        >
          <DialogTitle>
            {branches[0]?.network?.name} {"סניפים"}
            <IconButton
              aria-label="close"
              onClick={() => setBranchesPop(false)}
              sx={{
                position: "absolute",
                left: 8,
                top: 8,
                zIndex: "100000",
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <ul className="store-Module" style={{ padding: "2%" }}>
            {branches?.map((store) => (
              <li className="store-Module__list isLink">
                <div
                  className="store-box"
                  style={{ height: "150px !important" }}
                >
                  {store.distance && (
                    <div className="category-boxSubTitle">
                      <Location fill={"var(--primary)"} />
                      {store.distance}
                      <br />
                      ק"מ
                    </div>
                  )}

                  <div className="product-box__discount">
                    <span className="product-box__off">עד 12% הנחה</span>
                  </div>

                  {(store?.kosher == "כשר" ||
                    store?.kosher == "כשר למהדרין") && (
                    <div className="store-box__kosher">
                      {store?.kosher || ""}
                    </div>
                  )}
                  <img
                    alt=""
                    className="store-box__img no"
                    src={store.bannerImage || ""}
                    // onClick={() => {
                    //   navigate(`/vendor/${store.id}`, {
                    //     state: { id: store.id },
                    //   });
                    // }}
                  />
                  <div
                    className="store-box__logo"
                    style={{ backgroundImage: `url(${store.image})` }}
                  ></div>
                  <div className="store-box__info">
                    <h6 className="store-box__brand">{store.title}</h6>

                    <div className="address">
                      {store?.address ? store.address : ""}
                    </div>
                    <div className="phone">{store.telephone || ""}</div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </Dialog>
      </div>
    </>
  );
}
