import React from "react";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../header/Header";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import "./styles.css";

const Index = () => {
  const { orderId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const message = location.state?.message;

  return (
    <div className="pageTemplate">
      {/* <Header isFull={false} /> */}

      <div className="container">
        <div className="pelecard-page__order pelecard__order_error">
          <h3 className="pelecard-page__title">היתה בעיה בהזמנה</h3>
          <div className="pelecard-page__info">הזמנה מספר: {orderId}</div>
          {message && <div className="pelecard-page__info">{t(message)}</div>}
        </div>
        <div className="loadMoneyCard-btn">
          <Button
            className="blueBtn"
            onClick={() => {
              navigate("/");
            }}
          >
            {t("end")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Index;
