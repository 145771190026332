import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/autoplay/autoplay.min.css";
import "swiper/modules/effect-fade/effect-fade.min.css";
import nis from "../../assets/images/nis.png";
import cx from "classnames";

export default function Categories({
  handleChange,
  categories,
  currentCategory,
}) {
  return (
    <Swiper
      className="categorySwiper"
      slidesPerView={"auto"}
      spaceBetween={10}
      centeredSlides={false}
      centerInsufficientSlides={true}
      speed={1000}
      navigation
      modules={[Navigation]}
    >
      <SwiperSlide
        value="all"
        style={{ width: "100px" }}
        onClick={() => handleChange("all")}
        className="categoryList__block isLink"
      >
        <div className="category-box2 text-center">
          <img alt="" src={nis} style={{ width: "30px" }} />

          <h6
            className={cx("category-box__title", {
              "category-box__active": currentCategory === "all",
            })}
          >
            הכל
          </h6>
        </div>
      </SwiperSlide>
      {categories?.map((category, index) => (
        <SwiperSlide
          style={{ width: "100px" }}
          onClick={() => handleChange(category.id)}
          className="categoryList__block isLink"
        >
          <div className="category-box2 text-center">
            <img alt="" src={category.image} style={{ width: "30px" }} />

            <h6
              className={cx("category-box__title", {
                "category-box__active": currentCategory === category.id,
              })}
            >
              {category.title}
            </h6>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
