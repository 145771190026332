import { ProductsActionTypes } from "./product.types";

const INITIAL_STATE = {
  isProductsPending: false,
  originalProducts: [],
  products: [],
  productsError: false,
  filteredProducts: [],
  isProductPending: false,
  product: {},
  productError: false,

  filter: {},
};

const ProductsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case ProductsActionTypes.GET.GET_PRODUCT_PENDING:
      return {
        ...state,
        isProductsPending: true,
      };

    case ProductsActionTypes.GET.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        isProductsPending: false,
        products: action.payload,
        // originalProducts: action.payload,
        productsError: null,
      };

    case ProductsActionTypes.GET.GET_PRODUCT_ERROR:
      return {
        ...state,
        isProductsPending: false,
        productsError: action.payload,
      };

    case ProductsActionTypes.GET.GET_PRODUCT_BY_ID_PENDING:
      return {
        ...state,
        isProductPending: true,
      };

    case ProductsActionTypes.GET.GET_PRODUCT_BY_ID_SUCCESS:
      return {
        ...state,
        isProductPending: false,
        product: action.payload,
        productError: null,
      };

    case ProductsActionTypes.GET.GET_PRODUCT_BY_ID_ERROR:
      return {
        ...state,
        isProductPending: false,
        productError: action.payload,
      };

    case ProductsActionTypes.SET.PRODUCT_FILTER:
      let originalProducts = [...state.products];
      let newProducts = [];

      newProducts = originalProducts?.filter((product) => {
        if (product?.price === 0) {
          return true;
        } else {
          return (
            action.payload?.price[0] <= product?.price &&
            product?.price <= action.payload?.price[1]
          );
        }
        return false;
      });

      if (action.payload?.title) {
        if (newProducts?.length) {
          newProducts = newProducts.filter(
            (product) =>
              product.title.includes(action.payload.title) ||
              product.newMerchants.some(
                (m) =>
                  m.title.includes(action.payload.title) ||
                  product.newMerchants.some((m) =>
                    m.network.name.includes(action.payload.title)
                  )
              )
          );
        }
      }

      if (action.payload?.city) {
        if (newProducts?.length) {
          let newerProducts = [];
          newProducts.map((product) => {
            product.cities.map((city) => {
              if (city === action.payload.city) {
                newerProducts.push(product);
              }
              // return merchant;
            });

            // return product;
          });
          const uniques = Array.from(
            new Set(newerProducts.map((a) => a.id))
          ).map((id) => {
            return newerProducts.find((a) => a.id === id);
          });
          newProducts = [...uniques];
        }
      }

      if (action.payload?.region) {
        if (newProducts?.length) {
          let newerProducts = [];
          newProducts.map((product) => {
            product.regions.map((region) => {
              if (region?.id === action.payload?.region) {
                newerProducts.push(product);
              }
              // return merchant;
            });
            // return product;
          });
          const uniques = Array.from(
            new Set(newerProducts.map((a) => a.id))
          ).map((id) => {
            return newerProducts.find((a) => a.id === id);
          });
          newProducts = [...uniques];
        }
      }
      return {
        ...state,
        filter: action.payload,
        filteredProducts: newProducts,
      };

    default:
      return state;
  }
};

export default ProductsReducer;
