import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import widgetHelper from "../../helpers/widget";
import { openSidebar } from "../../redux/actions-exporter";

import PaymentCard from "../../assets/images/paymentCard.jpg";
import "../wallets/creaditcard.css";

export default function CreaditCard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useSelector((state) => state?.user?.user);

  return (
    <div>
      {/* <Header /> */}
      <div className="creaditCardTemplate">
        <div className="container">
          <div className="templateHeading">
            <span
              className="backIcon isLink"
              onClick={() => {
                if (!user) {
                  widgetHelper.login(
                    () => dispatch(openSidebar())
                    // () => navigate("/login")
                  );
                } else {
                  navigate("/wallet");
                }
              }}
            >
              <ArrowBackIcon />
            </span>
            <h1 className="templateTitle">{t("creditCardOorder")}</h1>
          </div>
          <div className="creaditMoneyCard">
            <img src={PaymentCard} className="img-fluid" alt="My Awesome" />
            <p className="creaditMoneyCard-Text">
              {t("nofees")}
              <span>250</span>
              {t("shekelsAsGift")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
