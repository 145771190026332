import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormGroup, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AiFillCaretUp } from "react-icons/ai";
import { AiFillCaretDown } from "react-icons/ai";

import { getRegions } from "../../redux/actions-exporter";

import "./ProductDetails.css";

const ProductSearch = ({
  merchants,
  filterMerchants,
  setFilterMerchants,
  product,
  isFood,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [region, setRegion] = useState(""); // eslint-disable-line

  // const [area, setArea] = useState("אזור");
  const [kosher, setKosher] = useState("");
  const [category, setCategory] = useState("קטגוריה");
  const [isPop, setIsPop] = useState(true);
  const [isUp, setIsUp] = useState(false);

  const [products, setProducts] = useState([]);
  const [minPrice, setMinPrice] = useState(0); // eslint-disable-line
  const [maxPrice, setMaxPrice] = useState(0); // eslint-disable-line
  const [stores, setStores] = useState([]); // eslint-disable-line
  const [searchText, setSearchText] = useState("");

  const productCategories = useSelector(
    (state) => state.productCategories.originalProductCategories
  );
  const regions = useSelector((state) => state.region.regions);

  useEffect(() => {
    dispatch(getRegions());
  }, []); // eslint-disable-line

  // useEffect(() => {
  //   if (productCategories.length) {
  //     const prepareProduct = productCategories.reduce(
  //       (previous, current) => [
  //         ...previous,
  //         ...current.products.map((product) => ({
  //           ...product,
  //           categoryId: current.id,
  //           categoryName: current.title,
  //         })),
  //       ],
  //       []
  //     );
  //     setProducts(prepareProduct);
  //     setStores(merchants);
  //   }
  // }, [productCategories]);

  // useEffect(() => {
  //   if (products.length) {
  //     const prices = [];
  //     products.forEach((product) => {
  //       if (product?.price) {
  //         prices.push(parseFloat(product.price));
  //       }
  //     });

  //     if (prices.length) {
  //       prices.sort((a, b) => a - b);
  //       setMinPrice(prices[0]);
  //       setMaxPrice(prices[prices.length - 1]);
  //     }
  //   }
  // }, [products]);

  const handleSort = () => {
    let newMerchants = [...filterMerchants];
    isPop !== true
      ? (newMerchants = newMerchants.sort((a, b) => a[0].id - b[0].id))
      : (newMerchants = newMerchants.sort((a, b) =>
          a[0].title.localeCompare(b[0].title)
        ));
    setFilterMerchants(newMerchants);
    setIsPop(!isPop);
  };

  const handleUp = () => {
    let newMerchants = [...filterMerchants];
    newMerchants.reverse();
    setFilterMerchants(newMerchants);
    setIsUp(!isUp);
  };

  const onFilter = () => {
    let newMerchants = [];
    if (searchText !== "") {
      merchants.map((merchant) => {
        if (merchant.title.toLowerCase().includes(searchText.toLowerCase())) {
          newMerchants.push(merchant);
        }
      });
    } else {
      newMerchants = [...merchants];
    }

    // if (kosher == 'לא כשר') {
    // newMerchants = newMerchants.filter(merchant => merchant.kosher == null)
    // } else

    if (kosher !== "") {
      newMerchants = newMerchants.filter(
        (merchant) => merchant.kosher == kosher
      );
    }

    if (region !== "") {
      newMerchants = newMerchants.filter(
        (merchant) => merchant.regionId === region
      );
    }
    let groups = Object.values(
      newMerchants.reduce((acc, curr) => {
        const firstWord = curr.network?.name || curr.title;
        if (!acc[firstWord]) {
          acc[firstWord] = [];
        }

        acc[firstWord].push(curr);
        return acc;
      }, {})
    );
    groups = groups.sort((a, b) => b.length - a.length);
    setFilterMerchants(groups);
  };

  return (
    <div className="search-product">
      <FormGroup className="filter">
        {/* <div className="searchWrapper"> */}

        {/* <FormControl sx={{ m: 1, maxWidth: 100 }}>
          <Select
            displayEmpty
            InputLabelProps={{ shrink: false }}
            autoWidth
            inputProps={{ "aria-label": "Without label" }}
            sx={{ background: "#f9f9f9" }}
            onChange={(e) => setArea(e.target.value)}
            value={regions.length ? regions[0].id : ""}
          >
            {regions.map((region) => (
              <MenuItem value={region.id}>{region.name}</MenuItem>
            ))}
          </Select>
        </FormControl> */}
        {isFood && (
          <FormControl
            sx={{
              m: 1,
              width: 200,
              flexDirection: "row",
              alignItems: "center",
              direction: "rtl",
            }}
          >
            <label style={{ margin: "10px", color: "#aaa" }}>כשרות</label>
            <Select
              displayEmpty
              InputLabelProps={{ shrink: false }}
              // autoWidth
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                background: "#f9f9f9",
                direction: "rtl",
                width: 150,
                textAlign: "right",
              }}
              onChange={(e) => setKosher(e.target.value)}
              value={kosher}
              // placeholder="כשרות"
            >
              <MenuItem value="">הכל</MenuItem>
              <MenuItem value="כשר">כשר</MenuItem>
              <MenuItem value="כשר למהדרין">כשר למהדרין</MenuItem>
            </Select>
          </FormControl>
        )}
        {/* {product.title === "פלטינום - גלובל תו" ? (
          <FormControl sx={{ m: 1, maxWidth: 100 }}>
            <Select
              displayEmpty
              InputLabelProps={{ shrink: false }}
              autoWidth
              inputProps={{ "aria-label": "Without label" }}
              sx={{ background: "#f9f9f9", direction: "ltr" }}
              onChange={(e) => setCategory(e.target.value)}
              value={category}
            >
              <MenuItem value="קטגוריה">קטגוריה</MenuItem>
            </Select>
          </FormControl>
        ) : (
          ""
        )} */}
        {/* </div> */}

        <FormControl sx={{ m: 1, maxWidth: 200 }}>
          <TextField
            id="search-bar"
            sx={{ background: "#f9f9f9" }}
            placeholder={`${t("Search")}`}
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
          />
        </FormControl>
        <FormControl sx={{ m: 1, width: 120 }}>
          <Select
            // disabled={filter.city}
            displayEmpty
            className="selectBorder"
            InputLabelProps={{ shrink: false }}
            autoWidth
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              background: "#f9f9f9",
              textAlign: "right",
            }}
            // onChange={(e) => setArea(e.target.value)}
            onChange={(e) => {
              setRegion(e.target.value);
            }}
            // value={regions.length ? regions[0].id : ""}
            value={region}
          >
            <MenuItem value="">אזור</MenuItem>
            {regions.length > 0
              ? regions.map((region) => (
                  <MenuItem value={region.id}>{region.name}</MenuItem>
                ))
              : ""}
          </Select>
        </FormControl>
        <div className="searchWrapper">
          <Button
            variant="contained"
            sx={{ m: 1, minHeight: 56, background: "var(--primary)" }}
            size="large"
            onClick={onFilter}
          >
            {t("apply")}
          </Button>
        </div>
        <div className="searchWrapper">
          <Button
            variant="outlined"
            sx={{
              m: 1,
              height: 56,
              width: 100,
              display: "flex",
              flexDirection: "column",
            }}
            size="large"
            onClick={handleSort}
          >
            <div>לפי סדר</div>
            <div>{isPop ? "פופולריות " : " א-ב"}</div>
          </Button>
          <Button
            variant="outlined"
            sx={{
              m: 1,
              height: 56,
              width: 40,
              display: "flex",
              flexDirection: "column",
            }}
            size="large"
            onClick={handleUp}
          >
            {isUp ? <AiFillCaretUp /> : <AiFillCaretDown />}
          </Button>
        </div>
      </FormGroup>
    </div>
  );
};

export default ProductSearch;
