export const LeadActionTypes = {
  ADD: {
    LEAD_PENDING: "ADD_LEAD_PENDING",
    LEAD_SUCCESS: "ADD_LEAD_SUCCESS",
    LEAD_ERROR: "ADD_LEAD_ERROR",
  },
  RESET: {
    LEAD_ADD: "RESET_LEAD_ADD",
  },
};
