import React, { useState, useEffect, useRef } from "react";
import "./prices.css";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  getProducts,
  setB2BValue,
} from "../../redux/actions-exporter";
import { Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Search from "../dashboard/Search";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Categories from "../dashboard/Categories";

function Prices() {
  const columns = [
    {
      field: "image",
      sortable: false,
      headerName: "",
      flex: 1,
      editable: false,
      renderCell: (params) => <img src={params.value} alt="cell image" />,
      cellClassName: "cell cellImage",
    },
    {
      field: "title",
      headerName: "שובר (לחץ למיון)",
      flex: 3,
      editable: true,
      cellClassName: "cell cellTitle",
    },
    {
      field: "price",
      headerName: "מחיר (לחץ למיון)",
      flex: 1,
      editable: true,
      cellClassName: "cell cellPrice",
      renderCell: (params) => (
        <>
          {params.row.price > 0
            ? `${params.row.price} ₪`
            : "סכום הטענה (בהנחה)"}
        </>
      ),
    },
    {
      field: "show",
      headerName: "",
      type: "actions",
      getActions: (params) => [
        <Button
          variant="contained"
          sx={{
            m: 1,
            background: "var(--primary)",
            maxWidth: "90% !important",
          }}
          onClick={() => {
            navigate(`/product/${params.row.id}`, {
              state: { id: params.row.id },
            });
          }}
        >
          לפרטים
        </Button>,
      ],
      headerClassName: "cell goto",
      flex: 1,
    },
  ];
  const navigate = useNavigate();
  const [clearFilter, setClearFilter] = useState(false);
  const [categories, setCategories] = useState([]);
  const [filterProducts, setFilterProducts] = useState([]);
  const [currentCategory, setCurrentCategory] = useState("all");
  const [rows, setRows] = useState([]);
  const b2b = useSelector((state) => state.tabbing.b2b);

  const dispatch = useDispatch();
  const coupons = useSelector((state) => state.product.products);
  useEffect(() => {
    if (!coupons.length) dispatch(getProducts());
  }, [dispatch]);

  useEffect(() => {
    let arr = [];
    filterProducts.map((coupon) => {
      arr.push({
        id: coupon.id,
        image: coupon.image,
        title: coupon.title,
        price: `${b2b ? coupon.priceB2B : coupon.price}`,
      });
    });
    setRows(arr);
  }, [filterProducts, b2b]);

  const handleB2B = (event, newValue) => {
    dispatch(setB2BValue(newValue));
  };
  const handleChange = (catId) => {
    if (currentCategory !== catId && catId !== "all") setClearFilter(true);
    setCurrentCategory(catId);
    if (catId === "all") {
      setFilterProducts(coupons);
    } else {
      setFilterProducts(
        coupons.filter(
          (product) =>
            product.categories.findIndex((category) => category.id === catId) >
            -1
        )
      );
    }
  };

  useEffect(() => {
    setFilterProducts(coupons.filter((p) => p.active === true));
    setCategories([]);
    if (coupons.length) {
      const productCategories = new Map();
      coupons.forEach((product) =>
        product.categories.forEach((category) =>
          productCategories.set(category.id, category)
        )
      );
      setCategories([...productCategories.values()]);
    }
  }, [coupons]);

  return (
    <div className="container">
      <Search
        filterProducts={filterProducts}
        setFilterProducts={setFilterProducts}
        handleChange={handleChange}
        clearFilter={clearFilter}
        setClearFilter={setClearFilter}
      />
      <Categories
        categories={categories}
        handleChange={handleChange}
        currentCategory={currentCategory}
      />

      {rows?.length > 0 ? (
        <div className="prices">
          {/* <ToggleButtonGroup
            value={b2b}
            exclusive
            onChange={handleB2B}
            sx={{
              direction: "ltr",
              m: "0 auto 20px",
            }}
          >
            <ToggleButton value={false} className="priceToggle">
              מחירון פרטי
            </ToggleButton>
            <ToggleButton value={true} className="priceToggle">
              מחירון עסקי
            </ToggleButton>
          </ToggleButtonGroup> */}
          <div className="priceDetails">
            {/* <div className="half">
              <h5>דיגיטל - זיכוי כספי:</h5>
              <div> הטען עד 500 ₪ וקבל 5% הנחה (סכום טעינה מינימלי 100 ₪)</div>
              <div>הטען 500 ₪ - 1,000 ₪ וקבל 7% הנחה</div>
              <div>הטען 1,000 ₪ ומעלה וקבל 9% הנחה </div>
              <div>(***ההנחות מחושבות אוטומטית פר טעינה)</div>
            </div> */}
            <div className="half">
              <h5>
                מחירון זה הוא ללקוחות
                {b2b ? "  עסקיים" : " פרטיים"}!
              </h5>
              {!b2b ? (
                <div> - המחירים כוללים מע"מ.</div>
              ) : (
                <>
                  <div> - המחירים אינם כוללים מע"מ.</div>
                  <div>- המחירים מתייחסים לרכישת מינימום 10 שוברים.</div>
                  <div>
                    - בשוברי "גלובל תו" ו"תו העמק" - המחירים מתייחסים להזמנת
                    מינימום שוברים בשווי 1,000 ₪.
                  </div>
                </>
              )}
              <div>
                {" "}
                - לא יינתן החזר כספי או פיצוי בגין אי מימוש, אובדן או גניבה.
              </div>
              <div>- ט.ל.ח.</div>
            </div>
          </div>
          <DataGrid
            disableColumnMenu
            sx={{ fontFamily: "inherit", mb: 5 }}
            rowHeight={80}
            autoHeight
            rows={rows}
            columns={columns}
            pageSize={50}
            disableSelectionOnClick
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Prices;
