import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import {
  MenuItem,
  Select,
  Button,
  DialogTitle,
  Dialog,
  FormControl,
  TextField,
  IconButton,
  Link,
} from "@mui/material";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
// import "swiper/swiper.min.css";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as Location } from "../../assets/icons/locate.svg";
// import cosmeticFace2 from "../../assets/images/ls.jpg";
import ProductSearch from "./ProductSearch";
import Send from "./Send";
import SendOne from "./SendOne";
import parse from "html-react-parser";
import cx from "classnames";
import nis from "../../assets/images/nis.png";
import QuantitySelector from "../quantitySelector";

// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper";
// import "swiper/modules/navigation/navigation.min.css";
// import "swiper/swiper-bundle.min.css";
// import "swiper/swiper.min.css";
import "./ProductDetails.css";
import { indexof } from "stylis";
import {
  addToCart,
  getProductById,
  getProducts,
  getMerchants,
  // getMerchantCategories,
} from "../../redux/actions-exporter";
import Categories from "../dashboard/Categories";
import { openSidebar } from "../../redux/actions-exporter";
import widgetHelper from "../../helpers/widget";

const s = {
  dialogContainer: {
    textAlign: "right",
    width: "400px",
    m: "auto",
  },
  dialogTitle: {
    padding: "16px 50px",
  },
  dialogIconButton: {
    position: "absolute",
    left: 8,
    top: 8,
    zIndex: "10000",
  },
};

export default function ProductDetails({ isClub }) {
  const cart = useSelector((state) => state.cart.cart);
  const [inCart, setInCart] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = location?.state;
  const { products, product } = useSelector((state) => state.product);
  const b2b = useSelector((state) => state.tabbing.b2b);
  const user = useSelector((state) => state?.user?.user);

  const [merchants, setMerchants] = useState([]);
  const [asterisks, setAsterisks] = useState([]);
  const [filterMerchants, setFilterMerchants] = useState([]);
  const [cartPop, setCartPop] = useState(false);
  const [detailsPop, setDetailsPop] = useState(false);
  const [details, setDetails] = useState();
  const [branchesPop, setBranchesPop] = useState(false);
  const [branches, setBranches] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState("all");
  const [selectedTab, setSelectedTab] = useState({ id: "all", title: "all" });
  const [fors, setFors] = useState([]);

  const [catAst, setCatAst] = useState([]);
  const [isFood, setIsFood] = useState(false);
  const [showCat, setShowCat] = useState("");
  const [disableBuy, setDisableBuy] = useState();
  const [chosen, setChosen] = useState({ product: {}, price: "", quantity: 1 });
  const [tooLow, setTooLow] = useState(null);
  const [tooHigh, setTooHigh] = useState(null);
  const [zero, setZero] = useState(false);
  // const [ids, setIds] = useState(false);
  const dbMerchants = useSelector((state) => state.merchant.merchants);
  // const [termsPop, setTermsPop] = useState(false);
  // const [terms, setTerms] = useState(); // eslint-disable-line let amount = [];
  let amount = [];
  for (let i = 1; i < 100; i++) amount.push(i);
  const desc = [];
  product?.description
    ?.split(product?.description.includes("\\n") ? "\\n" : "\n")
    .map((item) => {
      item.length > 0 && desc.push(item.includes("<a") ? parse(item) : item);
    });
  useEffect(() => {
    // if (!product?.length)
    dispatch(getProductById(id));
  }, [id]);

  useEffect(() => {
    !dbMerchants.length && dispatch(getMerchants());
  }, []);
  useEffect(() => {
    if (product?.merchants?.length) {
      const ids = product?.merchants?.map((merchant) => merchant.id);
      setMerchants(
        dbMerchants?.filter(
          (merchant) =>
            merchant.type === "merchant" &&
            merchant.active &&
            ids.includes(merchant.id)
        )
      );
    }
  }, [product, dbMerchants]);

  useEffect(() => {
    setDisableBuy(!b2b && product.priceB2B > 0 && product.price === 0);
  }, [b2b]);

  useEffect(() => {
    if (merchants.length) {
      let newCat = [];
      merchants?.map((merchant) => {
        if (merchant?.productsCategories?.length)
          newCat.push(...merchant.productsCategories);
      });
      const uniques = Array.from(new Set(newCat.map((a) => a.id))).map((id) => {
        return newCat.find((a) => a.id === id);
      });
      setCategories(uniques.filter((u) => u.id !== 8));
      product?.categories?.map((category) => {
        if (
          category.id !== 9 &&
          category.id !== 12 &&
          category.id !== 16 &&
          category.id !== 21
        ) {
          setIsFood(true);
        }
      });
      setShowCat(id);
    } else {
      setCategories([]);
    }
  }, [id, setShowCat, merchants]);

  useEffect(() => {
    let groups = Object.values(
      merchants.reduce((acc, curr) => {
        const firstWord = curr.network?.name || curr.title;
        if (!acc[firstWord]) {
          acc[firstWord] = [];
        }

        acc[firstWord].push(curr);
        return acc;
      }, {})
    );
    groups = groups.sort((a, b) => b.length - a.length);
    setFilterMerchants(groups);
  }, [merchants]);

  const handleChange = (catId) => {
    let newMer = [];
    setCurrentCategory(catId);
    if (catId === "all") {
      newMer = [...merchants];
    } else {
      merchants?.map((merchant) => {
        merchant?.productsCategories?.map((category) => {
          if (category.id === catId) newMer.push(merchant);
        });
      });
    }

    const uniques = Array.from(new Set(newMer.map((a) => a.id))).map((id) => {
      return newMer.find((a) => a.id === id);
    });

    let groups = Object.values(
      uniques.reduce((acc, curr) => {
        const firstWord = curr.network?.name || curr.title;
        if (!acc[firstWord]) {
          acc[firstWord] = [];
        }

        acc[firstWord].push(curr);
        return acc;
      }, {})
    );
    groups = groups.sort((a, b) => b.length - a.length);
    setFilterMerchants(groups);
  };

  useEffect(() => {
    product?.categories?.length &&
      setCatAst(
        currentCategory === "all"
          ? product?.categories
          : product?.categories?.filter(
              (category) => category.id === currentCategory
            )
      );
  }, [currentCategory, product]);

  const handleSubmit = (product) => {
    const { minPrice, maxPrice } = product;
    if (cart?.items?.some((a) => a.product.id === product.id)) {
      setInCart(true);
    } else {
      if (
        product.priceB2B > 0 ||
        (!minPrice && !maxPrice) ||
        (!maxPrice && minPrice && chosen.price >= minPrice) ||
        (!minPrice && maxPrice && chosen.price <= maxPrice) ||
        (chosen.price >= minPrice && chosen.price <= maxPrice)
      ) {
        setCartPop(true);
        setChosen({
          ...chosen,
          product: product,
        });
      } else {
        if (!chosen?.price) {
          setZero(true);
        } else {
          chosen.price >= minPrice ? setTooHigh(maxPrice) : setTooLow(minPrice);
        }
      }
    }
  };
  const handleDetails = (store) => {
    // todo:fix bug on b24!!!!!!!!!!!!!!!!!
    const ast = [];
    store.asterisks
      ?.filter((a) => a.productId === id)
      .map((b) => {
        b.description?.split(/(?= -)/).map((c) => {
          if (!ast.includes(c)) ast.push(c);
        });
      });

    setDetails(store);
    setDetailsPop(true);
    setAsterisks(ast);
  };
  return (
    <div>
      {/* <Header /> */}
      <div className="productDetails">
        <div className="container productTop">
          <div className="productDetails-heading">
            <h5 className="productDetails-title">{product.title}</h5>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="productDetails-info">
                {!isClub ? (
                  <div className="productDetails-inputs">
                    {/* {product.price > 0 ? (
                      <div>
                        ₪
                        {b2b
                          ? product.priceB2B
                          : product.price}{" "}
                      </div> */}
                    {(b2b ? product.priceB2B : product.price) > 0 ? (
                      <div className="fixed">
                        ₪{b2b ? product.priceB2B : product.price}{" "}
                      </div>
                    ) : (
                      <>
                        {disableBuy ? (
                          <div className="fixed">מחיר עסקי בלבד</div>
                        ) : (
                          <>
                            <FormControl sx={{ maxWidth: 110 }}>
                              <TextField
                                required
                                type="tel"
                                size="small"
                                id="search-bar"
                                placeholder="סכום טעינה"
                                value={chosen.price}
                                onChange={(e) =>
                                  setChosen({
                                    ...chosen,
                                    price: e.target.value,
                                  })
                                }
                              />
                            </FormControl>
                            ₪
                          </>
                        )}
                      </>
                    )}
                    <FormControl size="small" sx={{ minWidth: 60 }}>
                      <Select
                        autoWidth
                        value={chosen.quantity}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        InputLabelProps={{ shrink: false }}
                        onChange={(e) =>
                          setChosen({ ...chosen, quantity: e.target.value })
                        }
                      >
                        {amount?.map((i, idx) => (
                          <MenuItem value={i} key={idx}>
                            {i}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button
                      disabled={disableBuy}
                      sx={{ height: 40 }}
                      variant="outlined"
                      onClick={() => {
                        if (!user) {
                          widgetHelper.login(() => {
                            dispatch(openSidebar());
                          });
                        } else {
                          handleSubmit(product);
                        }
                      }}
                    >
                      לרכישה
                    </Button>
                  </div>
                ) : (
                  ""
                )}

                <div className="productDetails-content">
                  <h6 className="productDetails-contentTitle">
                    {t("description")}
                  </h6>
                  <p className="productDetails-contentText content">
                    {product?.content || ""}
                  </p>
                  {/* {product?.description?.split("\n").map((term) => (
                    <p className="productDetails-contentText description">
                      {term.includes("</a>") ? (
                        <>
                          {" "}
                          {term.slice(0, term.indexOf("<a"))}
                          {desc[1]}
                        </>
                      ) : (
                        term
                      )}
                    </p>
                  ))} */}
                  {desc.map((term) => (
                    <p className="productDetails-contentText description">
                      {term[1].type === "a" ? (
                        <span
                          onClick={() => {
                            navigate(`${term[1].props.href}`, {
                              state: {
                                id: term[1].props.href.replace("/product/", ""),
                              },
                            });
                          }}
                        >
                          {" "}
                          {term.slice(0, term.indexOf("<a"))}
                          {/* {term[1]} */}
                        </span>
                      ) : (
                        term
                      )}
                    </p>
                  ))}
                </div>
              </div>
            </div>
            <div className="imgWrapper">
              <div className="productDetails-img">
                <img src={product?.image} alt="" className="img-fluid" />
              </div>
            </div>
          </div>

          <div className="productDetails-content module-heading__link">
            <h6 className="productDetails-contentTitle">בתי עסק מכבדים</h6>
          </div>

          {merchants.length > 0 ? (
            <>
              <ProductSearch
                merchants={merchants}
                filterMerchants={filterMerchants}
                setFilterMerchants={setFilterMerchants}
                product={product}
                isFood={isFood}
              />
              {
                // showCat === id && categories.length > 0
                false ? (
                  <Categories
                    categories={categories}
                    handleChange={handleChange}
                    currentCategory={currentCategory}
                  />
                ) : (
                  ""
                )
              }
            </>
          ) : (
            ""
          )}
          <div
            style={{
              width: "90%",
              textAlign: "right",
              fontStyle: "italic",
              color: "#888",
              paddingBottom: "20px",
            }}
          >
            {catAst?.length > 0 &&
              catAst.map((category) =>
                category.asterisks[0]?.description
                  ?.split(/(?= -)/)
                  .map((asterisk) => (
                    <div style={{ fontSize: "smaller" }} className="test">
                      {asterisk}
                    </div>
                  ))
              )}
          </div>
          <ul className="store-Module">
            {merchants.length > 0
              ? filterMerchants.map((store) => (
                  <>
                    <li className="store-Module__list isLink">
                      <div className="store-box">
                        {/* {store.length === 1 && (
                        <div className="category-boxSubTitle">
                          12 {t("km")}
                          <Location fill={"var(--primary)"} />
                        </div>
                      )} */}
                        <div className="product-box__discount">
                          <span className="product-box__off">עד 12% הנחה</span>
                        </div>
                        {/* <div className="store-box__likeBtn">
                          <FavoriteBorderOutlinedIcon
                            onClick={() => handleFavChange()}
                          />
                        </div> */}
                        {store.length === 1 &&
                          store[0].kosher &&
                          store[0].kosher !== "לא כשר" && (
                            <div className="store-box__kosher">
                              {store[0].kosher || ""}
                            </div>
                          )}
                        <img
                          alt=""
                          className="store-box__img"
                          src={store[0]?.bannerImage || ""}
                          onClick={() => {
                            if (store.length === 1) {
                              navigate(`/vendor/${store[0].id}`, {
                                state: { id: store[0].id, product: product },
                              });
                            }
                          }}
                        />
                        <div
                          className="store-box__logo"
                          style={{ backgroundImage: `url(${store[0]?.image})` }}
                        ></div>
                        <div className="store-box__info">
                          <h6 className="store-box__brand">
                            {store.length === 1
                              ? store[0].title
                              : store[0]?.network?.name}
                          </h6>

                          <div className="address">
                            {store.length === 1 && store[0]?.address
                              ? store[0].address
                              : ""}
                          </div>
                          <div className="phone">
                            {(store.length === 1 && store[0].telephone) || ""}
                          </div>
                        </div>

                        <div className="store-box__infoTop">
                          {store.length > 1 && (
                            <Button
                              sx={{
                                borderColor: "var(--primary)",
                                color: "var(--primary)",
                              }}
                              variant="outlined"
                              onClick={() => {
                                setBranches(store);
                                setBranchesPop(true);
                              }}
                              fullWidth
                            >
                              סניפים
                            </Button>
                          )}
                          <Button
                            sx={{
                              borderColor: "var(--primary)",
                              color: "var(--primary)",
                            }}
                            variant="outlined"
                            onClick={() => handleDetails(store[0])}
                            fullWidth
                          >
                            לפרטים נוספים
                          </Button>
                          {store[0].url && (
                            <Link
                              href={
                                (store[0].url.includes("http")
                                  ? ""
                                  : "https://") + store[0].url
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Button
                                sx={{
                                  borderColor: "var(--primary)",
                                  color: "var(--primary)",
                                }}
                                variant="outlined"
                                fullWidth
                              >
                                לאתר
                              </Button>
                            </Link>
                          )}

                          {/* <Button
                          sx={{
                            borderColor: "var(--primary)",
                            color: "var(--primary)",
                          }}
                          variant="outlined"
                          onClick={() => {
                            store[0].url !== null
                              ? window.open(store[0].url)
                              : window.alert("Site missing");
                          }}
                          fullWidth
                        >
                          לאתר החנות
                        </Button> */}
                        </div>
                      </div>
                    </li>
                  </>
                ))
              : t("No merchant")}
          </ul>
        </div>
      </div>
      <Dialog
        sx={s.dialogContainer}
        onClose={() => setInCart(false)}
        open={inCart}
      >
        <DialogTitle>
          הפריט כבר נמצא בעגלה {""}
          <Button
            onClick={() => navigate("/cart")}
            variant="contained"
            sx={{ mr: 3 }}
          >
            לעגלה
          </Button>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setInCart(false)}
          sx={s.dialogIconButton}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>

      <Dialog sx={s.dialogContainer} onClose={() => setZero(false)} open={zero}>
        <DialogTitle sx={s.dialogTitle}>נא להכניס סכום</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setZero(false)}
          sx={s.dialogIconButton}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>

      <Dialog
        sx={s.dialogContainer}
        onClose={() => setTooLow(null)}
        open={Boolean(tooLow)}
      >
        <DialogTitle sx={s.dialogTitle}>סכום מינימלי: {tooLow} ₪ </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setTooLow(null)}
          sx={s.dialogIconButton}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>

      <Dialog
        sx={s.dialogContainer}
        onClose={() => setTooHigh(null)}
        open={Boolean(tooHigh)}
      >
        <DialogTitle sx={s.dialogTitle}>סכום מקסימלי: {tooHigh} ₪ </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setTooHigh(null)}
          sx={s.dialogIconButton}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>

      <Dialog
        onClose={() => setCartPop(false)}
        open={cartPop}
        maxWidth="xl"
        fullWidth
        sx={{ textAlign: "center" }}
      >
        <DialogTitle></DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setCartPop(false)}
          sx={s.dialogIconButton}
        >
          <CloseIcon />
        </IconButton>
        <Send
          setChosen={setChosen}
          chosen={chosen}
          fors={fors}
          setFors={setFors}
        />
      </Dialog>

      <Dialog
        onClose={() => setDetailsPop(false)}
        open={detailsPop}
        sx={{ textAlign: "right" }}
      >
        <DialogTitle>
          <img
            alt=""
            src={details?.image}
            style={{ width: "30%", marginLeft: "10px" }}
          />
          {details?.network?.name?.length
            ? details?.network?.name
            : details?.title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setDetailsPop(false)}
          sx={s.dialogIconButton}
        >
          <CloseIcon />
        </IconButton>
        <div style={{ padding: "0 24px 16px 24px" }}>
          <h6 dangerouslySetInnerHTML={{ __html: details?.content || "" }}></h6>
          {/* {details?.description?.split(/(?= -)/).map((line) => (
            <div style={{ fontSize: "smaller", color: 'red' }}>{line}</div>
          ))} */}

          {/* {details?.asterisks[0]?.description
            ?.split(/(?= -)/)
            .map((asterisk, i) => (
              <div style={{ fontSize: "smaller" }}>{asterisk}</div>
            ))} */}

          {asterisks
            // ?.filter(a => a.productId === id)
            // description?
            // ?.split(/(?= -)/)
            .map((asterisk, i) => (
              <div
                style={{ fontSize: "smaller" }}
                // dangerouslySetInnerHTML={{ __html: asterisk || '' }}
              >
                {asterisk}
              </div>
            ))}
        </div>
      </Dialog>

      <Dialog
        onClose={() => setBranchesPop(false)}
        open={branchesPop}
        className="branchesModal"
        sx={{
          textAlign: "right",
          overflowY: "hidden !important",
        }}
      >
        <DialogTitle>
          {branches[0]?.network.name} {"סניפים"}
          <IconButton
            aria-label="close"
            onClick={() => setBranchesPop(false)}
            sx={s.dialogIconButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <ul className="store-Module" style={{ padding: "2%" }}>
          {branches?.map((store) => (
            <li className="store-Module__list isLink">
              <div className="store-box" style={{ height: "150px !important" }}>
                {/* <div className="category-boxSubTitle">
                  12 {t("km")}
                  <Location fill={"var(--primary)"} />
                </div> */}
                <div className="product-box__discount">
                  <span className="product-box__off">עד 12% הנחה</span>
                </div>
                {/* <div className="store-box__likeBtn">
                        <FavoriteBorderOutlinedIcon
                          onClick={() => handleFavChange()}
                        />
                      </div> */}
                {store?.kosher !== "לא כשר" && (
                  <div className="store-box__kosher">{store?.kosher || ""}</div>
                )}
                <img
                  alt=""
                  className="store-box__img"
                  src={store.bannerImage || ""}
                  onClick={() => {
                    navigate(`/vendor/${store.id}`, {
                      state: { id: store.id, product: product },
                    });
                  }}
                />
                <div
                  className="store-box__logo"
                  style={{ backgroundImage: `url(${store.image})` }}
                ></div>
                <div className="store-box__info">
                  <h6 className="store-box__brand">{store.title}</h6>

                  <div className="address">
                    {store?.address ? store.address : ""}
                  </div>
                  <div className="phone">{store.telephone || ""}</div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </Dialog>
    </div>
  );
}
