import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

// import Footer from "./components/footer/Footer";
import Footer from "./components/newFooter/Footer";
import Login from "./components/login/Login";
import Register from "./components/register/Register";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import CreatePassword from "./components/sign/CreatePassword";
import UserProfile from "./components/userprofile/UserProfile";
import CategoryDetails from "./components/category/CategoryDetails";
import ProductDetails from "./components/product/ProductDetails";
import ProductLink from "./components/product/Product";
import VendorsDetails from "./components/vendor/VendorDetails";
import VendorLink from "./components/vendor/Vendor";
import Cart from "./components/cart/Cart";
import Wallets from "./components/wallets/Wallets";
import Payment from "./components/wallets/Payment";
import LoadMoney from "./components/wallets/LoadMoney";
import CreaditCard from "./components/wallets/CreaditCard";
import Club from "./components/club/Club";
import Clubs from "./components/clubs/Clubs";
import Dashboard from "./components/dashboard/Dashboard";
import CategoryList from "./components/category/CategoryList";
import NewMap from "./components/location/NewMap";
import PaymentHistory from "./components/wallets/PaymentHistory";
import Menu from "./components/menu/Menu";
import Checkout from "./components/cart/Checkout";
import Pay from "./components/cart/Pay";
import PaymentCardHistory from "./components/wallets/PaymentCardHistory";
import Company from "./components/booking/Company";
import Product from "./components/booking/Product";
import BookingCart from "./components/booking/BookingCart";
import BookingCartDetail from "./components/booking/BookingCartDetail";
import AllVendor from "./components/vendor/AllVendor";
import BookingPayment from "./components/booking/BookingPayment";
import CategoryProduct from "./components/category/CategoryProduct";
import Header from "./components/header/Header";
import ChatBot from "./components/chat/ChatBot";
import RegisterBusiness from "./components/register/RegisterBusiness";
import Shops from "./components/shop/Shops";
import ManageDashboard from "./components/dashboard/ManageDashboard";
import YourAccount from "./components/userprofile/account/YourAccount";
import ReferralProgram from "./components/userprofile/referralprogram/ReferralProgram";
import Filemanager from "./components/userprofile/filemanage/Filemanager";
import Documents from "./components/userprofile/document/Documents";
import Chatting from "./components/userprofile/chat/Chatting";
import Payments from "./components/userprofile/payment/Payments";
import Gift from "./components/gift/Gift";
import GetGift from "./components/gift/getGift/GetGift";
import Accessibility from "./components/accessibility/Accessibility";
import Terms from "./components/terms/Terms";
import Contact from "./components/contact/Contact";
import Think from "./components/think/Think";
import About from "./components/about/About";
import Prices from "./components/prices/Prices";
// import Business from "./components/business/Business";
import Success from "./components/cart/success";
import Error from "./components/cart/error";
import { getUser, setSidebarState, getGeo } from "./redux/actions-exporter";
import Sales from "./components/sales/Sales";
// import Malls from "./components/malls/Malls";
// import MallDetials from "./components/malls/MallDetials";
// import MallEvent from "./components/malls/MallEvent";
import ServiceWorkerWrapper from "./components/ServiceWorkerWrapper";
import InstallPWA from "./components/InstallPWA";
import CookieConsent, {
  Cookies,
  resetCookieConsentValue,
} from "react-cookie-consent";

import "./App.css";
import { borderRadius } from "@mui/system";

function App(props) {
  const dispatch = useDispatch();
  const { isChatOpen } = useSelector((state) => state.chat);
  const [isClub, setIsClub] = useState(false);
  const [isVisiblePWA, setIsVisiblePWA] = useState(false);
  const geo = useSelector((state) => state.user.geo);
  useEffect(() => {
    dispatch(getUser());

    document.addEventListener("b24chat.widget.logout", () => {
      dispatch(setSidebarState(false));
      dispatch(getUser());
    });
    document.addEventListener("b24chat.widget.close", () =>
      dispatch(setSidebarState(false))
    );

    // if (window.location.href.indexOf('/checkout/pay') === -1) {
    //   let accessibility = document.querySelector('[data-accessibility]')
    //   if (!accessibility) {
    //     let script = document.createElement('script'),
    //       scriptJQuery = document.createElement('script'),
    //       scripts = document.getElementsByTagName('script')[0]
    //
    //     scriptJQuery.src = '//code.jquery.com/jquery-1.12.4.js'
    //     script.src = '//www.negishim.com/accessibility/accessibility_pro_group255.js'
    //     script.type = 'text/javascript'
    //     script.setAttribute('data-accessibility', 1)
    //     if (scripts) {
    //       window.accessibility_rtl = true;
    //       window.pixel_from_side = 10;
    //       window.pixel_from_start = 240;
    //       window.hide_header_text = 1;
    //       window.accessibility_rtl = 0;
    //       window.css_style = 1;
    //
    //       scripts.parentNode.insertBefore(scriptJQuery, scripts)
    //       scripts.parentNode.insertBefore(script, scripts)
    //     }
    //   }
    // }
  }, [dispatch]); // eslint-disable-line

  // const isHide =
  //   window.location.href.indexOf("/checkout/success/") > -1 ||
  //   window.location.href.indexOf("/checkout/error") > -1;

  // get location
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  function success(pos) {
    dispatch(
      getGeo({
        latitude: pos.coords.latitude,
        longitude: pos.coords.longitude,
      })
    );
    // const crd = pos.coords;
  }

  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "denied") {
          }
        });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  return (
    <div className={isChatOpen ? "activeChat" : ""}>
      <CookieConsent
        // onAccept={(acceptedByScrolling) => {
        //   if (acceptedByScrolling) {
        //   }
        // }}
        // resetCookieConsentValue()
        location="bottom"
        buttonText="אין בעיה!"
        // "Sure man!!"
        cookieName="myAwesomeCookieName2"
        // style={{         }}
        // disableStyles={true}
        contentClasses="cookieText"
        containerClasses={isVisiblePWA ? "cookie-margin" : "cookie"}
        buttonStyle={{
          background: "var(--primary)",
          fontSize: "13px",
          borderRadius: "10px",
        }}
        expires={150}
      >
        באתר זה נעשה שימוש בעוגיות לטובת שיפור חוויית השימוש
        {/* This website uses cookies to enhance the user experience. */}{" "}
        {/* <span style={{ fontSize: "10px", paddingBottom: '100px !important' }}>This bit of text is smaller :O</span> */}
      </CookieConsent>

      <ServiceWorkerWrapper />
      <InstallPWA setIsVisiblePWA={setIsVisiblePWA} />
      <ToastContainer />
      <BrowserRouter>
        {/* {!isHide &&  */}
        <Header />
        {/* } */}
        <Routes>
          {/* <Route path="/header" element={<Header />} /> */}
          <Route path="/" element={<Dashboard setIsClub={setIsClub} />} />
          <Route
            path="/club/:clubId"
            element={<Club setIsClub={setIsClub} />}
          />
          <Route path="/clubs" element={<Clubs />} />
          {/* <Route path="/footer" element={<Footer />} /> */}
          {/* <Route path="/login" element={<Login />} /> */}
          {/* <Route path="/register" element={<Register />} /> */}
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route
            path="/reset/:token"
            exact
            element={<CreatePassword {...props} />}
          />
          <Route path="/userprofile" element={<UserProfile />} />
          <Route path="/categorydetails" element={<CategoryDetails />} />
          <Route path="/category/:id" element={<CategoryProduct />} />
          <Route
            path="/product/:id"
            element={<ProductDetails {...props} isClub={isClub} />}
          />
          <Route path="/coupon/:id" element={<ProductLink {...props} />} />

          <Route
            path="/vendor/:id"
            element={<VendorsDetails {...props} isClub={isClub} />}
          />
          <Route
            path="/vendor-coupon/:v/:c"
            element={<VendorLink {...props} />}
          />
          <Route path="/sales" element={<Sales />} />
          {/* <Route path="/malls" element={<Malls />} /> */}
          {/* <Route path="/malldetials" element={<MallDetials />} /> */}
          {/* <Route path="/mallevent" element={<MallEvent />} /> */}
          <Route path="/cart" element={<Cart />} />
          <Route path="/wallet" element={<Wallets />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/loadmoney" element={<LoadMoney />} />
          {/*<Route path="/loadcredit" element={<LoadMoney credit={true} />} />*/}
          <Route path="/creaditcard" element={<CreaditCard />} />
          <Route path="/shops" element={<Shops />} />
          <Route path="/categorylist" element={<CategoryList />} />
          <Route path="/map" element={<NewMap />} />
          {/* <Route path="/map" element={<TestMap />} /> */}
          <Route path="/paymenthistory" element={<PaymentHistory />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/checkout/pay" element={<Pay />} />
          <Route path="/paymentcardhistory" element={<PaymentCardHistory />} />
          <Route path="/company" element={<Company />} />
          <Route path="/product" element={<Product />} />
          <Route path="/bookingcart" element={<BookingCart />} />
          <Route path="/bookingcartdetail" element={<BookingCartDetail />} />
          <Route path="/allvendors" element={<AllVendor />} />
          <Route path="/bookingpayment" element={<BookingPayment />} />
          <Route path="/gift/*" element={<Gift />} />
          <Route path="/getgift" element={<GetGift />} />
          <Route path="/chatbot" element={<ChatBot />} />
          <Route path="/registerbusiness" element={<RegisterBusiness />} />
          <Route path="/managedashboard" element={<ManageDashboard />} />
          <Route path="/youraccount" element={<YourAccount />} />
          <Route path="/referralprogram" element={<ReferralProgram />} />
          <Route path="/filemanager" element={<Filemanager />} />
          <Route path="/documents" element={<Documents />} />
          <Route path="/chatting" element={<Chatting />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/accessibility" element={<Accessibility />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/think" element={<Think />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/prices" element={<Prices />} />
          <Route
            path={"/checkout/success/:orderId"}
            element={<Success />}
            exact
          />
          <Route path={"/checkout/error/:orderId"} element={<Error />} />
        </Routes>
        {/* {!isHide &&  */}
        <Footer />
        {/* } */}
      </BrowserRouter>
    </div>
  );
}
export default App;
