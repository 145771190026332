import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { useParams } from "react-router-dom";
import cx from "classnames";
import nis from "../../assets/images/nis.png";
import Categories from "../dashboard/Categories";

import {
  getMerchantById,
  getMerchantProducts,
} from "../../redux/actions-exporter";
import AllCoupons from "./coupon/coupon";
import BasicPagination from "../dashboard/BasicPagination";
import Search from "../dashboard/Search";
import ClubRegistr from "../dashboard/clubRegistr/ClubRegistr";

import Art from "../../assets/images/lg_logo.jpg";
import "./club.css";

const screenWidth = window.innerWidth;
let makeProductsPerPage = 0;
if (screenWidth > 991) {
  makeProductsPerPage = 12;
} else if (screenWidth > 767) {
  makeProductsPerPage = 8;
} else if (screenWidth > 500) {
  makeProductsPerPage = 6;
} else {
  makeProductsPerPage = 12;
}

export default function Club({ setIsClub }) {
  const dispatch = useDispatch();
  const { clubId } = useParams();
  const [currentCategory, setCurrentCategory] = useState("all");
  const [categories, setCategories] = useState([]);
  const [clearFilter, setClearFilter] = useState(false);
  const [filterProducts, setFilterProducts] = useState([]);
  const [BalanceTitleFont, setBalanceTitleFont] = useState("10vw");
  const [currentPage, setCurrentPage] = useState(1); // eslint-disable-line
  const [productsPerPage] = useState(makeProductsPerPage);
  const merchant = useSelector((state) => state.merchant.merchant);
  const products = useSelector((state) => state.merchant.merchantProducts);
  setIsClub(true);
  useEffect(() => {
    dispatch(getMerchantById(clubId));
    dispatch(getMerchantProducts(clubId));
  }, [clubId]); // eslint-disable-line

  useEffect(() => {
    setFilterProducts(products);
    setCategories([]);

    if (products.length) {
      const productCategories = new Map();
      products.forEach((product) =>
        product.categories.forEach((category) =>
          productCategories.set(category.id, category)
        )
      );
      setCategories([...productCategories.values()]);
    }
  }, [products]); // eslint-disable-line

  const handleChange = (catId) => {
    setCurrentPage(1);
    if (currentCategory !== catId && catId !== "all") setClearFilter(true);
    setCurrentCategory(catId);
    if (catId === "all") {
      setFilterProducts(products);
    } else {
      setFilterProducts(
        products.filter(
          (product) =>
            product.categories.findIndex((category) => category.id === catId) >
            -1
        )
      );
    }
  };

  const ref = useRef(null);
  useEffect(() => {
    if (ref.current.offsetWidth < 500) {
      setBalanceTitleFont("67px");
    }
  }, [ref.current]); // eslint-disable-line

  return (
    <>
      <div className="dashboard-tamplate">
        <div className="container" ref={ref}>
          <div
            className="balanceBox"
            style={{
              backgroundImage:
                "url(" +
                (merchant?.bannerImage || merchant?.image || Art) +
                ")",
            }}
          >
            <div className="balanceBoxInner">
              <h1 className="headTitle" style={{ fontSize: BalanceTitleFont }}>
                {/* MallClub */}
              </h1>
              {/* <p className="balanceBoxTitle">{t("yourBalance")}</p> */}
              <h6 className="balanceBoxprice">
                {/* שוברים דיגיטליים בקליק */}
                {/* {user?.money?.toFixed(1) || 0} ₪ / */}
                {/* {user?.credit?.toFixed(1) || 0} {t("e-credit")} */}
              </h6>
            </div>
          </div>
          <Search
            filterProducts={filterProducts}
            setFilterProducts={setFilterProducts}
            handleChange={handleChange}
            clearFilter={clearFilter}
            setClearFilter={setClearFilter}
            hidePrice={true}
          />

          {/* <Swiper //TODO: Slider starts off at the end of the slides, refuses to start at center.
            //initialSlide={5}
            slidesPerView={"auto"}
            spaceBetween={10}
            centeredSlides={true}
            speed={3000}
            style={{ width: "auto", direction: "ltr" }}
          >
            <SwiperSlide
              value="all"
              style={{ width: "auto" }}
              onClick={() => handleChange("all")}
              className="categoryList__block isLink"
            >
              <div className="category-box2 text-center">
                <h6
                  className={cx("category-box__title", {
                    "category-box__active": currentCategory === "all",
                  })}
                >
                  כל הקופונים
                </h6>
              </div>
            </SwiperSlide>
            {categories.map((category, index) => (
              <SwiperSlide
                style={{ width: "auto" }}
                onClick={() => handleChange(category.id)}
                className="categoryList__block isLink"
              >
                <div className="category-box2 text-center">
                  <h6
                    className={cx("category-box__title", {
                      "category-box__active": currentCategory === category.id,
                    })}
                  >
                    {category.title}
                  </h6>
                </div>
              </SwiperSlide>
            ))}
          </Swiper> */}

          <Categories
            categories={categories}
            handleChange={handleChange}
            currentCategory={currentCategory}
          />

          <div className="product-block">
            <AllCoupons
              filterProducts={filterProducts}
              currentPage={currentPage}
              userType="private"
            />

            <BasicPagination
              productsPerPage={productsPerPage}
              totalProducts={filterProducts.length}
              paginate={(e, page) => setCurrentPage(page)}
            />
          </div>
        </div>
        <ClubRegistr />
      </div>
    </>
  );
}
