import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import GoogleMapReact from "google-map-react";
import { TbShirt } from "react-icons/tb";
import { BiCart } from "react-icons/bi";
import { FaRegTimesCircle } from "react-icons/fa";

import { getProducts, getMerchants } from "../../redux/actions-exporter";
import Dashboard from "../dashboard/Dashboard";
import Marker from "./Marker";

import "./map.css";
import nis from "../../assets/images/nis.png";

const getMapOptions = () => {
  return {
    disableDefaultUI: true,
    mapTypeControl: true,
    streetViewControl: true,
    clickableIcons: false,
    styles: [
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "on" }],
      },
    ],
  };
};

const defaultCenter = {
  lng: 34.809185,
  lat: 32.1040256,
};
const locations = [
  { lng: 34.8909185, lat: 31.7040256 },
  { lng: 34.870766, lat: 32.184448 },
  { lng: 35.290146, lat: 32.919945 },
  { lng: 34.8300081, lat: 31.2500163 },
  { lng: 34.77001176, lat: 32.07999147 },
  { lng: 35.217018, lat: 31.771959 },
];

function NewMap() {
  const navigate = useNavigate();
  const [center, setCenter] = useState(defaultCenter);
  const [click, setClick] = useState(true); // eslint-disable-line
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [selectedMap, setSelectedMap] = useState({});
  const [activeMarker, setActiveMarker] = useState(); // eslint-disable-line
  const [open, setOpen] = useState(false); // eslint-disable-line
  const [type, setType] = useState("product");
  const [filterMap, setFilterMap] = useState([]);
  const [category, setCategory] = useState([]); // eslint-disable-line
  const [currentCategory, setCurrentCategory] = useState("all");
  const [filterProducts, setFilterProducts] = useState([]); // eslint-disable-line
  const [categories, setCategories] = useState([]);

  // get products
  const [stores, setStores] = useState([]); // eslint-disable-line

  // const categories = useSelector(
  //   (state) => state.productCategories.productCategories
  // );
  const dispatch = useDispatch();
  const products = useSelector((state) => state.product.products);

  useEffect(() => {

    dispatch(getMerchants());
    dispatch(getProducts());
  }, [dispatch]);

  useEffect(() => {
    setFilterProducts(products);
    setCategories([]);
    if (products.length) {
      const productCategories = new Map();
      products.forEach((product) =>
        product.categories.forEach((category) =>
          productCategories.set(category.id, category)
        )
      );
      setCategories([...productCategories.values()]);
    }
  }, [products]);

  //  search
  // useEffect(() => {
  //   if (categories.length) {
  //     const prepareProduct = categories.reduce(
  //       (previous, current) => [
  //         ...previous,
  //         ...current.products.map((product) => ({
  //           ...product,
  //           categoryId: current.id,
  //           categoryName: current.title,
  //         })),
  //       ],
  //       []
  //     );

  //     let addLocation = prepareProduct.map((product) => {
  //       return {
  //         ...product,
  //         latitude:
  //           merchants.find((merchant) => merchant.id === product.merchantId)
  //             ?.latitude || null,
  //         longitude:
  //           merchants.find((merchant) => merchant.id === product.merchantId)
  //             ?.longitude || null,
  //         city:
  //           merchants.find((merchant) => merchant.id === product.merchantId)
  //             ?.city || null,
  //         address:
  //           merchants.find((merchant) => merchant.id === product.merchantId)
  //             ?.address || null,
  //         store:
  //           merchants.find((merchant) => merchant.id === product.merchantId)
  //             ?.title || null,
  //       };
  //     });

  //     addLocation = addLocation.filter(
  //       (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
  //     );
  //     // setProducts(addLocation);
  //     // setStores(merchants);
  //   }
  // }, [
  //   // categories
  // ]); // eslint-disable-line

  useEffect(() => {
    setCategory(products);
    setFilterMap(products);
  }, [stores, products]);

  const handleClickOpen = (p) => {
    if (showingInfoWindow) {
      setShowingInfoWindow(false);
      setCenter({});
    } else {
      setShowingInfoWindow(true);
      setSelectedMap(p);
      setOpen(true);
      setActiveMarker(null);
      setCenter({});
      setCenter({ lat: p.longitude, lng: p.latitude });
    }
  };
  const handleCategory = (catId) => {
    // if (currentCategory !== catId && catId !== 'all') setClearFilter(true);
    setCurrentCategory(catId);
    if (catId === "all") {
      setFilterProducts(products);
    } else {
      setFilterProducts(
        products.filter(
          (product) =>
            product.categories.findIndex((category) => category.id === catId) >
            -1
        )
      );
    }
  };

  const handleChange = (newValue) => {
    // if (newValue === "mall") {
    //   setType("mall");

    //   setFilterMap([]);
    // } else
    if (newValue === "shop") {
      setFilterMap(stores);
      setType("shop");
    } else if (newValue === "product") {
      setFilterMap(products);
      setType("product");
    }
    // else {
    //   setFilterMap(category);
    // }
  };

  // const mapSearch = (result) => {
  //   let found = {};
  //   if (result.merchantId) {
  //     found = products.find((item) => item.id === result.id);
  //     setFilterMap(products);
  //     setType("product");
  //   } else {
  //     found = stores.find((item) => item.id === result.id);
  //     setFilterMap(stores);
  //     setType("shop");
  //   }
  //   setCenter({ lat: found.longitude, lng: found.latitude });
  // };
  return (
    <>
      {/* <Header isMap={true} mapSearch={mapSearch} /> */}
      <div className={click ? "sidebarTamplate activeTabs" : "sidebarTamplate"}>
        <div className="sidebarModule">
          <Dashboard isMap={true} />
        </div>

        <div className="rightModule">
          <div className="mapFilter">
            <ToggleButtonGroup
              orientation="vertical"
              className="mapFilterGroup"
              color="primary"
              value={currentCategory}
              exclusive
            >
              <ToggleButton
                onClick={() => handleCategory("all")}
                selectedColor="grey"
                value="all"
              >
                <Tooltip title="כל השוברים">
                  <img alt="" src={nis} className="mapIcon" />
                </Tooltip>
              </ToggleButton>
              {categories.map((category) => (
                <ToggleButton
                  onClick={() => handleCategory(category.id)}
                  selectedColor="grey"
                  value={category.id}
                >
                  <Tooltip title={category.title}>
                    <img alt="" src={category.image} className="mapIcon" />
                  </Tooltip>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>

          <div className="mapFilter left">
            <ToggleButtonGroup
              className="mapFilterGroup"
              color="primary"
              value={type}
              exclusive
            >
              <ToggleButton
                onClick={() => handleChange("product")}
                selectedColor="grey"
                value="product"
              >
                <Tooltip title="תוים">
                  <TbShirt />
                </Tooltip>
              </ToggleButton>

              <ToggleButton onClick={() => handleChange("shop")} value="shop">
                <Tooltip title="בתי עסק">
                  <BiCart />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>

            <ToggleButtonGroup className="mapFilterGroup">
              <ToggleButton
                onClick={() => navigate("/")}
                className="outMap"
                value="center"
              >
                <FaRegTimesCircle style={{ margin: "0" }} />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          <div className="mainMap">
            <GoogleMapReact
              bootstrapURLKeys={{}}
              center={center}
              defaultZoom={12}
              options={getMapOptions}
              onDrag={() => setCenter({})}
            >
              {filterMap.length > 0 &&
                filterMap
                  .slice(filterMap.length - 12, filterMap.length - 6)
                  .map((item, index) => {
                    return (
                      <Marker
                        openInfoWindow={() => handleClickOpen(item)}
                        lat={locations[index].lat}
                        lng={locations[index].lng}
                        image={item.image}
                        data={item}
                        selectedMap={selectedMap}
                        show={showingInfoWindow}
                        type={type}
                      ></Marker>
                    );
                  })}
            </GoogleMapReact>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewMap;
