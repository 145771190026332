import React, { useEffect, useState } from "react";
import {
  Link,
  Grid,
  TextField,
  CssBaseline,
  Button,
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  ToggleButtonGroup,
  ToggleButton,
  FormGroup,
  Divider,
  RadioGroup,
  Radio,
  FormControl,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";

import dayjs from "dayjs";

import SmsIcon from "@mui/icons-material/Sms";
import MailIcon from "@mui/icons-material/Mail";
import PrintIcon from "@mui/icons-material/Print";
import CreditCardIcon from "@mui/icons-material/CreditCard";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { useTranslation } from "react-i18next";
import MuiNumberFormat from "../UI/MuiNumberFormat";

import { register, setSidebarState } from "../../redux/actions-exporter";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const hows = [
  {
    value: "sms",
    label: "סמס",
    icon: <SmsIcon />,
  },
  {
    value: "mail",
    label: "מייל",
    icon: <MailIcon />,
  },
  {
    value: "print",
    label: "הדפסה",
    icon: <PrintIcon />,
  },
  {
    value: "card",
    label: "כרטיס",
    icon: <CreditCardIcon />,
  },
];
const theme = createTheme({
  direction: "rtl",
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export default function SendOne({ handleCart, chosen, fors, setFors }) {
  const [formats, setFormats] = useState(() => ["sms"]);
  const [isMe, setIsMe] = useState(true);
  const [isNow, setIsNow] = useState(true);
  const [unmaskedPhoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((state) => state?.user?.user);

  useEffect(() => {
    const amountArr = [];
    for (let i = 1; i <= chosen.amount; i++) {
      amountArr.push({
        name: "",
        mail: "",
        phone: "",
        isDate: false,
        date: "",
        isBless: false,
        bless: "",
      });
    }
    setFors(amountArr);
  }, [chosen]);
  const handleChangePhone = (event) => setPhoneNumber(event.target.value);
  const handleChangeEmail = (event) => setEmail(event.target.value);
  /*const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  };*/
  const onSubmit = (data) => {
    data["phone"] = unmaskedPhoneNumber;
    dispatch(() => data);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t("Email is required"))
      .email(t("Email is invalid")),
    phone: Yup.string().required(t("Phone Number is required")),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleChangePhoneNumber = (e) => setPhoneNumber(e.target.value);

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <Container
          component="main"
          // maxWidth="xs"
        >
          <CssBaseline />
          <Box
            sx={{
              marginTop: 3,
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",
            }}
          >
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ToggleButtonGroup
                    className="sendToggleButtonGroup"
                    m="auto"
                    color="primary"
                    value={formats}
                    onChange={(e, n) => setFormats(n)}
                    aria-label="text formatting"
                  >
                    {hows.map((how) => (
                      <ToggleButton
                        className="sendToggleButton"
                        value={how.value}
                        sx={{
                          flexDirection: "column",
                          width: "100px",
                        }}
                      >
                        {how.icon}
                        {how.label}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  m: 2,
                }}
              >
                {formats.includes("sms") && (
                  <TextField
                    name="phone"
                    required
                    size="small"
                    id="phone"
                    label="מספר נייד"
                    sx={{ mx: 1 }}
                  />
                )}
                {formats.includes("mail") && (
                  <TextField
                    name="email"
                    required
                    size="small"
                    id="email"
                    label="כתובת אימייל"
                    sx={{ mx: 1 }}
                  />
                )}
              </Box>

              <Divider sx={{ width: "90%", m: "auto" }} />

              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    m: "8px",
                  }}
                >
                  <FormControl>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        margin: "8px 0 8px",
                      }}
                    >
                      הקופון עבור
                    </div>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={isMe}
                      onChange={() => setIsMe(!isMe)}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "180px",
                      }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="עצמי"
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="אחר"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>

              {!isMe && (
                <Grid
                  container
                  spacing={2}
                  width="100%"
                  m={{ xs: "0 0 0 -8px", sm: "0 0 0 40px" }}
                  sx={{ textAlign: "start", ml: 4, mt: 0 }}
                >
                  <Grid item xs={12} sm={7}>
                    <TextField
                      fullWidth
                      // size='small'
                      id="blessForAll"
                      label="ברכה..."
                      name="blessForAll"
                      multiline
                      minRows={3}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      size="small"
                      id="from"
                      label="שם השולח/ת"
                      name="from"
                    />
                    <br />
                    <br />
                    {/* <br /> */}
                    <TextField
                      fullWidth
                      size="small"
                      id="for"
                      label="שם המקבל/ת"
                      name="for"
                    />
                  </Grid>
                </Grid>
              )}
              <Divider sx={{ width: "90%", m: "20px auto 30px" }} />
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    m: "8px",
                  }}
                >
                  <FormControl>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        margin: "8px 0 8px",
                      }}
                    >
                      שליחה
                    </div>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={isNow}
                      onChange={() => setIsNow(!isNow)}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "220px",
                      }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="מיידית"
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="מתוזמנת"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              {!isNow && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    my: 2,
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      // sx={{ width: '100%' }}
                      size="small"
                      defaultValue={dayjs()}
                    />
                  </LocalizationProvider>
                </Box>
              )}

              <Button
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                sx={{ width: "200px", mb: 2 }}
              >
                אישור
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </CacheProvider>
  );
}
