import React, { useState, useEffect } from "react";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import PersonOutline from "@material-ui/icons/PersonOutline";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import MenuIcon from "@mui/icons-material/Menu";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@mui/material/Button";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  setGetTabbingValue,
  openSidebar,
  setB2BValue,
  getUnused,
} from "../../redux/actions-exporter";
import widgetHelper from "../../helpers/widget";
import "./header.css";
import Logo from "../../assets/images/matana.png";
import moment from "moment";

const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isMenuOpen, setMenuOpen] = useState(false); // eslint-disable-line
  const [subToggleMenu, setSubToggleMenu] = useState(false);
  const [notification, setNotification] = useState(false);
  const { tabbingValue } = useSelector((state) => state.tabbing);
  const [value, setValue] = useState(tabbingValue);
  const [isPageWithBusinessToggle, setIsPageWithBusinessToggle] =
    useState(false);
  const [products, setProducts] = useState([]);
  const [stores, setStores] = useState([]);
  const [searchText, setSearchText] = useState([]); // eslint-disable-line
  const [minPrice, setMinPrice] = useState(0); // eslint-disable-line
  const [maxPrice, setMaxPrice] = useState(0); // eslint-disable-line
  const [filterProducts, setFilterProducts] = useState([]); // eslint-disable-line
  const [filterStores, setFilterStores] = useState([]); // eslint-disable-line
  const unused = useSelector((state) => state.order?.unused);

  const productCategories = useSelector(
    (state) => state.productCategories.originalProductCategories
  );
  const merchants = useSelector((state) => state.merchant.merchants);
  const b2b = useSelector((state) => state.tabbing.b2b);
  useEffect(() => {
    !unused.length && dispatch(getUnused());
  }, []);
  useEffect(() => {
    const pathname = location.pathname;
    setIsPageWithBusinessToggle(
      pathname.includes("/club") ||
        pathname.includes("/checkout") ||
        pathname.includes("/payment") ||
        pathname.includes("/loadmoney") ||
        pathname.includes("/paymenthistory") ||
        pathname.includes("/checkout/pay") ||
        pathname.includes("/paymentcardhistory") ||
        pathname.includes("/cart") ||
        pathname.includes("/wallet") ||
        pathname.includes("/bookingcart") ||
        pathname.includes("/bookingcartdetail") ||
        pathname.includes("/bookingpayment") ||
        pathname.includes("/payments") ||
        pathname.includes("/prices")
    );
  }, [location]);

  const cartCount = useSelector((state) => state.cart.count);
  const user = useSelector((state) => state?.user?.user);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setGetTabbingValue(newValue));
  };
  const handlenotification = () => {
    if (!notification) {
      setNotification(true);
    } else {
      setNotification(false);
    }
  };
  const handleB2B = (event, newValue) => {
    newValue !== null && dispatch(setB2BValue(newValue));
  };
  return (
    <div className="main ">
      <div className={isMenuOpen ? "mainheader active-menu" : "mainheader"}>
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-7">
              <div className="headerLeft">
                <span
                  className="userBlock-img"
                  onClick={() => {
                    setValue(0);
                    dispatch(setGetTabbingValue(0));
                    navigate("/");
                  }}
                >
                  <img src={Logo} alt="img" className="img-fluid" />
                </span>

                <div className="MainMenu">
                  <Tabs
                    value={value}
                    // indicatorColor="#D97D54"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="disabled tabs example"
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "var(--redish)",
                      },
                    }}
                  >
                    <Tab
                      label="קל לרגש בקליק"
                      onClick={() => {
                        navigate("/");
                      }}
                      s
                    />

                    <Tab
                      label="להתרגש לפני כולם"
                      onClick={() => {
                        navigate("/");
                        document
                          .getElementsByClassName("clubRegister")[0]
                          .scrollIntoView({ behavior: "smooth" });
                      }}
                    />
                    <Tab
                      label="יצירת קשר"
                      onClick={() => {
                        navigate("/contact");
                      }}
                    />
                  </Tabs>

                  {/* {!isClubPages && (
                    <ToggleButtonGroup
                      size="small"
                      value={userType}
                      exclusive
                      onChange={handleUserType}
                      sx={{ direction: "ltr", mr: 2 }}
                    >
                      <ToggleButton value="private">לקוח פרטי</ToggleButton>
                      <ToggleButton value="business">לקוח עסקי</ToggleButton>
                    </ToggleButtonGroup>
                  )} */}
                </div>
              </div>
            </div>

            <div className="col-5 text-right">
              <div className="headerRight">
                <div
                  className="mainheader__btn mainheader__btn--cart d-flex d-lg-none"
                  onClick={() => {
                    if (subToggleMenu) setSubToggleMenu(false);
                    else setSubToggleMenu(true);
                  }}
                >
                  <span className="openMenu">
                    <MenuIcon />
                  </span>
                  <span className="closeMenu">
                    <CloseOutlinedIcon />
                  </span>
                </div>
                {user && (
                  <>
                    <Button className="dropBtn">
                      <PersonOutline
                        onClick={() =>
                          widgetHelper.openProfile(() =>
                            dispatch(openSidebar(), () =>
                              navigate("/userprofile")
                            )
                          )
                        }
                      />
                    </Button>
                    <div className="notificationBlock">
                      <Button className="dropBtn" onClick={handlenotification}>
                        <NotificationsNoneIcon />
                        <span className="subCount">1</span>
                      </Button>
                      {notification ? (
                        <div className="notificationList">
                          <div className="chatMainBox">
                            <div className="chatMainBox__inner">
                              <div className="chatMainBox__img">
                                <img
                                  src={Logo}
                                  alt=""
                                  height={50}
                                  width={50}
                                  className="img-fluid"
                                />
                              </div>
                              <div className="chatMainBox__info">
                                <h4 className="chatMainBox__name">
                                  {t("chatbot")}
                                </h4>
                                <p className="chatMainBox__text">
                                  ברוכים הבאים לאתר מתנה! מועדון ההטבות המוביל
                                  בישראל
                                </p>
                              </div>
                            </div>
                            <span className="chatMainBox__time">{moment().format("HH:mm:ss")}</span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </>
                )}

                {!user && (
                  <Button
                    onClick={() =>
                      widgetHelper.login(() => dispatch(openSidebar()))
                    }
                    className="loginBtn d-lg-block "
                  >
                    {!user ? t("login") : t("sign out")}
                  </Button>
                )}

                <div
                  className="mainheader__btn mainheader__btn--cart"
                  onClick={() => {
                    if (
                      window.location.pathname === "/company" ||
                      window.location.pathname === "/product" ||
                      window.location.pathname === "/bookingcart" ||
                      window.location.pathname === "/bookingcartdetail"
                    )
                      navigate("/bookingcart");
                    else {
                      if (!user) {
                        widgetHelper.login(
                          () => dispatch(openSidebar()),
                          () => navigate("/cart")
                        );
                      } else {
                        navigate("/cart");
                      }
                    }
                  }}
                >
                  <ShoppingCartOutlinedIcon />
                  <span className="subCountRight">{cartCount || 0}</span>
                </div>

                <div className="mainheader__btn mainheader__btn--cart">
                  <AccountBalanceWalletIcon
                    onClick={() => {
                      if (!user) {
                        widgetHelper.login(
                          () => dispatch(openSidebar()),
                          () => navigate("/login")
                        );
                      } else {
                        navigate("/wallet");
                      }
                    }}
                  />
                  <span className="subCountRight">{unused?.length || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-12 d-flex d-lg-none">
              <div
                className={
                  subToggleMenu ? "mobileSearch activeSubMenu" : "mobileSearch "
                }
              >
                <div className="responsiveSubMenu">
                  <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="disabled tabs example"
                    className="MainMenu"
                  >
                    <Tab
                      label={t("home")}
                      onClick={() => {
                        navigate("/");
                      }}
                    />
                    <Tab label={t("about")} onClick={() => {}} />
                    <Tab label={t("terms")} onClick={() => {}} />
                    <Tab
                      label={"מועדונים"}
                      onClick={() => {
                        navigate("/clubs");
                      }}
                    />
                  </Tabs>
                  {/* <div className="hiddenBtns">
                    <ToggleButtonGroup
                      size="small"
                      value={userType}
                      exclusive
                      onChange={handleUserType}
                      sx={{ direction: "ltr" }}
                    >
                      <ToggleButton value="private">לקוח פרטי</ToggleButton>
                      <ToggleButton value="business">לקוח עסקי</ToggleButton>
                    </ToggleButtonGroup>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
