import React, { useState, useEffect, useRef } from "react";
import "./contact.css";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

function Contact() {
  const handleSubmit = (event) => {};
  return (
    <div
      data-elementor-type="wp-page"
      data-elementor-id="4988"
      className=" container elementor-4988 "
      data-elementor-settings="[]"
    >
      <div className="elementor-section-wrap">
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-eb64288 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="eb64288"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-965badf"
              data-id="965badf"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-9e474fc elementor-widget elementor-widget-heading"
                  data-id="9e474fc"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h2 className="elementor-heading-title elementor-size-default">
                      <strong>יצירת קשר</strong>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-da10c3a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="da10c3a"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6cf4b85"
              data-id="6cf4b85"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div className="contact">
                  <Form className="formBox" onSubmit={handleSubmit}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formBasicName"
                    >
                      <Form.Label column sm={3}>
                        שם החברה
                      </Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          name="company"
                          type="text"
                          placeholder="שם החברה"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formBasicName"
                    >
                      <Form.Label column sm={3}>
                        שם פרטי*
                      </Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          required
                          name="first"
                          type="text"
                          placeholder="שם פרטי"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formBasicName"
                    >
                      <Form.Label column sm={3}>
                        שם משפחה*
                      </Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          required
                          name="last"
                          type="text"
                          placeholder="שם משפחה"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formBasicName"
                    >
                      <Form.Label column sm={3}>
                        טלפון*
                      </Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          required
                          name="phone"
                          type="tel"
                          placeholder="טלפון"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formBasicName"
                    >
                      <Form.Label column sm={3}>
                        נייד
                      </Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          name="mobile"
                          type="tel"
                          placeholder="נייד"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formBasicName"
                    >
                      <Form.Label column sm={3}>
                        אימייל*
                      </Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          required
                          name="email"
                          type="email"
                          placeholder="אימייל"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formBasicName"
                    >
                      <Form.Label column sm={3}>
                        נושא הפנייה*
                      </Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          style={{ height: "100px", width: "100%" }}
                          as="textarea"
                          required
                          name="subject"
                          type="text"
                          placeholder="נושא הפנייה"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Col sm={3}></Col>

                      <Col sm={9}>
                        <Form.Check
                          inline
                          reverse
                          // <Form.Check
                          type="checkbox"
                          label="אבקש לקבל עדכונים"
                          style={{ width: "100%", marginRight: 0 }}
                        />
                      </Col>
                    </Form.Group>
                    <Row>
                      <Col sm={3} style={{ color: "gray" }}>
                        *שדה חובה
                      </Col>
                      <Col sm={9}>
                        <Button
                          style={{ width: "100%" }}
                          text="יצירת קשר"
                          type="submit"
                        >
                          שליחה
                        </Button>
                      </Col>
                    </Row>
                  </Form>

                  <div className="formBox">
                    <p>
                      טלפון: <a href="tel:039223392"> 03-9223392</a>
                    </p>
                    <p>
                      פקס: <a href="fax:039223303">03-9223303</a>
                    </p>
                    <p>
                      אימייל:{" "}
                      <a href="mailto:office@raayonit.co.il">
                        office@raayonit.co.il
                      </a>
                    </p>
                    <h6>שעות פעילות:</h6>
                    <p>ימים א‘ - ה‘ בין השעות: 16:30 - 9:00</p>
                    <h6>כתובת למשלוח דואר:</h6>
                    <p>ת.ד. 2066 פ‘‘ת</p>
                    <h6>מיקוד:</h6>
                    <p>4912001</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Contact;
