import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { EmailShareButton, WhatsappShareButton } from "react-share";
import { EmailIcon, WhatsappIcon } from "react-share";

const shareBox = {
  display: "flex",
  justifyContent: "center",
  paddingTop: "20px",
};

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme({
  direction: "rtl",
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export default function GiftForm({ order }) {
  const [receiver, setReceiver] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  };

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar> */}
            <Typography component="h1" variant="h5">
              קניית מתנה
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="נותן המתנה"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={(e) => setReceiver(e.target.value)}
                    required
                    fullWidth
                    id="lastName"
                    label="מקבל המתנה"
                    name="lastName"
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="מייל"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="טלפון"
                    type="tel"
                    id="password"
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker sx={{ width: "100%" }} label="מתי לשלוח" />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <TextareaAutosize
                    aria-label="minimum height"
                    minRows={3}
                    placeholder="ברכה"
                    style={{ width: "100%" }}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid> */}
              </Grid>

              <Box sx={shareBox}>
                <EmailShareButton
                  style={{ width: "100%" }}
                  url={"#"}
                  subject="מתנה מרעיונית!"
                  body={`
                    שלום ${receiver}  ,



                    מעניקים לך 🎁 שובר גלובל תו המקנה זיכוי כספי בסך _ שח

                    קוד מולטיפאס: 
                    ${order?.metadata?.cardNumber || ""} 

                    תוקף: 30/04/28

                    להנחיות למימוש ולרשימת האתרים המכבדים:https://raayonit.b24online.com/product/${""}

                    לבדיקת היתרה: https://raayonit.b24online.com/wallet

                    שירות לקוחות: 039223392

                    שירות לקוחות בנושא תפעול קופה: 0722569255



                                    בברכה,

                    _____`}
                >
                  <EmailIcon size={55} round style={{ marginLeft: "10px" }} />
                  שלח במייל
                </EmailShareButton>
                <WhatsappShareButton
                  style={{ width: "100%" }}
                  title={`
                    שלום ${receiver}  ,



                    מעניקים לך 🎁 שובר גלובל תו המקנה זיכוי כספי בסך _ שח

                    קוד מולטיפאס: 
                    ${order?.metadata?.cardNumber || ""} 

                    תוקף: 30/04/28

                    להנחיות למימוש ולרשימת האתרים המכבדים:https://raayonit.b24online.com/product/${""}

                    לבדיקת היתרה: https://raayonit.b24online.com/wallet

                    שירות לקוחות: 039223392

                    שירות לקוחות בנושא תפעול קופה: 0722569255


                    `}
                  separator={"\n"}
                  url={`
                                    בברכה,

                    _____`}
                >
                  <WhatsappIcon
                    size={55}
                    round
                    style={{ marginLeft: "10px" }}
                  />
                  שלח בווטסאפ
                </WhatsappShareButton>
              </Box>
              {/* <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="#" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid> */}
            </Box>
          </Box>
          {/* <Copyright sx={{ mt: 5 }} /> */}
        </Container>
      </ThemeProvider>
    </CacheProvider>
  );
}
