import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {
  getMerchantById,
  getProductById,
  getMerchants,
} from "../../redux/actions-exporter";
import Art from "../../assets/images/art.jpg";
import "../vendor/vendorsDetails.css";
import { ReactComponent as Location } from "../../assets/icons/locate.svg";

const s = {
  dialogContainer: {
    textAlign: "right",
    width: "400px",
    m: "auto",
  },
  dialogTitle: {
    padding: "16px 50px",
  },
  dialogIconButton: {
    position: "absolute",
    left: 8,
    top: 8,
    zIndex: "10000",
  },
};

export default function VendorsDetails({}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const merchant = useSelector((state) => state.merchant.merchant);
  const [branchesPop, setBranchesPop] = useState(false);
  const [branches, setBranches] = useState([]);
  const { v, c } = useParams();
  const { product } = useSelector((state) => state.product);
  const merchants = useSelector((state) => state.merchant.merchants);

  useEffect(() => {
    let newM = [
      ...merchants?.filter((m) => m.network?.id == merchant.network?.id),
    ];
    setBranches(newM);
  }, [merchants, merchant]);

  useEffect(() => {
    dispatch(getMerchantById(v));
    dispatch(getProductById(c));
    if (!merchants.length) dispatch(getMerchants());
  }, [v, c]); //eslint-disable-line

  const handleFavChange = () => {};

  return (
    <>
      <div className="container">
        <div className="vendorTemplate">
          <div
            className="vendorbanner"
            style={{
              backgroundImage: `url("${merchant?.bannerImage || Art}")`,
            }}
          >
            {merchant.network && (
              <Button
                variant="contained"
                sx={{
                  m: 1,
                  background: "white",
                  color: "var(--primary)",
                  fontWeight: "bold",
                }}
                size="large"
                onClick={() => setBranchesPop(true)}
              >
                סניפים
              </Button>
            )}
            <div className="row align-items-center">
              <div className="col-lg-8">
                <div className="vendorbanner-brand">
                  <span className="vendorbanner-brandImg">
                    <img src={merchant?.image} className="img-fluid" alt="" />
                  </span>
                  <div className="vendorbanner-brandInfo">
                    <p className="vendorbanner-brandSubtitle">
                      {merchant.network?.name || merchant.title}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", margin: "20px 0" }}>
            <div className="vendorContent">
              {merchant.content || merchant.description ? (
                <>
                  <h6 className="vendorContent-title">{t("description")}</h6>
                  <div
                    className="vendorContent-text"
                    dangerouslySetInnerHTML={{
                      __html: merchant?.content || "",
                    }}
                  ></div>
                </>
              ) : (
                ""
              )}
            </div>
            {product ? (
              <div style={{ margin: "auto", cursor: "pointer" }}>
                <div className="product-box vd">
                  <div
                    onClick={() => {
                      navigate(`/product/${product.id}`, {
                        state: { id: product.id },
                      });
                    }}
                    className="product-box__img"
                    style={{ backgroundImage: `url(${product.image})` }}
                  ></div>
                  <div className="product-box__info">
                    <div className="product-box__infoTop">
                      <h6 className="product-box__brand">{product.title}</h6>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <Dialog
          onClose={() => setBranchesPop(false)}
          open={branchesPop}
          className="branchesModal"
          sx={{
            textAlign: "right",
            overflowY: "hidden !important",
          }}
        >
          <DialogTitle>
            {branches[0]?.network?.name} {"סניפים"}
            <IconButton
              aria-label="close"
              onClick={() => setBranchesPop(false)}
              sx={{
                position: "absolute",
                left: 8,
                top: 8,
                zIndex: "100000",
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <ul className="store-Module" style={{ padding: "2%" }}>
            {branches?.map((store) => (
              <li className="store-Module__list isLink">
                <div
                  className="store-box"
                  style={{ height: "150px !important" }}
                >
                  {store.distance && (
                    <div className="category-boxSubTitle">
                      <Location fill={"var(--primary)"} />
                      {store.distance}
                      <br />
                      ק"מ
                    </div>
                  )}

                  <div className="product-box__discount">
                    <span className="product-box__off">עד 12% הנחה</span>
                  </div>

                  {(store?.kosher == "כשר" ||
                    store?.kosher == "כשר למהדרין") && (
                    <div className="store-box__kosher">
                      {store?.kosher || ""}
                    </div>
                  )}
                  <img
                    alt=""
                    className="store-box__img no"
                    src={store.bannerImage || ""}
                  />
                  <div
                    className="store-box__logo"
                    style={{ backgroundImage: `url(${store.image})` }}
                  ></div>
                  <div className="store-box__info">
                    <h6 className="store-box__brand">{store.title}</h6>

                    <div className="address">
                      {store?.address ? store.address : ""}
                    </div>
                    <div className="phone">{store.telephone || ""}</div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </Dialog>
      </div>
    </>
  );
}
