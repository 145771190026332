import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  resetPassword,
  resetVerifyPassword,
  setSidebarState,
} from "../../redux/actions-exporter";

const theme = createTheme({
  direction: "rtl",
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export default function CreatePassword({ setSignUp }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { token } = useParams();
  const { isResetVerifyPasswordPending } = useSelector((state) => state.user);
  const [isVisibleBtn, setIsVisibleBtn] = useState(false);

  useEffect(() => {
    if (token) {
      dispatch(
        resetVerifyPassword({ token }, null, (error) => {
          toast.error(t(error));
          navigate("/");
        })
      );
    }
  }, [token]);

  const validationSchema = Yup.object({
    password: Yup.string()
      .required("password is required")
      .min(6, "should be 6 chars minimum")
      .matches(/^\S*$/, "should not use spaces"),
    passwordConfirmation: Yup.string()
      .required("confirm password is required")
      .oneOf([Yup.ref("password")], "passwords must match"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleChangeForm = (e) => setIsVisibleBtn(!!e.target.value);
  const onSubmit = (data) => {
    dispatch(
      resetPassword(
        {
          token,
          password: data.password,
        },
        t,
        () => {
          toast.info(t("Your password has been successfully changed"));
          navigate("/");
          dispatch(setSidebarState(true));
        }
      )
    );
  };

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          {isResetVerifyPasswordPending ? (
            t("Loading...")
          ) : (
            <>
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 3,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  נא להזין סיסמה חדשה
                </Typography>
                <Box
                  component="form"
                  onSubmit={handleSubmit(onSubmit)}
                  onChange={handleChangeForm}
                  noValidate
                  sx={{ textAlign: "left", mt: 1 }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="password"
                    id="password"
                    {...register("password")}
                    error={!!errors.password}
                    helperText={errors.password?.message || ""}
                    label="סיסמה"
                    name="password"
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="password"
                    id="passwordConfirmation"
                    {...register("passwordConfirmation")}
                    error={!!errors.passwordConfirmation}
                    helperText={errors.passwordConfirmation?.message || ""}
                    label="חזרה על הסיסמה"
                    name="passwordConfirmation"
                    autoFocus
                  />
                  <Button
                    disabled={!isVisibleBtn}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    שליחה
                  </Button>
                  <Grid container>
                    <Grid item>
                      <Link
                        onClick={() => dispatch(setSidebarState(true))}
                        href="#"
                        variant="body2"
                      >
                        {"חזרה להתחברות"}
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </>
          )}
        </Container>
      </ThemeProvider>
    </CacheProvider>
  );
}
