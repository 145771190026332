import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Link from "@mui/material/Link";
import { Button } from "@material-ui/core";
import { ReactComponent as Location } from "../../assets/icons/locate.svg";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {
  getMerchants,
  getMerchantCategories,
} from "../../redux/actions-exporter";

export default function VendorList({ popular }) {
  const [filterMerchants, setFilterMerchants] = useState([]);
  const [detailsPop, setDetailsPop] = useState(false);
  const [details, setDetails] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const merchants = useSelector((state) => state.merchant.merchants);
  const koshaerArr = ["כשר", "כשר למהדרין", "לא כשר"]; //TODO: Swap for coupon kosher value
  const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
  };
  const handleFavChange = () => {};

  useEffect(() => {
    setFilterMerchants(popular);
  }, [popular]); // eslint-disable-line
  useEffect(() => {}, [dispatch]); // eslint-disable-line

  return (
    <>
      <div className="vendorAllListBlock">
        <h6 className="module-heading__title">{t("popularVendors")}</h6>
      </div>
      <ScrollingCarousel>
        <ul className="store-Module">
          {filterMerchants?.length > 0
            ? filterMerchants.slice(filterMerchants.length - 3).map((store) => (
                <>
                  <li className="store-Module__list isLink">
                    <div className="store-box">
                      <div className="product-box__discount">
                        <span className="product-box__off">עד 12% הנחה</span>
                      </div>

                      {store.kosher && (
                        <div className="store-box__kosher">
                          {store.kosher || ""}
                        </div>
                      )}
                      <img
                        alt=""
                        className="store-box__img"
                        src={store.bannerImage || ""}
                        onClick={() => {
                          navigate(`/vendor/${store.id}`, {
                            state: { id: store.id },
                          });
                        }}
                      />
                      <div
                        className="store-box__logo"
                        style={{ backgroundImage: `url(${store.image})` }}
                      ></div>
                      <div className="store-box__info">
                        <h6 className="store-box__brand">{store.title}</h6>
                        <div className="address">
                          {store?.address ? store.address : ""}
                        </div>
                        <div className="phone">{store.telephone || ""}</div>{" "}
                      </div>

                      <div className="store-box__infoTop">
                        <Button
                          sx={{
                            borderColor: "var(--primary)",
                            color: "var(--primary)",
                            "&:hover": {
                              color: "var(--redish)",
                              borderColor: "var(--redish)",
                              background: "#FED8B1",
                            },
                          }}
                          variant="outlined"
                          onClick={() => {
                            setDetails(store);
                            setDetailsPop(true);
                          }}
                          fullWidth
                        >
                          לפרטים נוספים
                        </Button>

                        {store.url && (
                          <Link
                            href={
                              (store.url.includes("http") ? "" : "https://") +
                              store.url
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Button
                              sx={{
                                borderColor: "var(--primary)",
                                color: "var(--primary)",
                              }}
                              variant="outlined"
                              fullWidth
                            >
                              לאתר
                            </Button>
                          </Link>
                        )}

                        {/* <Button
                        sx={{
                          borderColor: "var(--primary)",
                          color: "var(--primary)",
                          '&:hover': {
                            color: "var(--redish)",
                            borderColor: "var(--redish)",
                            background: '#FED8B1'
                          }
                        }}
                        variant="outlined"
                        onClick={() => {
                          store.url !== null
                            ? window.open(store.url)
                            : window.alert("Site missing");
                        }}
                        fullWidth
                      >
                        לאתר החנות
                      </Button> */}
                      </div>
                    </div>
                  </li>
                </>
              ))
            : t("No merchants")}
        </ul>
      </ScrollingCarousel>
      <Dialog
        onClose={() => setDetailsPop(false)}
        open={detailsPop}
        sx={{ textAlign: "right" }}
      >
        <DialogTitle>
          <img
            alt=""
            src={details?.image}
            style={{ width: "30%", marginLeft: "10px" }}
          />
          {details?.title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setDetailsPop(false)}
          sx={{
            position: "absolute",
            left: 8,
            top: 8,
            zIndex: "100000",
          }}
        >
          <CloseIcon />
        </IconButton>
        <div style={{ padding: "0 24px 16px 24px" }}>
          <h6 dangerouslySetInnerHTML={{ __html: details?.content || "" }}></h6>
          {details?.description?.split(/(?= -)/).map((line) => (
            <div style={{ fontSize: "smaller" }}>{line}</div>
          ))}
        </div>
      </Dialog>
    </>
  );
}
