import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FaFacebookF } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addLead, resetLead } from "../../../redux/actions-exporter";

import "./ClubRegistr.css";
import mall1 from "../../../assets/images/mall1.jpg";
import Card from "../../../assets/images/matana_lg.png";
const linkArr = [
  { text: "חומר למחשבה", link: "/think" },
  { text: " יצירת קשר", link: "/contact" },
  { text: "מועדונים", link: "/clubs" },
  { text: "מחירון", link: "/prices" },
  { text: "אודות", link: "/about" },
  { text: "תקנון", link: "/terms" },
  { text: "נגישות", link: "/accessibility" },
];
const ClubRegistr = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const LEAD_SOURCE = "מועדון רעיונית";
  const [form, setForm] = useState({ source: LEAD_SOURCE, firstName: "" });

  const handleSend = (e) => {
    e.preventDefault();
    // if (e.currentTarget.checkValidity() === false) {
    // e.stopPropagation();
    // } else {
    dispatch(addLead(form));
    // }
    // setValidated(true);
  };
  return (
    <>
      <footer className="text-center text-lg-start bg-light text-muted clubRegister">
        <section className="">
          <div className="container text-center text-md-start mt-5 ">
            <div
              className="row mt-3 mobile"
              style={{ flexWrap: "wrap-reverse" }}
            >
              <div className="col-sm-3">
                <section className="d-flex justify-content-around justify-content-lg-around p-4 mx-5 border-bottom">
                  <a
                    href="https://www.facebook.com/raayonit"
                    target="_blank"
                    className="me-4 text-reset"
                  >
                    <FaFacebookF style={{ width: "50px", height: "50px" }} />
                  </a>
                  <a
                    href="https://www.instagram.com/raayon.it/"
                    target="_blank"
                    className="me-4 text-reset"
                  >
                    <AiFillInstagram
                      style={{ width: "50px", height: "50px" }}
                    />
                  </a>
                  {/* <a href="#!" className="me-4 text-reset">
                  <AiOutlineTwitter />
                </a> 
                  <a href="#" className="me-4 text-reset">
                    <AiFillLinkedin />
                  </a>
                   <a href="" className="me-4 text-reset">
                  <FaTiktok />
                </a> */}
                </section>
                <h5 className="text-uppercase bold m-3">קישורים שימושיים:</h5>
                <div className="links">
                  {linkArr.map((el) => (
                    <div
                      onClick={() => navigate(`${el.link}`)}
                      className="text-reset"
                    >
                      {el.text}
                    </div>
                  ))}
                </div>
              </div>

              <div className="d-flex flex-column justify-content-around col-sm-6  mx-auto ">
                <img alt="" className="ClubRegistrImg" src={mall1} />
                <div className="ClubRegistrContent">
                  <h1>הרשמה למועדון לקוחות</h1>
                  <p className="text-right">
                    לקוחות יקרים, בואו להיות חלק מהמועדון התוים הדיגיטליים
                    "MATANA", מועדון לעשרות קניונים בפריסה ארצית. תקבלו מבצעים
                    שווים המתאימים לכם, תוכן, מידע על מוצרים חדשים, אירועים
                    בקניונים וכו'.
                  </p>
                  <Form
                    className="d-flex flex-row justify-content-center p-3 "
                    onSubmit={handleSend}
                  >
                    <Form.Group className="mb-3 " controlId="formBasicEmail">
                      {/* <Form.Label>אי-מייל</Form.Label> */}
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="אימייל"
                        onChange={(e) =>
                          setForm({
                            ...form,
                            email: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <div className="px-2  mb-3">
                      <Button type="submit">הרשמה</Button>
                    </div>
                  </Form>
                </div>
              </div>

              <div className="d-flex flex-column justify-content-around col-sm-3  mx-auto ">
                <img alt="" className="card" src={Card} />
              </div>
            </div>
          </div>
        </section>

        <div
          className="text-center p-4"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
        >
          {"© 2022 Copyright: "}
          <a className="text-reset fw-bold" href="#!">
            {"Matana"}
          </a>
        </div>
      </footer>
    </>
  );
};

export default ClubRegistr;
