import { RegionActionTypes } from "./region.types";
import { axiosInstance } from "../../axios.config";

export const getRegions = () => (dispatch) => {
  dispatch({ type: RegionActionTypes.GET_REGION_PENDING });

  axiosInstance
    .get(`/region`)
    .then((res) => {
      dispatch({
        type: RegionActionTypes.GET_REGION_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: RegionActionTypes.GET_REGION_ERROR,
        payload: error.response.data,
      });
    });
};
