import "./accessibility.css";
function Accessibility() {
  return (
    <div>
      <div
        data-elementor-type="wp-page"
        data-elementor-id="4988"
        className="elementor elementor-4988"
        data-elementor-settings="[]"
      >
        <div className="elementor-section-wrap">
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-b291602 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="b291602"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c9fbde7"
                data-id="c9fbde7"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-e0de0b3 elementor-widget elementor-widget-heading"
                    data-id="e0de0b3"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h1 className="elementor-heading-title elementor-size-default">
                        <strong>הצהרת נגישות</strong>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-eb64288 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="eb64288"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-965badf"
                data-id="965badf"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-9e474fc elementor-widget elementor-widget-heading"
                    data-id="9e474fc"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        {/* הצהרת נגישות לאתר רעיונית */}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-da10c3a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="da10c3a"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6cf4b85"
                data-id="6cf4b85"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-3801231 elementor-widget elementor-widget-text-editor"
                    data-id="3801231"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          בשנים האחרונות אתרי האינטרנט הפכו להיות הפלטפורמה
                          העיקרית לפרסום המידע והשירותים השונים והמגוונים
                          המוצעים על ידי החברה לציבור הרחב.&nbsp;
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400, direction: "rtl" }}>
                          באתר אינטרנט זה ניתן לצפות במידע אודות רעיונית.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          מטרתנו בהנגשת אתר אינטרנט זה היא יצירת שוויון
                          הזדמנויות מלא ומקיף במרחב האינטרנטי לאנשים עם לקויות
                          מגוונות ואנשים הנעזרים בטכנולוגיות מסייעות שונות בעת
                          הגלישה באתר והשימוש בו.
                        </span>
                      </p>
                      <h2>
                        <strong>התאמות הנגישות באתר האינטרנט</strong>
                      </h2>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          באתר אינטרנט זה בוצעו התאמות נגישות בהתאם להמלצות התקן
                          הישראלי (ת”י 5568) ולנגישות תכנים באינטרנט ברמת AA
                          בשילוב המלצות מסמך WCAG2.0 שפורסם באמצעות הארגון
                          הבינלאומי W3C העוסק בתקינה ברשת האינטרנט.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          ההתאמות שבוצעו באתר נבדקו באמצעות הדפדפנים הנפוצים
                          ביותר: גוגל כרום ואינטרנט אקספלורר. לשם קבלת חווית
                          גלישה נעימה ומיטבית עם תוכנה להקראת מסך אנו ממליצים
                          להשתמש בתוכנת NVDA בגרסה העדכנית ביותר.
                        </span>
                      </p>
                      <h3>
                        <b>
                          לצורך הנגשת האתר לאנשים עם מוגבלות בוצעו מספר רב של
                          פעולות, בניהן:
                        </b>
                      </h3>
                      <ul>
                        <li style={{ fontWeight: 400 }} aria-level="1">
                          <span style={{ fontWeight: 400 }}>
                            שימוש בניגודיות חזותית מתאימה בין הטקסט לרקע.
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level="1">
                          <span style={{ fontWeight: 400 }}>
                            ביצוע שינוי חזותי כפול בעת ריחוף מעל התפריטים
                            המרכזיים המופיעים באתר.
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level="1">
                          <span style={{ fontWeight: 400 }}>
                            שימוש בכותרות מתאימות במסגרת עמודי האתר.
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level="1">
                          <span style={{ fontWeight: 400 }}>
                            הוספת כפתור חיפוש בכל עמודי האתר לצורך התמצאות טובה
                            יותר באתר.
                          </span>
                        </li>
                      </ul>
                      <h3>
                        <b>
                          התאמות שבוצעו באתר לגולשים המשתמשים בתוכנה להקראת מסך:
                        </b>
                      </h3>
                      <ul>
                        <li style={{ fontWeight: 400 }} aria-level="1">
                          <span style={{ fontWeight: 400 }}>
                            שימוש במבנה אתר סמנטי ונוח למשתמשים המסתייעים
                            באמצעות קורא מסך.
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level="1">
                          <span style={{ fontWeight: 400 }}>
                            שימוש בשפה פשוטה ובהירה בכל עמודי האתר.
                          </span>
                        </li>
                      </ul>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          הייעוץ והליווי בנושא הנגשת אתר האינטרנט של חברת פרומול
                          בוצע על ידי צוות{" "}
                        </span>
                        <span style={{ textDecoration: "underline" }}>
                          <a href="http://www.web-a.co.il/">
                            <span style={{ fontWeight: 400 }}>
                              WEB-A&nbsp; הנגשת אתרים, אפליקציות ומערכות
                              מתקדמות.
                            </span>
                          </a>
                        </span>
                      </p>
                      <h2>
                        <strong>הסדרי נגישות למרכזי רעיונית</strong>
                      </h2>
                      <h3>
                        <strong>
                          להלן פירוט אודות הסדרי הנגישות בכל מרכזי רעיונית:
                        </strong>
                      </h3>
                      <ul>
                        <li style={{ fontWeight: 400 }} aria-level="1">
                          <span style={{ fontWeight: 400 }}>
                            בכל המרכזים ישנם מקומות חניה נגישים לרכב רגיל ורכב
                            גבוה.
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level="1">
                          <span style={{ fontWeight: 400 }}>
                            הכניסה למרכזים נגישה.
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level="1">
                          <span style={{ fontWeight: 400 }}>
                            המעליות הקיימות במרכזים נגישות.
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level="1">
                          <span style={{ fontWeight: 400 }}>
                            בכל המרכזים ישנם תאי שירותים נגישים (מלבד המרכז עתיר
                            ידע שלא קיימים בו שירותים ציבוריים).
                          </span>
                        </li>
                      </ul>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          לנוחות המבקרים במסגרת אתר האינטרנט נוספו גם הסדרי
                          הנגישות בכל עמודי המרכזים בצורה בולטת וברורה.
                        </span>
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <b>
                          צוות חברת רעיונית מאמין כי לכל אדם עם מוגבלות צריכה
                          להיות היכולת להשתמש בשירותים המוצעים באתר האינטרנט
                          בצורה שווה, מהנה וחווייתית.
                        </b>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          באתר אינטרנט זה בוצעו שינויים והתאמות מיוחדות באמצעות
                          הטכנולוגיה העדכנית והמתאימה ביותר לצרכי לקוחותינו
                          והגולשים באתר.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          ייתכן וימצאו אלמנטים שאינם מונגשים כי טרם הונגשו או
                          שלא נמצאה טכנולוגיה מתאימה ולצד זה אנו מבטיחים כי
                          מתבצעים מרב המאמצים לשפר ולהנגיש ברמה גבוהה וללא
                          פשרות.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          במידה ונתקלתם ברכיב שאינו נגיש באתר אתם מוזמנים לפנות
                          אלינו ואנו מבטיחים
                        </span>
                        <span style={{ fontWeight: 400 }}>
                          <br />
                        </span>
                        <span style={{ fontWeight: 400 }}>
                          לבדוק לשפר אותו בהקדם האפשרי וכמובן להעניק לכם את
                          השירות הטוב ביותר בצורה מהירה, אישית ובהתאם לשביעות
                          רצונכם.
                        </span>
                      </p>
                      <p>
                        <b>הצהרת הנגישות עודכנה בתאריך: 28.12.2021</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Accessibility;
