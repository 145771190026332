import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as Location } from "../../assets/icons/locate.svg";
import {
  Button,
  Slider,
  Box,
  Dialog,
  DialogTitle,
  TextField,
  CircularProgress,
  Link,
} from "@mui/material";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
// import cosmeticFace2 from "../../assets/images/ls.jpg";
import cx from "classnames";
import nis from "../../assets/images/nis.png";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper";
// import "swiper/modules/navigation/navigation.min.css";
// import "swiper/swiper-bundle.min.css";
// import "swiper/swiper.min.css";
import Categories from "../dashboard/Categories";

import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import {
  getProducts,
  getMerchants,
  getRegions,
  // getMerchantCategories,
} from "../../redux/actions-exporter";
import "../vendor/vendorsDetails.css";

const marks = [
  {
    value: 0,
    label: '1 ק"מ',
  },
  {
    value: 1,
    label: '5 ק"מ',
  },
  {
    value: 2,
    label: '10 ק"מ',
  },
  {
    value: 3,
    label: '50 ק"מ',
  },
  {
    value: 4,
    label: "הכל",
  },
];

export default function AllVendor() {
  const regions = useSelector((state) => state.region?.regions);
  const koshaerArr = ["כשר", "כשר למהדרין", "לא כשר"]; //TODO: Swap for coupon kosher value
  const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
  };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dbMerchants = useSelector((state) => state.merchant.merchants);
  const merchants = dbMerchants.filter(
    (merchant) => merchant.type === "merchant" && merchant.active === true
  );
  const merchantsPending = useSelector(
    (state) => state.merchant.isMerchantsPending
  );
  const [selectedTab, setSelectedTab] = useState({ id: "all", title: "all" }); // eslint-disable-line

  const [dis, setDis] = useState(4);
  const [categories, setCategories] = useState([]);
  const [merchantsList, setMerchantsList] = useState();
  const [filterMerchants, setFilterMerchants] = useState([]);
  const [detailsPop, setDetailsPop] = useState(false);
  const [branchesPop, setBranchesPop] = useState(false);
  const [branches, setBranches] = useState([]);
  const [details, setDetails] = useState();
  const [currentCategory, setCurrentCategory] = useState("all");
  const products = useSelector((state) => state.product.products);
  const geo = useSelector((state) => state.user.geo);
  useEffect(() => {
    setCategories([]);
    if (products.length) {
      const productCategories = new Map();
      products?.forEach((product) =>
        product?.categories?.forEach((category) =>
          productCategories.set(category.id, category)
        )
      );
      setCategories([...productCategories.values()]);
    }
  }, [products]);

  const handleChange = (catId) => {
    let newM = [];
    setCurrentCategory(catId);
    if (catId === "all") {
      newM = [...merchants];
    } else {
      merchants.map((merchant) => {
        // merchant.products.map((product) => {
        merchant.productsCategories.map((category) => {
          if (category.id === catId) newM.push(merchant);
        });
      });
      // });
    }
    const uniques = Array.from(new Set(newM.map((a) => a.id))).map((id) => {
      return newM.find((a) => a.id === id);
    });
    let groups = Object.values(
      uniques.reduce((acc, curr) => {
        const firstWord = curr.network?.name || curr.title;
        if (!acc[firstWord]) {
          acc[firstWord] = [];
        }

        acc[firstWord].push(curr);
        return acc;
      }, {})
    );
    groups = groups.sort((a, b) => b.length - a.length);
    setFilterMerchants(groups);
  };

  useEffect(() => {
    if (!products.length) dispatch(getProducts());
    if (!merchants.length) dispatch(getMerchants());
    if (!regions?.length) dispatch(getRegions());
  }, [dispatch]);

  useEffect(() => {
    let groups = Object.values(
      merchants.reduce((acc, curr) => {
        const firstWord = curr.network?.name || curr.title;
        if (!acc[firstWord]) {
          acc[firstWord] = [];
        }

        acc[firstWord].push(curr);
        return acc;
      }, {})
    );
    groups = groups.sort((a, b) => b.length - a.length);
    setFilterMerchants(groups);
  }, [dbMerchants, dis]);

  const handleFavChange = () => {};

  const handleSearch = (e) => {
    let newM = merchants.filter((merchant) => {
      return (
        merchant.title.toLowerCase().includes(e.toLowerCase()) ||
        merchant.network?.name.toLowerCase().includes(e.toLowerCase())
      );
    });
    let groups = Object.values(
      newM.reduce((acc, curr) => {
        const firstWord = curr.network?.name || curr.title;
        if (!acc[firstWord]) {
          acc[firstWord] = [];
        }
        acc[firstWord].push(curr);
        return acc;
      }, {})
    );
    groups = groups.sort((a, b) => b.length - a.length);
    setFilterMerchants(groups);
  };
  useEffect(() => {
    const ranges = [
      100, 5000, 10000, 50000,
      // 0
      10000000000,
    ];
    dispatch(getMerchants(geo.longitude, geo.latitude, ranges[dis]));
  }, [dis, geo.longitude, geo.latitude]);

  return (
    <div>
      <div className="pageTemplate">
        <div className="container">
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="vendorSearch">
              <h6 className="module-heading__title vendorSearchTitle">
                חיפוש:{" "}
              </h6>
              <TextField
                id="search-bar"
                sx={{ background: "#f9f9f9", ml: 5 }}
                placeholder="בית העסק"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>

            {geo.latitude && (
              <Box
                sx={{
                  width: 350,
                  my: 2,
                  mx: 0,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h6 className="module-heading__title vendorSearchTitle">
                  מרחק:
                </h6>
                <Slider
                  sx={{
                    color: "var(--primary)",
                    fontFamily: "inherit !important",
                    ml: 2,
                  }}
                  onChange={(e, value) => {
                    setDis(value);
                  }}
                  min={0}
                  max={4}
                  value={dis}
                  step={null}
                  marks={marks}
                />
              </Box>
            )}
          </Box>
          <Categories
            categories={categories}
            handleChange={handleChange}
            currentCategory={currentCategory}
          />

          <div className="vendorAllListBlock">
            <h6 className="module-heading__title">{t("allvendors")}</h6>
            <ul className="store-Module">
              {filterMerchants?.length > 0 ? (
                filterMerchants.map((store) => (
                  <>
                    <li className="store-Module__list isLink">
                      <div className="store-box">
                        {store[0].distance && store.length === 1 && (
                          <div className="category-boxSubTitle">
                            <Location fill={"var(--primary)"} />
                            {store[0].distance}
                            <br />
                            ק"מ
                          </div>
                        )}
                        <div className="product-box__discount">
                          <span className="product-box__off">עד 12% הנחה</span>
                        </div>
                        {/* <div className="store-box__likeBtn">
                          <FavoriteBorderOutlinedIcon
                            onClick={() => handleFavChange()}
                          />
                        </div> */}
                        {store.length == 1 &&
                          store[0].kosher &&
                          store[0].kosher != "לא כשר" && (
                            <div className="store-box__kosher">
                              {store[0].kosher || ""}
                            </div>
                          )}
                        <img
                          alt=""
                          className="store-box__img"
                          src={store[0].bannerImage || ""}
                          onClick={() => {
                            // if (store.length === 1) {
                            navigate(`/vendor/${store[0].id}`, {
                              state: { id: store[0].id },
                            });
                            // }
                          }}
                        />
                        <div
                          className="store-box__logo"
                          style={{ backgroundImage: `url(${store[0]?.image})` }}
                        ></div>
                        <div className="store-box__info">
                          <h6 className="store-box__brand">
                            {store.length === 1
                              ? store[0].title
                              : store[0].network?.name}
                          </h6>

                          <div className="address">
                            {store.length === 1 && store[0]?.address
                              ? store[0].address
                              : ""}
                          </div>
                          <div className="phone">
                            {(store.length === 1 && store[0].telephone) || ""}
                          </div>
                        </div>

                        <div className="store-box__infoTop">
                          {store.length > 1 && (
                            <Button
                              sx={{
                                borderColor: "var(--primary)",
                                color: "var(--primary)",
                              }}
                              variant="outlined"
                              onClick={() => {
                                setBranches(store);
                                setBranchesPop(true);
                              }}
                              fullWidth
                            >
                              סניפים
                            </Button>
                          )}
                          <Button
                            sx={{
                              borderColor: "var(--primary)",
                              color: "var(--primary)",
                            }}
                            variant="outlined"
                            onClick={() => {
                              setDetails(store[0]);
                              setDetailsPop(true);
                            }}
                            fullWidth
                          >
                            לפרטים נוספים
                          </Button>
                          {store[0].url && (
                            <Link
                              href={
                                (store[0].url.includes("http")
                                  ? ""
                                  : "https://") + store[0].url
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Button
                                sx={{
                                  borderColor: "var(--primary)",
                                  color: "var(--primary)",
                                }}
                                variant="outlined"
                                fullWidth
                              >
                                לאתר
                              </Button>
                            </Link>
                          )}
                          {/* 
                          <Button
                            sx={{
                              borderColor: "var(--primary)",
                              color: "var(--primary)",
                            }}
                            variant="outlined"
                            onClick={() => {
                              store[0].url !== null
                                ? window.open(store[0].url)
                                : window.alert("Site missing");
                            }}
                            fullWidth
                          >
                            לאתר החנות
                          </Button> */}
                        </div>
                      </div>
                    </li>
                  </>
                ))
              ) : merchantsPending ? (
                <CircularProgress />
              ) : (
                "אין תוצאות"
              )}
            </ul>
          </div>
        </div>
      </div>

      <Dialog
        onClose={() => setDetailsPop(false)}
        open={detailsPop}
        sx={{ textAlign: "right" }}
      >
        <DialogTitle>
          <img
            alt=""
            src={details?.image}
            style={{ width: "30%", marginLeft: "10px" }}
          />
          {details?.network ? details?.network?.name : details?.title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setDetailsPop(false)}
          sx={{
            position: "absolute",
            left: 8,
            top: 8,
            zIndex: "100000",
          }}
        >
          <CloseIcon />
        </IconButton>
        <div style={{ padding: "0 24px 16px 24px" }}>
          <h6 dangerouslySetInnerHTML={{ __html: details?.content || "" }}></h6>
          {details?.description?.split(/(?= -)/).map((line) => (
            <div style={{ fontSize: "smaller" }}>{line}</div>
          ))}
        </div>
      </Dialog>

      <Dialog
        onClose={() => setBranchesPop(false)}
        open={branchesPop}
        className="branchesModal"
        sx={{
          textAlign: "right",
          overflowY: "hidden !important",
        }}
      >
        <DialogTitle>
          {branches[0]?.network?.name} {"סניפים"}
          <IconButton
            aria-label="close"
            onClick={() => setBranchesPop(false)}
            sx={{
              position: "absolute",
              left: 8,
              top: 8,
              zIndex: "100000",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <ul className="store-Module" style={{ padding: "2%" }}>
          {branches?.map((store) => (
            <li className="store-Module__list isLink">
              <div className="store-box" style={{ height: "150px !important" }}>
                {store.distance && (
                  <div className="category-boxSubTitle">
                    <Location fill={"var(--primary)"} />
                    {store.distance}
                    <br />
                    ק"מ
                  </div>
                )}
                {/* {store.distance && (
                  <div className="category-boxSubTitle">
                    {store.distance}
                    <Location fill={"var(--primary)"} />
                  </div>
                )} */}
                {/* <div className="category-boxSubTitle">
                  12 {t("km")}
                  <Location fill={"var(--primary)"} />
                </div> */}
                <div className="product-box__discount">
                  <span className="product-box__off">עד 12% הנחה</span>
                </div>
                {/* <div className="store-box__likeBtn">
                        <FavoriteBorderOutlinedIcon
                          onClick={() => handleFavChange()}
                        />
                      </div> */}
                {(store?.kosher == "כשר" || store?.kosher == "כשר למהדרין") && (
                  <div className="store-box__kosher">
                    {/* {koshaerArr[getRandomInt(3)]} */}
                    {store?.kosher || ""}
                  </div>
                )}
                <img
                  alt=""
                  className="store-box__img"
                  src={store.bannerImage || ""}
                  onClick={() => {
                    navigate(`/vendor/${store.id}`, {
                      state: { id: store.id },
                    });
                  }}
                />
                <div
                  className="store-box__logo"
                  style={{ backgroundImage: `url(${store.image})` }}
                ></div>
                <div className="store-box__info">
                  <h6 className="store-box__brand">{store.title}</h6>

                  <div className="address">
                    {store?.address ? store.address : ""}
                  </div>
                  <div className="phone">{store.telephone || ""}</div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </Dialog>
    </div>
  );
}
