import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Search from "../dashboard/Search";
import SalesList from "./SalesList";

import "./Sales.css";

export default function Sales() {
  const { t } = useTranslation();
  const [filterProducts, setFilterProducts] = useState([]);

  return (
    <div>
      {/* <Header /> */}
      <div className="pageTemplate sales">
        <div className="container">
          <div className="PageBgHeading">
            <h5 className="PageBgHeading-title">{t("promotions")}</h5>
          </div>
          <Search
            filterProducts={filterProducts}
            setFilterProducts={setFilterProducts}
          />
          <SalesList isNotScrolling={true} />
        </div>
      </div>
    </div>
  );
}
