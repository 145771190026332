import { LeadActionTypes } from "./lead.types";

const INITIAL_STATE = {
  isAddLeadPending: false,
  addedLead: null,
  addLeadError: null,
};

const LeadReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case LeadActionTypes.ADD.LEAD_PENDING:
      return {
        ...state,
        isAddLeadPending: true,
        addedLead: false,
      };

    case LeadActionTypes.ADD.LEAD_SUCCESS:
      return {
        ...state,
        isAddLeadPending: false,
        addedLead: true,
        addLeadError: null,
      };

    case LeadActionTypes.ADD.LEAD_ERROR:
      return {
        ...state,
        addedLead: null,
        isAddLeadPending: false,
        addLeadError: action.payload,
      };

    case LeadActionTypes.RESET.LEAD_ADD:
      return {
        ...state,
        addedLead: null,
      };

    default:
      return state;
  }
};

export default LeadReducer;
