const LEFT_TO_RIGHT_MARK = "‎\u200e";

export const cardNumberMask = (str = "") => {
  return (
    (str ? LEFT_TO_RIGHT_MARK : "") +
    str
      .replace(/\D/g, "")
      .split(/(\d{4})/)
      .filter((s) => s !== "")
      .join(" ")
  );
};

export const cardDateMask = (str = "") => {
  return (
    (str ? LEFT_TO_RIGHT_MARK : "") +
    str
      .replace(/\D/g, "")
      .split(/(\d{2})/)
      .filter((s) => s !== "")
      .join("/")
  );
};

export const phoneMask = (str = "") => {
  const positions = [1, 5, 9, 12, 16];
  const chars = str.replace(/\D/g, "").slice(0, 11).split("");
  Array(15)
    .fill(null)
    .forEach((c, idx) => {
      if (chars[idx] && positions.includes(idx)) {
        chars.splice(idx, 0, " ");
      }
    });

  return (chars.length ? LEFT_TO_RIGHT_MARK + "+" : "") + chars.join("");
};
