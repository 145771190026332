import "./terms.css";
function Terms() {
  return (
    <div>
      <div
        data-elementor-type="wp-page"
        data-elementor-id="4988"
        className="elementor elementor-4988"
        data-elementor-settings="[]"
      >
        <div className="elementor-section-wrap">
          {/* <section
            className="elementor-section elementor-top-section elementor-element elementor-element-b291602 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="b291602"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c9fbde7"
                data-id="c9fbde7"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-e0de0b3 elementor-widget elementor-widget-heading"
                    data-id="e0de0b3"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h1 className="elementor-heading-title elementor-size-default">
תקנון
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-eb64288 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="eb64288"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-965badf"
                data-id="965badf"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-9e474fc elementor-widget elementor-widget-heading"
                    data-id="9e474fc"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        <strong>תקנון לאתר רעיונית</strong>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-da10c3a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="da10c3a"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6cf4b85"
                data-id="6cf4b85"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-3801231 elementor-widget elementor-widget-text-editor"
                    data-id="3801231"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          התנאים המפורטים להלן מהווים תנאי שימוש מחייבים לשימוש
                          באתר הבית של "רעיונית". הגלישה באתר והגישה אל המוצרים
                          והשירותים הינה בכפוף לתנאים המפורטים בתקנון זה.
                          באמצעות כניסה לאתר מביע כל משתמש את הסכמתו לאמור
                          בתקנון זה. אי הסכמה של משתמש לאיזה מהתנאים באתר מחייבת
                          אותו להימנע מעשיית כל שימוש שהוא באתר.&nbsp;
                        </span>
                      </p>
                    </div>
                    <div>
                      <h5>
                        <strong>1. כללי</strong>
                      </h5>
                      <p>
                        1.1. אתר הבית של "רעיונית" מופעל על ידי חברת "רעיונית"
                        (להלן: "מנהל האתר") ונמצא במתחם (domain) שזיהויו ברשת
                        האינטרנט (www) הינו: www.raayonit.co.il (להלן: "האתר").
                        האתר נועד לאפשר ללקוחות לקבל מידע, לסקור, לבחון ולרכוש
                        מוצרים ושירותים המוצעים על ידי מנהל האתר בתחום הבילוי,
                        האירוח, הנופש והכיף (להלן: "המוצרים והשירותים").
                      </p>
                      <p>1.2. האתר מופעל על ידי מנהל האתר.</p>
                      <p>
                        1.3. האתר מוגבל לקבלת מידע בלבד, ואין לעשות בו כל שימוש
                        מסחרי ללא הרשאה מפורשת בכתב מאת מנהל האתר. על המשתמשים
                        באתר חל איסור מוחלט לבצע בו שינויים או להעתיק, להפיץ,
                        לשדר, להציג, לבצע, לשכפל, לפרסם, להנפיק רישיון, ליצור
                        עבודות נגזרות או למכור פריט מפריטי המידע, התכנים,
                        התוכנה, המוצרים או השירותים שמקורם באתר.
                      </p>
                      <p>
                        1.4. מנהל האתר רשאי לסגור את האתר לעדכונים ושדרוגים,
                        ולשנות, מעת לעת, את מבנהו, מראהו וזמינותם של המוצרים,
                        השירותים והתכנים הניתנים בו, וזאת ללא צורך להודיע ללקוח
                        על כך מראש. 1.5. מנהל האתר אינו מתחייב כי השימוש באתר לא
                        יופרע ו/או יינתן כסדרו ללא הפסקות והפרעות ו/או כי יהא
                        חסין מפני גישה לא חוקית, נזקים, קלקולים, תקלות, כשלים
                        בחומרה, בתוכנה או בקווי התקשורת אצל מנהל האתר, או ייפגע
                        מכל סיבה אחרת.
                      </p>
                      <h5>
                        <strong>2. זכאות להשתמש באתר</strong>
                      </h5>
                      <p>
                        2.1. זכאים להשתמש באתר לקוחות המעוניינים לרכוש מוצרים
                        וכן עובדי הארגונים שמחזיקים במוצרי "רעיונית" (להלן:
                        הלקוחות). יחד עם זאת, לביצוע רכישות באתר יש להחזיק
                        בכרטיס אשראי (כהגדרתו בחוק כרטיס חיוב, התשמ"ו - 1986)
                        תקף של אחת מחברות האשראי הפועלות בישראל אשר הונפק
                        בישראל. על אף האמור, ולמען הסר הספק יובהר, כי כל
                        המשתמשים באתר (אפילו אם אינם לקוחות) יהיו כפופים להוראות
                        תקנון זה.
                      </p>
                      <p>
                        2.2. תנאי מקדמי לשימוש באתר לביצוע עסקאות רכישה הינו
                        כדלקמן:
                      </p>
                      <p className="sub">
                        2.2.1. אישור חברת כרטיסי האשראי, אשר דרכה התחייב הלקוח
                        לביצוע התשלום עבור עסקת הרכישה של המוצרים ו/או השירותים;
                      </p>
                      <p>
                        2.3. מבלי לגרוע מהאמור לעיל, מנהל האתר יהא רשאי למנוע
                        מלקוח כלשהו להשתתף באיזה מן המכירות ולהגיש הצעת רכישה
                        בכל אחד מן המקרים הבאים:
                      </p>
                      <p className="sub">
                        2.3.1. אם לדעת מנהל האתר הלקוח מסר במתכוון פרטים שגויים;
                      </p>
                      <p className="sub">
                        2.3.2. אם לדעת מנהל האתר הלקוח ביצע מעשה או מחדל הפוגעים
                        או העלולים לפגוע במפעיל או בחברה או בצדדים שלישיים
                        כלשהם, לרבות ספקי התוכן (כהגדרתם להלן);
                      </p>
                      <p className="sub">
                        2.3.3. אם לדעת מנהל האתר הלקוח עשה שימוש בשירותי האתר
                        לביצוע מעשה בלתי חוקי או כדי לאפשר, להקל, לסייע או לעודד
                        ביצועו של מעשה כזה;
                      </p>
                      <p className="sub">
                        2.3.4. אם הלקוח הפר איזו מהוראות תקנון זה;
                      </p>
                      <p className="sub">
                        2.3.5. אם כרטיס האשראי שברשות הלקוח נחסם או הוגבל לשימוש
                        בדרך כלשהי.
                      </p>
                      <h5>
                        <strong>3. המוצרים והשירותים המוצעים באתר</strong>
                      </h5>
                      <p>
                        3.1. המוצרים והשירותים המוצעים באתר הינם לפי שיקול דעתם
                        הבלעדי של מנהל האתר והחברה.
                      </p>
                      <p>
                        3.2. מנהל האתר ידאג לכך שבאתר יפורטו כל מאפייני ופרטי
                        המוצרים והשירותים הנדרשים באופן סביר לצורך ביצוע רכישה
                        מרחוק, בהתאם למקובל בתחום הסחר האלקטרוני בישראל (כדוגמת
                        תיאורם, עלותם, אופן התשלום, דרכי ביטול, דמי ביטול, הגבלת
                        כמויות וכדומה).
                      </p>
                      <p>
                        3.3. מנהל האתר יהא רשאי לשנות את מאפייני ופרטי המוצרים
                        והשירותים ואת תנאי הרכישה של המוצרים והשירותים, מעת לעת.
                      </p>
                      <p>
                        3.4. מנהל האתר אינו אחראי לאספקת המוצרים או ביצוע
                        השירותים בפועל וכן אינו אחראי לטיב המוצר ו/או השירות אשר
                        יינתנו תמורת השובר. אחריות כאמור הינה אחריותו הבלעדית של
                        ספק התוכן. מבלי לגרוע מהאמור, יובהר כי תמונות המוצרים או
                        השירותים שבאתר נועדו להמחשה בלבד, וייתכנו הבדלים בין
                        התמונות לבין המוצרים או השירותים הנמכרים.
                      </p>
                      <p>
                        3.5. מנהל האתר אינו אחראי ולא יהא מחויב למחירי המוצרים
                        והשירותים המוצגים באתרי אינטרנט שונים שאינם מופעלים על
                        ידי מנהל האתר, לרבות אתרים העוסקים בהשוואת מחירים ו/או
                        אתרים שיש להם קישור (לינק) באתר. למען הסר ספק יובהר כי
                        המחיר הקובע לצורך מכירת המוצרים יהיה המחיר המוצג במחירון
                        "רעיונית".
                      </p>
                      <p>
                        3.6. פרטים נוספים לגבי המוצרים והשירותים המוצעים באתר,
                        ניתן לקבל בדרך כלל באתרי הבית של ספקי תוכן, או על ידי
                        התקשרות טלפונית ישירות עם ספקי התוכן.
                      </p>
                      <p>
                        3.7. מנהל האתר יהא רשאי לשנות או להפסיק את הצגת המוצרים
                        והשירותים, או חלק מהם, על ידי הסרת המוצר / השירות מרשימת
                        המוצרים המוצגים באתר.
                      </p>
                      <p>
                        3.8. זמן אספקת השוברים על ידי מנהל האתר יהיה בתוך
                        ארבע-עשר (14) ימים מיום אישור ביצוע התשלום.
                      </p>
                      <p>
                        3.9. ביטול הצעה בעקבות טעות- מנהל האתר ו/או החברה לא
                        יישאו באחריות כלשהי, במישרין או בעקיפין, במקרה של טעות
                        בתום לב בהקלדת מחירי ההצעה ו/או פרטי ההצעה ו/או תיאור
                        ההצעה ו/או תיאור ספק התוכן. כן לא יישאו החברה ו/או מנהל
                        האתר במישרין או בעקיפין, באחריות במקרה בו פרטי הרכישה או
                        פרטי הרוכש לא נקלטו במערכת, כולם או חלקם ו/או באחריות
                        לכל בעיה טכנית אחרת המונעת ביצוע פעולות באתר.
                      </p>
                      <p>
                        3.10. האתר מכיל בתוכו קישורים אלקטרוניים (hyperlink) אל
                        מקורות מידע ו/או אתרים אחרים באינטרנט ומהם. קישורים אלה
                        אינם יכולים להתפרש כהבעת תמיכה או מתן חסות על ידי החברה
                        לאותם מקורות מידע ו/או אתרים אחרים, ו/או המלצה לעשות בהם
                        שימוש כלשהו.
                      </p>
                      <p>
                        3.11. כל הסתמכות על הצהרות, הבעות עמדה, עצות או כל מידע
                        אחר המוצג באתר, ובכלל זה התקשרות בעסקאות במסגרת אתר זה,
                        נעשית לפי שיקול דעתו של מבצע הפעולה ובאחריותו הבלעדית.
                      </p>
                      <h5>
                        <strong>4. מימוש ותוקף</strong>
                      </h5>
                      <p>
                        4.1. מימוש המוצרים הינו בכפוף להנחיות המופיעות על גבי
                        המוצר ובאתר האינטרנט של "רעיונית".
                      </p>
                      <p>
                        4.2. על גבי כל מוצר מצוין תוקף באופן ברור ובולט. תוקף זה
                        הינו סופי ומוחלט.
                      </p>
                      <p className="sub">
                        4.2.1. תוקף המוצרים הינו בהתאם לנהלי חברת "רעיונית" וע"פ
                        שיקול דעתה הבלעדי, בכפוף לחוק. החל מיום ג‘ בתמוז התשע"ד
                        (1 ביולי 2014) ואילך, בהתאם לסעיף 14ח בחוק הגנת הצרכן,
                        התשמ"א- 1981, מוצרים המקנים זיכוי כספי הינם בעלי תוקף
                        לחמש (5) שנים מיום הנפקתם. יתר המוצרים הינם בעלי תוקף
                        לשנה אחת (1) לפחות מיום הנפקתם, בהתאם לשיקול דעתה הבלעדי
                        של חברת רעיונית.
                      </p>
                      <p className="sub">
                        4.2.2. במקרים חריגים ובכפוף לאישור רעיונית, ניתן להאריך
                        תוקף מוצר פעם אחת בלבד לשנה נוספת ובתנאי ולא חלפו שלוש
                        (3) שנים מיום הנפקתו, וזאת כנגד תשלום בשיעור של 18% מערך
                        המוצר במועד הארכת התוקף אך לא פחות מ- 24 ש‘‘ח.
                      </p>
                      <p className="sub">
                        4.2.3. למען הסר ספק, לא יוארכו מוצרים המקנים זיכוי כספי
                        שהונפקו ונמכרו עם תוקף לחמש (5) שנים.
                      </p>
                      <p className="sub">
                        4.2.4. למען הסר ספק, ערך המוצרים הן במועד הרכישה והן
                        במועד הארכת התוקף, ייקבעו ע"י "רעיונית" בלבד, בהתאם
                        לנתונים המצויים בידיה.
                      </p>
                      <p className="sub">
                        4.2.5. חברת "רעיונית" שומרת לעצמה את הזכות שלא להאריך
                        תוקף מוצרים לפי שיקול דעתה הבלעדי. כמו כן, החברה רשאית
                        לשנות את התקנון הנ"ל בכל עת בהתאם לשיקול דעתה הבלעדי.
                      </p>
                      <p>4.3. לא יינתן מימוש לקבוצות ואירועים.</p>
                      <p>
                        {" "}
                        4.4. לא יינתן החזר כספי או פיצוי בגין אי-מימוש, אובדן או
                        גניבה.
                      </p>
                      <h5>
                        <strong>5. ביצוע רכישות באתר</strong>
                      </h5>
                      <p>
                        5.1. הלקוחות יהיו רשאים לרכוש מספר מוגבל או בלתי מוגבל
                        בהתאם למפורט באתר הרווחה של החברה ו/או הארגון לגבי כל
                        מוצר.
                      </p>
                      <p>
                        5.2. בעת ביצוע רכישה באתר יידרשו הלקוחות למסור פרטים
                        מזהים אודות עצמם (לרבות: מספר זהות, שם מלא, מס‘ טלפון,
                        כתובת דוא"ל, פרטי כרטיס אשראי וכיו"ב) (להלן: "הפרטים
                        האישיים") , ובמקרים מסוימים אף להזדהות באמצעות קוד אישי.
                      </p>
                      <p>
                        5.3. בעת ביצוע רכישה באתר, הפרטים האישיים של הלקוח
                        יועברו למערכת עיבוד הנתונים של מנהל האתר (להלן:
                        "המערכת") באמצעות ערוץ מוצפן תקני (SSL). רישומי המערכת
                        יהוו ראיה לכאורה לאמור בהם.
                      </p>
                      <p>
                        5.4. לאחר שיגור בקשת רכישה באתר על ידי הלקוח, יקבל הלקוח
                        אישור הזמנה, ובו מספר סידורי של הצעת הרכישה שמשמעו כי
                        פרטי בקשת הרכישה שהוגשה נקלטו במערכת וההצעה הפכה לעסקת
                        רכש מלאה ומחייבת, אלה אם יצוין במפורש אחרת ובכפוף לתנאי
                        ביטול רכישות באתר בסעיף 6 להלן.
                      </p>
                      <p>
                        5.5. מנהל האתר שומר לעצמו את הזכות שלא לקבל או לבטל כל
                        בקשה לרכישת מוצרים ו/או שירותים בכל אחד מן המקרים הבאים:
                      </p>
                      <p className="sub">
                        5.5.1. בהתאם לשיקול דעתו הסביר של מנהל האתר מתעורר חשד
                        כי התשלום עבור הרכישה לא יבוצע כלל או שלא יבוצע במלואו
                        ובמועדו;
                      </p>
                      <p className="sub">
                        5.5.2. נפלה טעות במסירת פרטי הזיהוי ו/או מידע הנדרש
                        לביצוע רכישה באתר. במקרה שמוצר שנשלח ללקוח יוחזר למפעיל
                        עקב הזנת פרטי זיהוי או מידע שגויים כאמור, הלקוח יחויב
                        בתשלום עבור דמי המשלוח והטיפול במוצר.
                      </p>
                      <h5>
                        <strong>6. ביטול רכישות באתר</strong>
                      </h5>
                      <p>
                        6.1. בכפוף לאמור בסעיף 6.2 להלן, כל לקוח רשאי לבטל בקשת
                        ביצוע רכישה של מוצרים בהתאם למפורט להלן או על פי התנאים
                        הקבועים לעניין זה בחוק הגנת הצרכן, תשמ"א - 1981, במועד
                        הביטול.
                      </p>
                      <p className="sub">
                        6.1.1. הלקוח יהא רשאי לבטל את בקשת ביצוע הרכישה בדרך של
                        בקשת ביטול באמצעות פנייה טלפונית למוקד השירות של האתר
                        וכנגד החזרת המוצר המקורי ללא פגם או בלאי, לידי החברה,
                        וזאת בתוך ארבע-עשר (14) ימים מיום ביצוע הרכישה באתר.
                        ביטול זה יחויב בדמי ביטול והוצאות משלוח ואריזה בשיעור של
                        5% מערך המוצר במועד הביטול או 100 ש‘‘ח, הנמוך מהשניים.
                      </p>
                      <p>
                        6.2. בכפוף להוראות כל דין, על אף האמור בסעיף 6.1 לעיל
                        זכות ביטול, לא תעמוד ללקוחות במקרים הבאים:
                      </p>
                      <p className="sub">
                        6.2.1. לגבי מוצרים שיוצרו במיוחד עבור הלקוח בעקבות בקשת
                        הרכישה.
                      </p>
                      <p>
                        6.3. לאחר ביטול בקשת ביצוע הרכישה בהתאם לסעיף 6.1 לעיל,
                        וכנגד החזרת המוצר (בהתאם לסעיף 6.1.2 לעיל), יזוכה הלקוח
                        בתוך 45 ימים ממועד ההחזרה, בסכום ששילם עבורם ובניכוי דמי
                        ביטול והוצאות משלוח ואריזה. דמי הביטול בגין ביטול רכישת
                        המוצר ו/או השירות כאמור (ככל שיהיו), יהיו בהתאם למפורט
                        בסעיף 6.1.1.
                      </p>
                      <h5>
                        <strong>7. תשלום ואספקה</strong>
                      </h5>
                      <p>
                        7.1. ביצוע הזמנה באתר החברה באמצעות זיהוי הלקוח לפי שם
                        וקוד זיהוי, תיחשב כביצוע עסקה בחתימת ידו.
                      </p>
                      <p>
                        7.2. חיוב הלקוח בגין המוצרים שרכש באתר יתבצע בהתאם לתנאי
                        הרכישה והתשלום המפורטים לצד כל מוצר המוצע באתר, באמצעות
                        חיוב בכרטיס אשראי.
                      </p>
                      <p>
                        7.3. המוצרים שבאתר יסופקו ללקוחות בהתאם לתנאים ולדמי
                        המשלוח המפורטים לצדם באתר. יובהר, כי דמי המשלוח בהם
                        יחויב הלקוח, כוללים בין היתר את עלות אריזת המוצר,
                        האחריות וביטוח המוצר בעת המשלוח וכן הוצאות המשלוח בפועל.
                      </p>
                      <p>
                        7.4. ככלל, במידה והאספקה ללקוחות תהא באמצעות חברת
                        משלוחים ו/או באמצעות דואר ישראל, יישלחו המוצרים לכתובת
                        שהוזנה על ידם באתר בעת ביצוע הרכישה. מוצרים שיסופקו
                        באמצעות חברת משלוחים או דואר ישראל יימסרו ללקוחות בהתאם
                        לזמני האספקה המקובלים בדואר ישראל או אצל חברת המשלוחים
                        ובכפוף לטיב ולתנאי השירות המקובלים אצלם.
                      </p>
                      <p>
                        7.5. לקוחות שיבחרו לאסוף את המוצרים שרכשו באתר ממשרדי
                        מנהל האתר (ככל שהדבר אפשרי), יתאמו זאת ישירות מול מנהל
                        האתר, באמצעות מספר הטלפון המופיע בעמודי המכירה של
                        המוצרים באתר.
                      </p>
                      <p>
                        7.6. מנהל האתר לא יישא בכל אחריות לעיכובים באספקת
                        המוצרים כתוצאה מאירועים שאינם בשליטתו, כדוגמת: שביתות או
                        השבתות, תקלות במערכת המחשוב או במערכות הטלפונים ו/או
                        במערכות תקשורת, תקלות בשירות הדואר האלקטרוני, פעולות
                        איבה ו/או כוח עליון (מלחמה / רעידת אדמה / מזג אוויר
                        וכיו"ב).
                      </p>
                      <h5>
                        <strong>8. אבטחת מידע והגנת הפרטיות</strong>
                      </h5>
                      <p>
                        8.1. מנהל האתר ישתמש באמצעי הגנה והצפנה מסוג SSL ,
                        הנהוגים בתחום הסחר האלקטרוני בישראל ובארה"ב לפעולות של
                        מסירת מידע מקוון, ובהתאם לנדרש על פי כל דין. אמצעי ההגנה
                        האמור נועדו לאבטחת זרימת מידע מוצפנת בין מחשב הלקוח בזמן
                        ביצוע הרכישה לבין שרתי המערכת.
                      </p>
                      <p>
                        8.2. ידוע ללקוחות כי מנהל האתר מקדיש משאבים ונוקט אמצעים
                        מחמירים למניעת חדירה לאתר ולסיכול פגיעה אפשרית בפרטיות
                        הלקוחות, אולם הוא אינו יכול למנוע שיבושים באתר באופן
                        מוחלט. מנהל האתר לא יישא באחריות, ישירה ו/או עקיפה, לכל
                        סוג נזק אשר ייגרם ללקוח, במישרין או בעקיפין, ללקוח ו/או
                        לצד ג‘, כתוצאה מפריצה ו/או חדירה למידע השמור אצל מנהל
                        האתר.
                      </p>
                      <p>
                        8.3. מנהל האתר ינהג בפרטים האישיים ובמידע שמסרו הלקוחות
                        במסגרת השימוש באתר בהתאם ובכפוף להוראות כל דין. מבלי
                        לגרוע מן האמור, מנהל האתר יהא רשאי להשתמש בפרטים האישיים
                        ובמידע שמסרו הלקוחות כאמור, וכן לאסוף מידע אודות דפוסי
                        השימוש של הלקוחות באתר על ידי רישום השימוש שיעשו באתר
                        (לרבות באמצעות cookies ) בכפוף לכל דין, במקרים הבאים:
                      </p>
                      <p className="sub">
                        8.3.1. לצורך שיפור המידע והתכנים שמנהל האתר יציע במסגרת
                        האתר ללקוחות באופן אישי ו/או לכלל ו/או לחלק מן הלקוחות
                        באתר;
                      </p>
                      <p className="sub">
                        8.3.2. לצורך ניתוח מידע סטטיסטי על ידי מנהל האתר ו/או
                        לצורך יצירת קשר עם הלקוחות במקרה הצורך.
                      </p>
                      <p>
                        8.4. בכפוף לכל דין, מנהל האתר מתחייב שלא להעביר לצדדים
                        שלישיים את המידע האישי אודות הלקוח, ללא הסכמה מפורשת
                        מראש של הלקוח, למעט במקרים הבאים:
                      </p>
                      <p className="sub">
                        8.4.1. הדבר נדרש מכוח חוק ו/או צו בית משפט ו/או רשות
                        מוסמכת אחרת.
                      </p>
                      <p className="sub">
                        8.4.2. לצורך הגנה על זכויותיו הכשרות של מנהל האתר ו/או
                        מי מטעמו, לרבות צדדים שלישיים, לרבות במקרה של הפרת תנאי
                        מתנאי השימוש באתר על ידי הלקוח ו/או הפרת הסכם ההתקשרות
                        עם מנהל האתר בכל דרך.
                      </p>
                      <p className="sub">
                        8.4.3. לצורך אימות פרטים ואבטחת המידע.
                      </p>
                      <p></p>
                      <p>
                        8.5 על אף האמור בסעיף 8.4. לעיל, מנהל האתר יהיה רשאי
                        להסתייע בגורמי חוץ לצורך ניהול מאגרי המידע של מנהל האתר
                        ו/או לצורך אבטחת המידע.
                      </p>
                      <p>
                        8.6. היה ונדרש מנהל האתר להעביר פרטי לקוחות לצדדים
                        שלישיים כאמור בסעיף 8.4. לעיל, מנהל האתר יעשה כל
                        שביכולתו, בנסיבות העניין, כדי להגן על פרטיותו של הלקוח.
                      </p>
                      <h5>
                        <strong>9. אחריות</strong>
                      </h5>

                      <p>
                        9.1. אחריות מנהל האתר תהא מוגבלת אך ורק לכל נזק ישיר
                        שיגרם למי מהלקוחות בשל מעשה או מחדל רשלני של מנהל האתר
                        במסגרת פעולותיו במסגרת הפעלת ותפעול האתר. יובהר כי בכל
                        מקרה אחריות מנהל האתר כאמור תהא מוגבלת עד לסכום הרכישה
                        שבוצעה ואושרה על ידי המערכת. בכל מקרה, מנהל האתר לא יישא
                        באחריות לנזק, הוצאה או הפסד כאמור שייגרמו בשל מעשה או
                        מחדל של הלקוח עצמו או מי מטעמו או בשל מעשה או מחדל של
                        ספקי התוכן בקשר עם המוצרים והשירותים שיוצעו על ידם
                        ללקוחות.
                      </p>
                      <h5>
                        <strong>10. קניין רוחני</strong>
                      </h5>
                      <p>
                        10.1. כל הזכויות הקיימות והעתידיות בתכנים ובעיצוב של
                        האתר ובדפי האינטרנט (ובכלל זה הזכות לרישום שם המתחם לשמו
                        לעיצובו ולסימנים המסחריים והאחרים הכלולים בו) וכן המידע
                        במאגר המידע המצוי במערכת והגישה אליו, שייכות לחברה ולה
                        בלבד, ותיוותרנה, בכל עת וללא כל תנאי או זכות מכל מין
                        וסוג שהוא, בבעלותה המלאה והבלעדית של החברה. במתן האפשרות
                        ללקוחות וגולשים אחרים לעשות שימוש באתר אין משום מתן
                        רישיון או זכות כלשהי בתכנים ובעיצוב האתר, בדפי האינטרנט,
                        בשם המתחם, ברישומו, עיצובו או בסימנים המסחריים והאחרים
                        הכלולים באתר.
                      </p>
                      <p>
                        10.2. אין להעתיק ו/או לפרסם כל חלק מהאתר ו/או דפי
                        האינטרנט ללא הסכמה מפורשת מראש ובכתב של החברה או מנהל
                        האתר על פי העניין.
                      </p>
                      <p>
                        10.3. אין באמור לעיל כדי להטיל על החברה אחריות או חבות
                        כלשהי כלפי הלקוחות בקשר עם כל דבר או עניין הנוגע בשימוש
                        באתר.
                      </p>
                      <h5>
                        <strong>11. שונות</strong>
                      </h5>
                      <p>
                        11.1. הלקוחות מאשרים בזאת כי קראו והבינו את תנאי השימוש
                        באתר המפורטים בתקנון זה, ומצהירים על הסכמתם להשתמש באתר
                        בכפוף להם, שאם לא כן אסור להם לבצע כל פעולה באתר.
                      </p>
                      <p>
                        11.2. מנהל האתר יפעיל את האתר והמערכת ו/או יספק ללקוחות
                        את המוצרים והשירותים ו/או יעשה שימוש בפרטים האישיים
                        ובמידע שמסרו הלקוחות במסגרת השימוש באתר, בהתאם ובכפוף
                        להוראות כל דין (לרבות, ומבלי לגרוע, ההוראות הנוגעות
                        להגנת הפרטיות, אבטחת מאגרי מידע, איסור לשון הרע, הגנת
                        הצרכן, אבטחת מידע, זכויות יוצרים וזכויות קניין רוחני).
                      </p>
                      <p>
                        11.3. הדין החל על תקנון זה הנו דין מדינת ישראל, וסמכות
                        השיפוט הייחודית והבלעדית לעניין תקנון זה נתונה אך ורק
                        לבתי המשפט המוסמכים לכך בעיר פתח תקווה (והצדדים שוללים
                        במפורש את סמכותם המקומית של בתי משפט אחרים ברחבי הארץ).
                      </p>
                      <p>
                        11.4. החברה ו/או מנהל האתר יהיו רשאים לתקן ו/או לשנות
                        הוראות תקנון זה, כולם או חלקם. תיקונים ו/או שינויים
                        כאמור יעשו בכפוף להסכמת החברה והם ייכנסו לתוקפם מיד לאחר
                        פרסומם באתר.
                      </p>
                      <p>
                        11.5. למען הסר ספק, יובהר כי הוראות תקנון זה גוברות על
                        הוראות כל תקנון ספציפי אחר שקיים (ככל שקיים) לגבי כל
                        מוצר ו/או שירות המוצעים באתר.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Terms;
