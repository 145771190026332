import { OrderActionTypes } from "./order.types";
import { axiosInstance } from "../../axios.config";

export const getOrder = (orderId) => (dispatch) => {
  dispatch({ type: OrderActionTypes.GET.ORDER_PENDING });

  axiosInstance
    .get(`/order/${orderId}`)
    .then((res) =>
      dispatch({
        type: OrderActionTypes.GET.ORDER_SUCCESS,
        payload: res.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: OrderActionTypes.GET.ORDER_ERROR,
        payload: error.response.data,
      })
    );
};
export const getBalance = (orderId) => (dispatch) => {
  dispatch({ type: OrderActionTypes.GET.BALANCE_PENDING });

  axiosInstance

    .get(`/multipass/card/${orderId}/balance`)
    .then((res) =>
      dispatch({
        type: OrderActionTypes.GET.BALANCE_SUCCESS,
        payload: res.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: OrderActionTypes.GET.BALANCE_ERROR,
        payload: error.response.data,
      })
    );
};

export const getOrders = () => (dispatch) => {
  dispatch({ type: OrderActionTypes.GET.ORDERS_PENDING });

  axiosInstance
    .get("/order")
    .then((res) =>
      dispatch({
        type: OrderActionTypes.GET.ORDERS_SUCCESS,
        payload: res.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: OrderActionTypes.GET.ORDERS_ERROR,
        payload: error.response.data,
      })
    );
};
export const getUsage = () => (dispatch) => {
  dispatch({ type: OrderActionTypes.GET.USAGE_PENDING });

  axiosInstance
    .get(`/multipass/usage-history`)
    .then((res) =>
      dispatch({
        type: OrderActionTypes.GET.USAGE_SUCCESS,
        payload: res.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: OrderActionTypes.GET.USAGE_ERROR,
        payload: error.response.data,
      })
    );
};

export const getUnused = () => (dispatch) => {
  dispatch({ type: OrderActionTypes.GET.UNUSED_PENDING });

  axiosInstance
    .get(`/multipass/unused-cards`)
    .then((res) =>
      dispatch({
        type: OrderActionTypes.GET.UNUSED_SUCCESS,
        payload: res.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: OrderActionTypes.GET.UNUSED_ERROR,
        payload: error.response.data,
      })
    );
};

export const clearOrder = () => (dispatch) => {
  dispatch({ type: OrderActionTypes.CLEAR.ORDER });
};
