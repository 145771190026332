import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

const theme = createTheme({
  direction: "rtl",
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export default function BasicPagination({
  productsPerPage,
  totalProducts,
  paginate,
}) {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil((totalProducts - 1) / productsPerPage); i++) {
    pageNumbers.push(i);
  }
  // let size = ''
  // if (isMobile) {
  //   size = 'small'
  // }

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <Stack spacing={2}>
          <Pagination
            onChange={paginate}
            count={pageNumbers.length}
            color="primary"
            size="small"
          />
        </Stack>
      </ThemeProvider>
    </CacheProvider>
  );
}
