import "./think.css";
function Think() {
  return (
    <div>
      <div
        data-elementor-type="wp-page"
        data-elementor-id="4988"
        className="elementor elementor-4988"
        data-elementor-settings="[]"
      >
        <div className="elementor-section-wrap">
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-eb64288 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="eb64288"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-965badf"
                data-id="965badf"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-9e474fc elementor-widget elementor-widget-heading"
                    data-id="9e474fc"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        <strong>חומר למחשבה</strong>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-da10c3a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="da10c3a"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6cf4b85"
                data-id="6cf4b85"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-3801231 elementor-widget elementor-widget-text-editor"
                    data-id="3801231"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          לקוחות נכבדים !!! נעים להכיר, שמי אילן כהן ואני סמנכ"ל
                          השיווק של חברת רעיונית מאז 1993. פעמים רבות נשאלתי מהו
                          היתרון שיש בהענקת שובר שי לעובד/לקוח/חבר על נתינת סכום
                          כסף למשל ?! והרי תשובתי:
                        </span>
                      </p>
                    </div>
                    <div>
                      <p>
                        1. הענקת בונוס כספי של 150 ש"ח לעובד לא תשפיע על העובד.
                        אם מדובר בעובד בשכר מינימום הרי 150 ש"ח יכנסו לחשבון
                        העו"ש שלו לכיסוי המינוס. אם מדובר בעובד שמרוויח משכורת
                        גבוהה, הרי שתוספת של 150 ש"ח למשכורתו לא "תדגדג" לו.
                        מאידך, תנו לעובד שובר הזמנה למסעדה למשל, העובד ישמח על
                        תשומת הלב שניתנה לו, יראה לאשתו את המתנה וייתן לה לבחור
                        את המסעדה. העובד בשכר הנמוך יוכל להרשות לעצמו להתפנק
                        במסעדה (דבר שאינו יכול להרשות לעצמו בד"כ) ואילו, העובד
                        בשכר הגבוה לבטח ישמח לצאת ולבלות במסעדה ע"ח העבודה.
                      </p>
                      <p>
                        2. זכות הבחירה! מנהל/ת נכבד/ה, באפשרותך לשלוח את
                        העובד/לקוח למלון/בקתה/אטרקציה/מסעדה שאתה בחרת עבורו או
                        לתת לו הזמנה שאותה הוא יבחר היכן ומתי לנצל במאות אתרים
                        לבחירתו. מניסיוני, עובד שיגיע לאתר שהוזמן עבורו ולא יאהב
                        את החדר/השרות/הנוף לא יהיה מרוצה מהמתנה אותה קיבל,
                        יתמרמר ויתלונן. מאידך, מה יותר הגיוני מאשר לתת
                        לעובד/ללקוח לבחור מתוך עשרות אתרים שעומדים לרשותו מקום
                        שהוא חפץ ובזמן שמתאים לו. עובד/לקוח שיקבל את זכות הבחירה
                        לעולם לא יתלונן!
                      </p>
                      <p>
                        3. כל הזמנה מגיעה במעטפת שי ולה מצורפת (בד"כ) חוברת
                        צבעונית ובה פירוט האתרים בהם אפשר לממש את ההזמנה. ההזמנה
                        תקפה ל-12 חודשים ויותר.
                      </p>
                      <p>
                        4. מחיר, אותו דווקא השארתי לסוף... מחיר שובר הזמנה
                        למלון/צימר/עיסוי/ארוחה ועוד זול משמעותית מהמחיר הישיר
                        באתרים!
                      </p>
                      <p>
                        5. ובנימה אישית... ברצוני לחלוק עמכם מניסיוני ולספר לכם
                        על כמה מהיתרונות של הענקת/קבלת שוברי שי: לא מזמן, ביום
                        הולדתי ה- 50 (מזל טוב!), אחרי שתמו תרועות הפסטיבלים
                        מהמסיבה, מצאתי עצמי עם עשרות מתנות שאת רובן הגדול אני
                        ממש לא צריך ואז... נפל לי האסימון! תנו לי שובר! למסעדה/
                        לסרט/ לספא/ לקניות... תנו לי להחליט בעצמי ולבחור מה אני
                        רוצה לעשות. ואל תחליטו לי בבקשה... :)
                      </p>
                      <br />
                      <p>בברכה ובהצלחה,</p>
                      <p>אילן כהן</p>
                      <p>ilan@raayonit.co.il</p>
                      <p>050-6504343</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Think;
