export const MerchantCategoryActionsTypes = {
  GET: {
    GET_MERCHANT_CATEGORY_PENDING: "GET_MERCHANT_CATEGORY_PENDING",
    GET_MERCHANT_CATEGORY_SUCCESS: "GET_MERCHANT_CATEGORY_SUCCESS",
    GET_MERCHANT_CATEGORY_ERROR: "GET_MERCHANT_CATEGORY_ERROR",

    GET_MERCHANT_CATEGORY_BY_ID_PENDING: "GET_MERCHANT_CATEGORY_BY_ID_PENDING",
    GET_MERCHANT_CATEGORY_BY_ID_SUCCESS: "GET_MERCHANT_CATEGORY_BY_ID_SUCCESS",
    GET_MERCHANT_CATEGORY_BY_ID_ERROR: "GET_MERCHANT_CATEGORY_BY_ID_ERROR",

    GET_MERCHANT_BY_CATEGORY_ID_PENDING: "GET_MERCHANT_BY_CATEGORY_ID_PENDING",
    GET_MERCHANT_BY_CATEGORY_ID_SUCCESS: "GET_MERCHANT_BY_CATEGORY_ID_SUCCESS",
    GET_MERCHANT_BY_CATEGORY_ID_ERROR: "GET_MERCHANT_BY_CATEGORY_ID_ERROR",
  },
  SET: {
    MERCHANT_CATEGORY_FILTER: "SET_MERCHANT_CATEGORY_FILTER",
  },
};
