import { TabbingTypes } from "./tabbing.types";

export const setGetTabbingValue = (data) => (dispatch) => {
  try {
    dispatch({ type: TabbingTypes.SET_TABBING_VALUE, data });
  } catch (error) {
    console.error(error);
  }
};

export const setB2BValue = (data) => (dispatch) => {
  dispatch({ type: TabbingTypes.SET_B2B_VALUE, data });
};
