import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";

import Paymentsuccess from "../../assets/images/paymentsuccess.png";
import "./bookingPayment.css";

function BookingPayment() {
  const { t } = useTranslation();

  return (
    <div>
      {/* <Header /> */}
      <div className="sucess_screen_main">
        <div className="sucess_screen_inner">
          <div
            className="sucess_screen_image"
            style={{ backgroundImage: `url(${Paymentsuccess})` }}
          ></div>
          <h3>{t("purchase")}</h3>
        </div>
        <div className="sucess_screen_button">
          <Button variant="contained">{t("backto")} </Button>
        </div>
      </div>
    </div>
  );
}

export default BookingPayment;
