import React, { useEffect, useState, useMemo } from "react";
import {
  Grid,
  TextField,
  CssBaseline,
  Button,
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  ToggleButtonGroup,
  ToggleButton,
  FormGroup,
  Divider,
  RadioGroup,
  Radio,
  FormControl,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/en-gb";
import { toast } from "react-toastify";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, updateCart } from "../../redux/actions-exporter";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import QuantitySelector from "../quantitySelector";

import SmsIcon from "@mui/icons-material/Sms";
import MailIcon from "@mui/icons-material/Mail";
import PrintIcon from "@mui/icons-material/Print";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { useTranslation } from "react-i18next";
import { phoneMask } from "../cart/inputMask";
import useValidateForm from "./useValidateForm";

import { register, setSidebarState } from "../../redux/actions-exporter";
import { current } from "@reduxjs/toolkit";
import { set } from "date-fns";
const hows = [
  {
    value: "sms",
    label: "sms",
    icon: <SmsIcon />,
  },
  {
    value: "email",
    label: "מייל",
    icon: <MailIcon />,
  },
  {
    value: "print",
    label: "הדפסה",
    icon: <PrintIcon />,
  },
];
const theme = createTheme({
  direction: "rtl",
});
const formatPhoneNumber = (phone) => phone.replace(/\D/g, "").slice(0, 11);
const formatEmail = (email) => email.replace(/ /g, "");

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const Send = ({
  chosen,
  setChosen,
  isCart,
  handleChangeQuantity,
  setCartPop,
}) => {
  const b2b = useSelector((state) => state.tabbing.b2b);
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [edited, setEdited] = useState(false);
  const [error, setError] = useState(null);

  const [isMe, setIsMe] = useState(true);
  const [isNow, setIsNow] = useState(true);
  const [formats, setFormats] = useState(() => ["sms"]);
  const [fors, setFors] = useState([]);
  const [text, setText] = useState("");
  const [date, setDate] = useState(isCart ? "" : dayjs());
  const { validateData } = useValidateForm(fors, setFors, formats);
  const { t } = useTranslation();

  // useEffect(() => {
  //   !isNow && setDate(dayjs())
  // }, [isNow])

  useEffect(() => {
    if (
      chosen.recipients?.length >= 1 &&
      chosen?.recipients[0]?.phone !== user.phone
    )
      setIsMe(false);
    const newFors = [];

    if (isCart) {
      const newFormats = [];
      if (chosen.recipients.some((a) => a.email.length))
        newFormats.push("email");
      if (chosen.recipients.some((a) => a.phone.length)) newFormats.push("sms");
      setFormats(newFormats);
      chosen.recipients.forEach((item) => {
        if (!item.isDate && item.date.length > 0) {
          setDate(dayjs(item.date));
          setIsNow(false);
        }
        if (!item.isText && item.text.length && !text.length)
          setText(item.text);
        // if (item.email.length && !newFormats.includes('email')) newFormats.push('email')
        // if (item.phone.length && !newFormats.includes('sms')) newFormats.push('sms')
        // setFormats(newFormats)

        newFors.push({
          name: item.name,
          email: item.email,
          phone: item.phone,
          isDate: item.isDate,
          // date: item.isDate ? dayjs(item.date) : '',
          date: item.date ? dayjs(item.date) : "",
          isText: item.isText,
          text: item.isText ? item.text : "",
          reason: item.reason,
          errors: {},
        });
      });
    } else {
      for (let i = 1; i <= chosen.quantity; i++) {
        newFors.push({
          name: "",
          email: "",
          phone: "",
          isDate: chosen.quantity > 1 ? false : true,
          date: dayjs(),
          isText: false,
          text: "",
          reason: "",
          errors: {},
        });
      }
    }

    setFors(newFors);
  }, []);

  useEffect(() => {
    if (fors.length) {
      if (chosen.quantity > fors.length) {
        let newFors = [...fors];
        newFors.push({
          name: "",
          email: "",
          phone: "",
          isDate: isNow,
          // date: dayjs(),
          date: date ? dayjs(date) : dayjs(),
          isText: false,
          text: "",
          reason: "",
          errors: {},
        });
        setFors(newFors);
      } else if (chosen.quantity < fors.length) {
        let newFors = [...fors];
        newFors.pop();
        setFors(newFors);
      }
    }
  }, [chosen]);

  useEffect(() => {
    if (fors.length) {
      let newFors = [...fors];
      if (chosen.quantity > fors.length && !isMe) {
        for (let i = 0; i < chosen.quantity - 1; i++) {
          newFors.push({
            name: "",
            email: "",
            phone: "",
            isDate: chosen.quantity > 1 ? false : true,
            // date: dayjs(),
            date: "",
            isText: false,
            text: "",
            reason: "",
            errors: {},
          });
        }
      } else if (
        isMe
        // && fors.length > 1
      ) {
        newFors = [newFors[0]];
      }
      setFors(newFors);
    }
  }, [isMe]);
  const handleCart = () => {
    let newProduct = {
      ...chosen.product,
      quantity: chosen.quantity,
      price: b2b ? chosen.product.priceB2B : chosen.product.price,
    };
    if (newProduct.price === 0) newProduct.price = Number(chosen.price);
    let newFors = [];
    if (!isMe) {
      fors.forEach((item) => {
        newFors.push({
          name: item.name,
          email: item.email,
          phone: item.phone,
          isDate: item.isDate,
          date: isNow ? "" : item.date ? item.date.toISOString() : "",
          // date: isNow ? '' : item.isDate ? item.date.toISOString() : date.toISOString(),
          isText: item.isText,
          text: item.text,
          // text: item.isText ? item.text : text,
          reason: item.reason,
          errors: {},
        });
      });
    } else {
      newFors = [
        {
          name: [user.firstName, user.lastName].join(" "),
          email: formats.includes("email") ? user.email : "",
          phone: formats.includes("sms") ? user.phone : "",
          isDate: false,
          date: isNow ? "" : date.toISOString(),
          isText: false,
          text: "",
          reason: "",
          errors: {},
        },
      ];
    }
    validateData(newFors).then(() => {
      const isValidData = newFors
        .map((item) => JSON.stringify(item.errors) === "{}")
        .every((v) => v);
      if (!isValidData) {
        toast.error("לשליחה עצמית יש להשלים פרטי משתמש");
      } else {
        if (isCart) {
          dispatch(updateCart(chosen.id, newProduct.quantity, newFors));
          setCartPop(false);
        } else {
          const alt = chosen.vendor ? chosen.product.title.concat(" - " + chosen.vendor.network?.name) : chosen.product.title
          dispatch(
            addToCart(
              newProduct.id,
              newProduct.quantity,
              newProduct.benefitType === "load" ? newProduct.price : null,
              newFors,
              alt
            )
          );
          navigate("/cart");
        }
      }
    });
  };
  const handleSubmit = (e) => {
    if (!isCart || (isCart && edited)) {
      e.preventDefault();
      validateData(fors).then(() => {
        const isValidData =
          isMe ||
          fors
            .map((item) => JSON.stringify(item.errors) === "{}")
            .every((v) => v);
        if (isValidData) {
          /*isValidData >>> dispatch fors somewhere >>> handleCart()*/
          handleCart();
        }
      });
    } else {
      setCartPop(false);
    }
  };
  const handleChangeFors = (idx, field) => (e) => {
    if (isCart && !edited) setEdited(true);

    const newFors = [...fors];
    switch (field) {
      case "phone":
        newFors[idx][field] = formatPhoneNumber(e.target.value);
        break;
      case "email":
        newFors[idx][field] = formatEmail(e.target.value);
        break;
      case "name":
        newFors[idx][field] = e.target.value;
        break;
      case "date":
        newFors[idx][field] = e;
        break;
      case "dateForAll":
        setDate(e);
        newFors.forEach((item) => {
          // item.date = e
          if (!item.isDate) {
            item.date = e;
          }
        });
        break;
      case "text":
        newFors[idx][field] = e.target.value;
        break;
      case "textForAll":
        setText(e.target.value);
        newFors.forEach((item) => {
          if (!item.isText) {
            item.text = e.target.value;
          }
        });
        break;
      case "isDate":
        newFors[idx][field] = !newFors[idx][field];
        break;
      case "isText":
        newFors[idx][field] = !newFors[idx][field];
        break;
      default:
        break;
    }
    setFors(newFors);
  };

  const errorMessage = useMemo(() => {
    switch (error) {
      case "minTime": {
        return "תאריך שגוי";
      }
      default: {
        return "";
      }
    }
  }, [error]);

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <Container component="main">
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            שליחת השובר -
            <strong>
              {chosen.product.title}
              {chosen.vendor && " - " + chosen.vendor?.network?.name}
            </strong>
            <div style={{ width: "100px", margin: "auto", paddingTop: "10px" }}>
              <QuantitySelector
                onChange={(e) => {
                  // isCart && handleChangeQuantity(
                  //   e,
                  //   chosen.product.productId,
                  //   chosen.id
                  // )
                  setChosen({
                    ...chosen,
                    quantity: e.target.value,
                  });
                  setEdited(true);
                }}
                minValue={1}
                value={chosen.quantity}
              />
            </div>
            <Box
              component="form"
              noValidate
              onSubmit={
                isCart
                  ? (e) => {
                    e.preventDefault();
                    setCartPop(false);
                  }
                  : handleSubmit
              }
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {/* {!isMe &&  */}
                  <ToggleButtonGroup
                    className="sendToggleButtonGroup"
                    m="auto"
                    color="warning"
                    value={formats}
                    onChange={(e, n) => {
                      setFormats(n);
                      setEdited(true);
                    }}
                    aria-label="text formatting"
                  >
                    {hows.map((how, idx) => (
                      <ToggleButton
                        key={idx}
                        value={how.value}
                        sx={{ flexDirection: "column" }}
                        className="sendToggleButton"
                      >
                        {how.icon}
                        {how.label}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                  {/* } */}
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} sx={{ m: "8px" }}>
                    <FormControl
                      row
                      sx={{
                        flexDirection: { sm: "row" },
                        width: "100%",
                        maxWidth: "700px",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={isMe}
                        onChange={() => {
                          setIsMe(!isMe);
                          setEdited(true);
                        }}
                        sx={{
                          flexDirection: "row",
                          display: "flex",
                          width: "300px",
                          justifyContent: "center",
                          "&>*>*>*": { m: 1 },
                        }}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio color="warning" />}
                          label="לעצמי"
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio color="warning" />}
                          label="מתנה"
                        />
                      </RadioGroup>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={isNow}
                        onChange={() => setIsNow(!isNow)}
                        sx={{
                          flexDirection: "row",
                          display: "flex",
                          width: "300px",
                          justifyContent: "center",
                          "&>*>*>*": { m: 1 },
                        }}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio color="warning" />}
                          label="משלוח מיידי"
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio color="warning" />}
                          label="משלוח עתידי"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>

                {!isMe && (
                  <>
                    {fors.map((item, idx) => (
                      <>
                        <Grid container spacing={1} m={1}>
                          <Grid item xs={12} sm={1}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                              }}
                            >
                              {idx + 1}
                            </div>
                          </Grid>
                          {formats.includes("sms") && (
                            <Grid item xs={12} sm={2}>
                              <TextField
                                required
                                error={!!item.errors.phone}
                                helperText={t(item.errors.phone) || ""}
                                name="phone"
                                id="phone"
                                size="small"
                                label="מספר נייד"
                                fullWidth
                                color="warning"
                                value={
                                  // phoneMask
                                  item.phone
                                }
                                onChange={handleChangeFors(idx, "phone")}
                              />
                            </Grid>
                          )}
                          {formats.includes("email") && (
                            <Grid item xs={12} sm={2}>
                              <TextField
                                required={formats.includes("mail")}
                                error={!!item.errors.email}
                                helperText={t(item.errors.email) || ""}
                                name="email"
                                id="email"
                                size="small"
                                label="כתובת אימייל"
                                fullWidth
                                value={item.email}
                                onChange={handleChangeFors(idx, "email")}
                                color="warning"
                              />
                            </Grid>
                          )}
                          <Grid item xs={12} sm={2}>
                            <TextField
                              required
                              error={!!item.errors.name}
                              helperText={t(item.errors.name) || ""}
                              name="name"
                              id="name"
                              size="small"
                              label="עבור"
                              fullWidth
                              value={item.name}
                              color="warning"
                              onChange={handleChangeFors(idx, "name")}
                            />
                          </Grid>
                          {!isNow && (
                            <Grid item xs={6} sm={2}>
                              <FormGroup>
                                <FormControlLabel
                                  className="sendCheckBoxLabel"
                                  label="?מתי לשלוח"
                                  control={
                                    <Checkbox
                                      checked={item.isDate}
                                      onChange={handleChangeFors(idx, "isDate")}
                                      color="warning"
                                    />
                                  }
                                />
                              </FormGroup>
                            </Grid>
                          )}
                          <Grid item xs={6} sm={2}>
                            <FormGroup>
                              <FormControlLabel
                                className="sendCheckBoxLabel"
                                label="ברכה אישית"
                                control={
                                  <Checkbox
                                    color="warning"
                                    checked={item.isText}
                                    onChange={handleChangeFors(idx, "isText")}
                                  />
                                }
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                        {(item.isText || item.isDate) && (
                          <Grid
                            container
                            spacing={1}
                            sx={{ m: "-8px 8px 24px" }}
                          >
                            <Grid item xs={12} sm={1} />
                            {item.isDate && !isNow && (
                              <>
                                <Grid item xs={12} sm={4}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale={"en-gb"}
                                  >
                                    <DateTimePicker
                                      required
                                      name="date"
                                      // helperText={"תאריך שגוי"}
                                      color="warning"
                                      error={!!item.errors.date}
                                      helperText={t(item.errors.date) || ""}
                                      fullWidth
                                      sx={{ width: "100%" }}
                                      size="small"
                                      minDateTime={dayjs().subtract(
                                        1,
                                        "minute"
                                      )}
                                      value={item.date}
                                      onChange={handleChangeFors(idx, "date")}
                                    />
                                    {item.errors.date && (
                                      <span
                                        style={{
                                          display: "block",
                                          textAlign: "start",
                                          color: "red",
                                          fontSize: "12px",
                                          marginRight: "14px",
                                        }}
                                      >
                                        {item.errors.date}
                                      </span>
                                    )}
                                  </LocalizationProvider>
                                </Grid>
                              </>
                            )}
                            {item.isText && (
                              <Grid item xs={12} sm={7}>
                                <TextField
                                  name="text"
                                  id="text"
                                  label="ברכה..."
                                  inputProps={{ maxLength: 500 }}
                                  fullWidth
                                  value={item.text}
                                  onChange={handleChangeFors(idx, "text")}
                                  color="warning"
                                />
                              </Grid>
                            )}
                          </Grid>
                        )}
                        {idx !== fors.length - 1 && (
                          <Divider sx={{ width: "90%", m: "auto" }} />
                        )}
                      </>
                    ))}
                  </>
                )}

                <Divider sx={{ width: "90%", m: "20px auto 30px" }} />

                {!isMe && chosen.quantity > 1 && (
                  <Box
                    sx={{ textAlign: "start", margin: "auto", width: "90%" }}
                  >
                    <div style={{ fontWeight: "bold", fontSize: "18px" }}>
                      ברכה אחידה לכולם
                    </div>
                    <div style={{ margin: "5px 0 15px" }}>
                      מלאו אם ברצונכם לשלוח ברכה אחידה לכל הנמענים.
                      <br></br>
                      נמענים מסויימים שתוכנס להם ברכה אישית יקבלו את הברכה
                      האישית ולא את הברכה האחידה.
                    </div>
                    <Grid item xs={12}>
                      <TextField
                        name="textForAll"
                        id="textForAll"
                        label="ברכה לכולם"
                        fullWidth
                        multiline
                        minRows={4}
                        onChange={handleChangeFors(null, "textForAll")}
                        value={text || ""}
                        color="warning"
                      />
                    </Grid>
                  </Box>
                )}
                {(isMe || chosen.quantity > 1) && !isNow && (
                  <Box
                    sx={{ textAlign: "start", margin: "auto", width: "90%" }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        margin: "20px 0 5px",
                      }}
                    >
                      זמן משלוח
                      {chosen.quantity > 1 && !isMe && " אחיד לכל הנמענים"}
                    </div>
                    <Grid item xs={12} sm={4}>
                      <LocalizationProvider
                        adapterLocale={"en-gb"}
                        dateAdapter={AdapterDayjs}
                      >
                        <DateTimePicker
                          onError={(newError) => setError(newError)}
                          slotProps={{
                            textField: {
                              helperText: errorMessage,
                            },
                          }}
                          fullWidth
                          sx={{ width: "100%" }}
                          size="small"
                          minDateTime={dayjs().subtract(1, "minute")}
                          // defaultValue={dayjs()}
                          value={date || dayjs()}
                          onChange={handleChangeFors(null, "dateForAll")}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Box>
                )}
              </Grid>
              <Button
                onClick={handleSubmit}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                color="warning"
              >
                אישור
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default Send;
